import React, { useState } from "react";
import {  Auth } from "aws-amplify";
import Card from "./Card";
import { TextInput } from "./TextInput";
import { ButtonBlue } from "./ButtonBlue";
import { Image, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import "../styles/components/resetPassword.scss";
import { I18nProvider, LOCALES } from "../i18n";

const VerifyLogin = ({ user }) => {
    const [ state, setState ] = useState({
        verificationCode: "",
        error: "",
        loader: false
    });

    const onChange = (e) => setState({ ...state, [ e.target.name ]: e.target.value, error: false });

    const submit = async(e) => {
        e.preventDefault();
        if (verificationCode) {
            const { verificationCode } = state;
            setState({ ...state, loader: true });
            try {
                await Auth.sendCustomChallengeAnswer(
                    user,
                    verificationCode
                ).then(res => {
                    if (!res.signInUserSession) {
                        setState({ ...state, error: "Wrong verification code has been provided!", loader: false });
                    }
                });
            } catch (err) {
                setState({ ...state, error: "You have exceeded the number of attempts!", loader: false });
                setTimeout(() => {
                    window.location.replace("/");
                }, 2000);
            }
            
        } else {
            setState({ ...state, error: "Verification code cannot be empty" });
        }
    };

    const { verificationCode, loader, error } = state;
    let [ locale ] = useState(LOCALES.EN);
    const language = localStorage.getItem("language");
    if (language === "EN") {
        locale = LOCALES.EN;
    }
    if (language === "DE") {
        locale = LOCALES.DE;
    }
    if (language === "NL") {
        locale = LOCALES.NL;
    }
    
    return (
        <I18nProvider locale={locale}>
            <div className="reset-container">
                <Col md={7} sm={12} xs={12} lg={5}>
                    <Card>
                        <Image
                            src={require("../assets/images/logos/BAUCAMERA.png")}
                            alt="baucamera-logo"
                            className="reset-container__baucamera-logo"
                        />
                        <h2 className="reset-container__title">Login Verification</h2>
                        <p style={{ fontSize: 16, marginBottom: 40, textAlign: 'center' }}>We have sent the verification code to your Email.</p>
                        <form onSubmit={submit} className="reset-container__login-form">
                            <TextInput
                                label="Verification Code"
                                placeholder=""
                                type="text"
                                name="verificationCode"
                                onChange={onChange}
                                value={verificationCode}
                            />
                            <ButtonBlue title="Submit" type="submit" loader={loader} />
                        </form>
                        <span className="reset-container__error">{error && error}</span>
                    </Card>
                </Col>
            </div>
        </I18nProvider>
     
    );
};

VerifyLogin.propTypes = {
    user: PropTypes.object,
    setToRender:PropTypes.func,
};

export default VerifyLogin;