import React from "react";
import helpCamera from "./assets/hilfe_kamera.png";

const CameraManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>Camera</h2>
            Click on the image in the project view to open the player. With the player you can easily browse through the images in the archive. This is useful if you are looking for specific image content.  

                <br /><br/>

                <img src={helpCamera} alt="help_project"/>
            
                <br /><br/>

From the current image backwards, simply click on the arrow on the left-hand side of the image until the desired image is displayed. The arrow to the right goes in the opposite direction.  


                <h3>Timeline</h3>
Click on the blue triangle below the image to open the timeline. Here you can set the point in time 
from which you would like to scroll. The timeline automatically displays the last month, you can also select a different month; you will find the selection in the month field on the left of the timeline. 

In the player, you can simply download the image displayed by clicking the button.  

Within the timeline, you can use the arrows on the right-hand side of the player to jump to the beginning or end of the project archive.  
                <br/>

                <h3>Image size and resolution</h3>
All images are displayed in the cloud in a reduced preview size to enable fast and good data handling. The original images are not changed and are saved in their original size. You will receive all images in their original size as soon as you download them.
                <br/>

                <h3>Copy image link</h3>
Copy image link" button: The link refers to the latest image without requesting access data. 
This allows you to integrate the latest image from the camera on your homepage or intranet. This link is only available as soon as the camera has transferred the first images. This function is only available to main users.  
                <br/>

                <h3>Weather information</h3>
Weather information about the construction site is displayed above the image. This information can also be found with the images in the archive.  
                <br/>

                <h3>Notification Cam Offline</h3>
If this switch is activated, you will be informed by e-mail if a BAU.CAMERA is no longer transferring to the cloud. Each user can set this individually.  
                <br/>

                <h3>Copy image link</h3>
Main users can copy a link to the current image here. You can use this link on your homepage, for example. The link always shows the current image.  
            </div></div>
    );
};

export default CameraManual;