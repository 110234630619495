import React, { useEffect, useState, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listEvents } from "../../graphql/queries";
import "../../styles/pages/logfile.scss";
import Pagination from "../../components/Pagination";
import moment from "moment";
import Loader from "../../components/Loader";
import { onCreateEvent } from "../../graphql/subscriptions";
import translate from "../../i18n/translate";

const LogFile = () => {
    const [ events, setEvents ] = useState([]);
    const [ loader, setLoader ] = useState(false);
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ itemPerPage ] = useState(100);
    const [ selectedTab, setSelectedTab ] = useState("camera");
    const onCreate = useRef();

    useEffect(() => {
        fetchListEvents();
    }, [ selectedTab ]);

    const fetchListEvents = async () => {
        setLoader(true);
        try {
            const requestData = await API.graphql(graphqlOperation(listEvents, 
                { limit: 10000, filter: { logType: { eq: selectedTab } } }));
            const requests = requestData.data.listEvents.items;
            setEvents(requests);
            setLoader(false);
        } catch (err) {
            setLoader(false);
        }
    };

    useEffect(() => {
        onCreate.current = API.graphql(
            graphqlOperation(onCreateEvent)
        ).subscribe({
            next: (createEventData) => {
                const createdEvent = createEventData?.value?.data?.onCreateEvent;
                setEvents(previousData => [ ...previousData, createdEvent ]);
            }
        });
        return () => {
            onCreate.current.unsubscribe();
        };
    }, []);

    const paginate = (selectedPage) => {
        let selected = selectedPage.selected;
        setCurrentPage(selected);
        document.documentElement.scrollTop = 0;
    };

    const indexOfLastItem = (currentPage + 1) * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const sortedEvents = events.sort(
        (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
    );
    const currentItems = sortedEvents.slice(indexOfFirstItem, indexOfLastItem);

    const logTypesOptions = [
        { label: translate("camera-logs"), value: "camera" },
        { label: translate("project-logs"), value: "project" },
        { label: translate("user-logs"), value: "user" },
        { label: translate("company"), value: "company" },
        { label: translate("historical-archive"), value: "historicalArchive" },
        { label: translate("email"), value: "email" },
        { label: translate("Downloads"), value: "download" },
    ];

    const renderLogsTable = (logsItems) => {
        return (
            <div className="log-event__container">
                {loader ?
                    <Loader /> :
                    logsItems.map((item) => {
                        const date = item.createdAt.split("T")[ 0 ];
                        const time = item.createdAt.split("T")[ 1 ];
                        const remainingTime = (time.slice(2, time.length));
                        let extractedTime = String((Number(time.slice(0, 2)) + 2));
                        extractedTime = extractedTime.length === 1 ? `0${extractedTime}${remainingTime}` : 
                            `${extractedTime}${remainingTime}`;
                        return (
                            <div
                                className="log-event__item"
                                key={item.id}
                            >
                                <span className="log-event__date">
                                    {`${moment(date).format("DD.MM.YYYY")} | ${extractedTime?.substring(0, 5)}`}
                                </span>
                                <span>{item.log} {item.logType !== "email" && <>By {item.user}</>}</span > 
                            </div>
                        );
                    })}
                <div className="mt-5">
                    <Pagination
                        currentPage={currentPage}
                        itemPerPage={itemPerPage}
                        totalItems={events.length}
                        paginate={paginate}
                        path="/logfile"
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="log-event">
            <div className="log-event__cover">
                <h1>Events Log</h1>
            </div>
            <ul className="nav nav-tabs">
                {logTypesOptions.map(item => {
                    return (
                        <li 
                            className="nav-item" 
                            key={item.label}
                            onClick={() => setSelectedTab(item.value)}
                        >
                            <a 
                                className={item.value === selectedTab ? "nav-link active" : "nav-link"} 
                                aria-current="page" href="#">
                                {item.label}
                            </a>
                        </li>
                    );
                })}
            </ul>
            {renderLogsTable(currentItems)}
        </div>
    );
};

export default LogFile;