import React from "react";
import helpTimelapse from "./assets/hilfe_zeitraffer.png";

const TimelapseManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>Time-lapse online</h2>
                <h3>Time-lapse</h3>
                Hier zie je de time-lapse van de laatste dag, die automatisch wordt afgespeeld. De productie van de time-lapse van de laatste week en de laatste maand kan worden besteld door op de betreffende knop te klikken. Zodra de time-lapse beschikbaar is, 
                verandert de kleur van de knop in groen. Elke time-lapse kan ook worden gedownload.   
                <br/><br/>
                <img src={helpTimelapse} alt="help_project"/>
            </div></div>
    );
};

export default TimelapseManual;