import React from "react";
import "../styles/pages/Cams/cams-popup.scss";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MobileCloseBtn from "./MobileCloseBtn";
import { propTypes } from "react-bootstrap/esm/Image";
import { FaRegCalendarAlt } from "react-icons/fa";
import moment from "moment";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "transparent" : "transparent",
    boxShadow: "none",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    background: "transparet !important",
    color: theme.palette.text.secondary,
}));

const PopupHeader = ({ Role, cameraData, cameraAssignedDate, imageDatetime, setTime, onClose, hasCameraData }) => {
    const isTimeShowing = (hasCameraData ? true : false);
    return (
        <Grid container alignItems="flex-end" spacing={4} sx={{}}>
            {(isTimeShowing ?  <Grid item xs="auto">
                <Item sx={{ padding: 0 }}>
                    <Box sx={{ height: "30px", display: "table-cell", verticalAlign: "middle", paddingTop: 0, }}>
                        <div className="header-container">
                            <span className="font-provider">
                                {Role === "admin" || Role === "crew"
                                    ? cameraData.cameraName
                                    : cameraData.cameraAlias}
                            </span>
                        </div>
                    </Box>
                </Item>
            </Grid> : null)}
            {(isTimeShowing ?  <Grid item xs={5}>
                <Item sx={{ padding: 0 }}> 
                    <Box sx={{ height: "30px", display: "table-cell", verticalAlign: "middle", paddingTop: 0 }}>
                        <div className="dummy-content">
                            <span className="header-calendar-icon">
                                <FaRegCalendarAlt size={14} color='#1181F2' />
                            </span>
                            {cameraAssignedDate < imageDatetime ? (
                                <span className="font-provider-calendar">{`${moment(imageDatetime).format(
                                    "DD.MM.YYYY"
                                )} | ${setTime?.substring(0, 5)}`}</span>
                            ) : (
                                <span className="font-provider-caledar">--.--.---- | --:--</span>
                            )} 
                        </div>
                    </Box>
                </Item>
            </Grid> : null )}
            <Grid item xs>
                <Item sx={{ float: "right", padding: 0 }}>
                    <Box sx={{ height: "30px", display: "table-cell", verticalAlign: "middle" }}>
                        <MobileCloseBtn
                            size={30}
                            onClick={onClose}
                            className="mobile-close-button"
                        />
                    </Box>
                </Item>
            </Grid>
        </Grid>
    );
}; export default PopupHeader;
PopupHeader.propTypes = {
    Role: propTypes.string,
    cameraData: propTypes.string,
    cameraAssignedDate: propTypes.string,
    imageDatetime: propTypes.string,
    onClose: propTypes.func,
    setTime: propTypes.string,
    hasCameraData: propTypes.bool,
};