export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      name
      projects {
        items {
          id
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            items {
              id
              cameraName
            }
            nextToken
          }
          company {
            id
            name
            createdAt
            updatedAt
          }
          users {
            nextToken
          }
          endedAt
          cameraIDs
          deleteAllImages
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateCamera = /* GraphQL */ `
  subscription OnUpdateCamera(
    $sortDirection: ModelSortDirection
  ) {
    onUpdateCamera {
      id
      cameraName
      cameraAlias
      username
      password
      status
      project {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          name
          projects {
            nextToken
          }
          createdAt
          updatedAt
        }
        users {
          items {
            id
            userID
            projectID
            createdAt
            updatedAt
          }
          nextToken
        }
        endedAt
        cameraIDs
        deleteAllImages
        createdAt
        updatedAt
      }
      imagesByDate(sortDirection: $sortDirection) {
        items {
          id
          cameraID
          datetime
          date
          time
          intTime
          workday
          s3ObjectPathOriginal
          s3ObjectPathThumbnail
          s3ObjectPathBlurred
          blurred
          createdAt
          updatedAt
        }
        nextToken
      }
      liveStream
      liveStreamLink
      latestPictureLink
      ftpLink
      rmsLink
      downloadImagesLink
      deleteAllImages
      changePassword
      hideCopyLink
      blurringEnabled
      createdAt
      updatedAt
    }
  }
`;
