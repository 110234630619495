import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listUsers } from "../graphql/queries";
import { updateUser } from "../graphql/mutations";
import { completeNewPassword } from "../service/AuthService";
import Card from "./Card";
import { TextInput } from "./TextInput";
import { ButtonBlue } from "./ButtonBlue";
import { Image, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import "../styles/components/resetPassword.scss";
import translate from "../i18n/translate";
import { I18nProvider, LOCALES } from "../i18n";

const ResetPassword = ({ user, setToRender }) => {
    const [ state, setState ] = useState({
        password: "",
        error: "",
        loader: false
    });

    const onChange = (e) => setState({ ...state, [ e.target.name ]: e.target.value, error: false });

    const submit = (e) => {
        e.preventDefault();
        const { password } = state;
        setState({ ...state, loader: true });
        completeNewPassword(user, password)
            .then(async (res) => {
                await API.graphql(graphqlOperation(listUsers, {
                    filter: {
                        username: {
                            eq: res.username
                        }
                    },
                    limit: 10000
                }))
                    .then(async ({ data: { listUsers: { items } } }) => {
                        await API.graphql(graphqlOperation(updateUser, { input: { id: items[ 0 ].id, status: true } }));
                    });
                setState({ ...state, loader: false });
                setToRender();
                window.location.replace("/");
            })
            .catch((err) => {
                setState({ ...state, error: err, loader: false });
            });
    };

    const { password, loader, error } = state;
    let [ locale ] = useState(LOCALES.EN);
    const language = localStorage.getItem("language");
    if (language === "EN") {
        locale = LOCALES.EN;
    }
    if (language === "DE") {
        locale = LOCALES.DE;
    }
    if (language === "NL") {
        locale = LOCALES.NL;
    }
    
    return (
        <I18nProvider locale={locale}>
            <div className="reset-container">
                <Col md={7} sm={12} xs={12} lg={5}>
                    <Card>
                        <Image
                            src={require("../assets/images/logos/BAUCAMERA.png")}
                            alt="baucamera-logo"
                            className="reset-container__baucamera-logo"
                        />
                        <h2 className="reset-container__title">{translate("reset-password")}</h2>
                        <form onSubmit={submit} className="reset-container__login-form">
                            <TextInput
                                label={translate("new-password")}
                                placeholder=""
                                type="password"
                                name="password"
                                onChange={onChange}
                                value={password}
                            />
                            <ButtonBlue title={translate("change-password")} type="submit" loader={loader} />
                        </form>
                        <span className="reset-container__error">{error && error}</span>
                    </Card>
                </Col>
            </div>
        </I18nProvider>
     
    );
};

ResetPassword.propTypes = {
    user: PropTypes.object,
    setToRender:PropTypes.func,
};

export default ResetPassword;