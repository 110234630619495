import React, { useState } from "react";
import "../styles/components/select-dropdown.scss";
import PropTypes from "prop-types";
import Select from "react-select";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { ClickAwayListener } from "@mui/base";

const SelectDropdown = ({ defaultValue, placeholder, id, onChange, options }) => {
    const [ show, setShow ] = useState(false);
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected && "#1181F2",
            backgroundColor: "none",
            cursor: "pointer",
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const cursor = "pointer";
            return { ...provided, opacity, transition, cursor };
        },
        menu: (provided) => ({
            ...provided,
            cursor: "pointer",
            borderRadius: 10,
            paddingLest: 2,
            fontSize: 12,
            fontFamily: "Intersate-Light",
        }),
    };

    return (
        <ClickAwayListener onClickAway={() => {setShow(false);}}>
            <div className="select-dropdown" onClick={() => {setShow(!show);}} style={{ cursor: "pointer" }}>
                <Select 
                    id={id}
                    onChange={onChange}
                    options={options}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    styles={customStyles}
                    isSearchable={false}
                    openMenuOnClick={false}
                    menuIsOpen={show}
                />
                <span style={!show ? { display: "none" } : null}>
                    <MdArrowDropUp size={18} onClick={() => {setShow(!show);}}/>
                </span>
                <span style={show ? { display: "none" } : null}>
                    <MdArrowDropDown size={18} onClick={() => {setShow(!show);}}/>
                </span>
            </div>
        </ClickAwayListener>
    );
};


SelectDropdown.propTypes = {
    defaultValue: PropTypes.object,
    id: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};
export default SelectDropdown;