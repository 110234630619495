import translate from "../i18n/translate";

export const menuItemsAdmin = [
    {
        name: "Home",
        path: "/",
        exact: true,
        isActive: (match, location) => {
            if (location.pathname === "/") {
                return true;
            } else return false;
        }
    },
    { name: translate("projects"), path: "/projects" },
    { name: translate("cams"), path: "/cams" },
    { name: translate("users"), path: "/users" },
    { name: translate("historical-archive"), path: "/historical-archive" },
    { name: translate("companies"), path: "/companies" },
    { name: translate("log-file"), path: "/logfile" },
];

export const menuItemsSuperUser = [ { name: translate("projects"), path: "/projects" }, { name: translate("users"), path: "/users" }, ];

export const slidesItemsAdmin = [
    {
        id: 1,
        title: "Home",
        path: "/",
    },
    {
        id: 2,
        title: translate("projects"),
        path: "/projects",
    },
    {
        id: 3,
        title: translate("cams"),
        path: "/cams",
    },
    {
        id: 4,
        title: translate("users"),
        path: "/users",
    },
    {
        id: 5,
        title: translate("historical-archive"),
        path: "/historical-archive",
    },
    {
        id: 6,
        title: translate("companies"),
        path: "/companies",
    },
    {
        id: 7,
        title: translate("log-file"),
        path: "/logfile",
    },
];

export const slidesItemsSuperUser = [
    {
        id: 1,
        title: translate("projects"),
        path: "/projects",
    },
    {
        id: 2,
        title: translate("users"),
        path: "/users",
    },
];