import React from "react";

const ArchiveManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>Archief</h2>
                Je kunt je afbeeldingen uit het archief downloaden. Hiervoor kun je rechtsboven een selectie maken en de afbeeldingen dienovereenkomstig filteren. In de eerste twee velden 
                voer je de begin- en einddatum in, in de velden rechts kun je ook filteren op een tijd met begin- en eindtijd. Je kunt ook "Sluit weekend uit" gebruiken om 
                aan te geven of alle afbeeldingen van de week worden geselecteerd of alleen de afbeeldingen van maandag tot en met vrijdag.

                <br/><br/>
                Als je alleen foto's van een bepaalde dag zoekt, kun je het filter wijzigen in "op datum" om alle foto's van die dag weer te geven.
                <br/><br/>
                Zodra je een selectie hebt gemaakt, kun je op de knop "Downloaden" klikken om een downloadlink aan te maken. De cloud zal nu deze selectie 
                foto's voor je beschikbaar maken. Zodra dit proces is voltooid, ontvang je een e-mail met een downloadlink. Afhankelijk van de grootte van 
                het archief kan dit tot een half uur duren. 
                <br/><br/>
                Downloads kunnen alleen worden besteld als de eerder gestarte bestelling is voltooid. U kunt de status van deze functie herkennen aan de kleur op de knop. Groen betekent dat een download kan worden besteld, 
                blauw betekent dat een opdracht momenteel nog wordt verwerkt en dat de volgende opdracht nog moet wachten.
                <br/><br/>
                Als je meerdere camera's in het project hebt, kun je linksboven onder "Selecteer cam" de betreffende camera voor de archiefweergave opgeven. 
            </div></div>
    );
};

export default ArchiveManual;