import React from "react";
import helpTimelapse from "./assets/hilfe_zeitraffer.png";

const TimelapseManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>Zeitraffer Online</h2>
                <h3>Zeitraffer</h3>
                Hier sehen Sie den Zeitraffer des letzten Tages, er spielt automatisch ab. Die Herstellung der Zeitraffer der letzten Woche und des letzten Monats können durch einen Klick auf den jeweiligen Button beauftragt werden. Sobald der Zeitraffer verfügbar ist, 
                wechselt die Farbe des Buttons auf Grün. Jeder Zeitraffer kann auch heruntergeladen werden.
                <br/><br/>
                <img src={helpTimelapse} alt="help_project"/>
            </div></div>
    );
};

export default TimelapseManual;