import React, { useState, useEffect } from "react";
import UserManualEN from "./EN/Index";
import UsermanualDE from "./DE/Index";
import UserManualNL from "./NL/Index";

const UserManual = () => {
    const [ language, setLanguage ] = useState(localStorage.getItem("language"));
    useEffect(() => {
        setLanguage(localStorage.getItem("language"));
    }, [ localStorage.getItem("language") ]);

    return language === "EN" ? <UserManualEN /> : language === "DE" ? <UsermanualDE /> : <UserManualNL />;
};

export default UserManual;