import React from 'react';
import '../styles/components/card.scss';
import PropTypes from 'prop-types';

const Card = ({children, ...props}) => <div {...props} className="card">{children}</div>;

Card.propTypes = {
    children: PropTypes.any
};

export default Card;

