import React from "react";
import ReactLoading from "react-loading";
import "../styles/components/Loader.scss";


const Loader = ()=>{
    return (
        <div className = 'loader'>
            <ReactLoading type ={"spinningBubbles"} color="#007bff"/>
        </div>
    );
};

export default Loader;