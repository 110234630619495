import React from "react";
import helpCamera from "./assets/hilfe_kamera.png";

const CameraManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>Kamera</h2>
                In der Projektansicht auf das Bild klicken, öffnet den Player. Mit dem Player können Sie einfach durch die Bilder des Archivs zu blättern. Dies ist sinnvoll, wenn sie einen bestimmten Bildinhalt suchen.
                <br /><br/>

                <img src={helpCamera} alt="help_project"/>
            
                <br /><br/>

                Vom aktuellen Bild rückwärts einfach auf den Pfeil an der linken Bild Seite klicken, bis das gewünschte Bild angezeigt wird. Der Pfeil nach rechts ab, geht zeitlich in die andere Richtung.

                <h3>Timeline</h3>
                Der Klick auf das blaue Dreieck unterhalb des Bildes öffnet die Timeline. Hier können Sie einstellen, 
                von welchem Zeitpunkt aus Sie gerne blättern möchten. Die Timeline zeigt automatisch den letzten Monat an, Sie können auch einen anderen Monat auswählen, die Auswahl finden SIe innerhalb der Timeline links im Monatsfeld.

                Im Player können Sie das jeweils angezeigte Bild einfach herunterladen, dazu den Button klicken.

                Innerhalb der Timeline können Sie mit den Pfeilen rechts im Player an den Anfang oder an das Ende des Projektarchivs springen.
                <br/>

                <h3>Bildgröße und Auflösung</h3>
                Alle Bilder werden in der Cloud in einer verkleinerten Vorschaugröße angezeigt, um ein schnelles und gutes Daten-Handling zu ermöglichen. 
                Die Original-Bilder werden nicht verändert und in Originalgröße gespeichert. Sie erhalten alle Bilder in Originalgröße, sobald Sie Bilder runterladen.
                <br/>

                <h3>Bildlink kopieren</h3>
                Button „Bildlink kopieren“: Der Link verweist auf das jeweils neueste Bild ohne Zugangsdaten abzufragen. So können Sie das aktuelle Bild 
                der Kamera auf Ihrer Homepage oder im Intranet einbinden. Dieser Link steht erst zur Verfügung, sobald die Kamera erste Bilder übertragen hat. Diese Funktion steht nur Hauptnutzern zur Verfügung.
                <br/>

                <h3>Wetterinformationen</h3>
                Oberhalb des Bildes werden Wetterinformationen zur Baustelle eingeblendet. Diese Informationen finden Sie auch zu den Bildern im Archiv.
                <br/>

                <h3>Benachrichtigung Cam Offline</h3>
                Ist dieser Schalter aktiviert, werden sie per E-Mail darüber unterrichtet, falls eine BAU.CAMERA nicht mehr in die Cloud überträgt. Jeder Nutzer kann das individuell einstellen.
                <br/>

                <h3>Bildlink kopieren</h3>
                Hauptnutzer können hier einen Link zum aktuellen Bild kopieren. Diesen Link können Sie zum Beispiel auf ihrer Homepage einsetzen. Der Link zeigt immer das aktuelle Bild.
            </div></div>
    );
};

export default CameraManual;