import React from "react";
import "../styles/components/Buttons.scss";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";

export const ButtonBlue = ({ title, backgroundColor, type, loader, color, onClick, disabled, loaderColour, className, border }) => {
    const background = disabled ? "#eeeeee" : backgroundColor;
    return (
        <button
            style={{
                border: border
            }}
            className="blue-button"
            type={type}
            onClick={onClick}
            disabled={disabled}
        >
            {loader ? 
                <div className="loader-span">
                    <ReactLoading type="bubbles" color={loaderColour ? loaderColour : "#fff"} width={40} height={40}/> 
                </div>
                : title}
        </button>
    );
};

ButtonBlue.propTypes = {
    color: PropTypes.string,
    title: PropTypes.any,
    backgroundColor: PropTypes.string,
    type: PropTypes.string,
    loader: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    loaderColour: PropTypes.string,
    className: PropTypes.string,
    border: PropTypes.string
};