import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import PropTypes from "prop-types";
import greyCamera from "../../assets/images/greycam.png";

Storage.configure({
    customPrefix: {
        public: ""
    },
});

export const ArchiveImage = ({ cameraKey, onClick }) => {
    const [ imageURI, setImageURI ] = useState("");

    useEffect(() => {
        Storage.get(cameraKey)
            .then((res) => {
                setImageURI(res);
            });
    }, [ cameraKey ]);

    return (
        <>
            {imageURI ?
                <img 
                    src={imageURI} 
                    alt='Arvhive Camera'
                    className="Archive__tile--img" 
                    onClick={onClick}
                    onError={(e) => { e.target.setAttribute("src", greyCamera); }}
                />
                :
                <div className = 'Archive__camera-img-default-tile'>
                    <img 
                        src={require("../../assets/images/iconfinder_video_camera_172629 1.png")} 
                        alt='Archive Camera'
                        onError={(e) => { e.target.setAttribute("src", greyCamera); }}
                    />
                </div> }
        </>
    );
};

ArchiveImage.propTypes = {
    cameraKey: PropTypes.string,
    onClick: PropTypes.func,
};