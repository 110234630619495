export const lightTheme = {
  body: "#ffff",
  body2: "#f9f9f9",
  buttonText: "#1181f2",
  text: "#212529",
  textGrey: "lightgrey",
  card: "#ffff",
  card2: "#f5f5f5",
  button: "#ffff",
  buttonWithoutBg: "none",
  boxShadow: "0px 0px 15px rgba(229, 229, 229, 0.7)",
  modalBackground: "#e5e5e5d1",
  fieldBg: "#e9ecef",
  fieldBoxShadow: "inset 0px 0px 20px rgba(222, 222, 222, 0.53)",
  rowItem: "#f9f9f9",
  tabBody: "#f9f9f9"
};

export const darkTheme = {
  body: "#18191A",
  body2: "#2d2d2d",
  buttonText: "#ffff",
  text: "#ffff",
  textGrey: "#ffff",
  card: "#2d2d2d",
  card2: "#363636",
  button: "#363636",
  buttonWithoutBg: "none",
  boxShadow: "none",
  modalBackground: "#717171d1",
  fieldBg: "#2d2d2d",
  fieldBoxShadow: "none",
  rowItem: "#2d2d2d",
  tabBody: "#2d2d2d"
};