import React from "react";
import { useState } from "react";
import "../styles/components/dropdown.scss";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import PropTypes from "prop-types";
import { ClickAwayListener } from "@mui/base";

const DropdownInput = ({ name, item, id, onSelect, label, title, iconSize }) => {
    const [ show, setShow ] = useState(false);
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected && "#1181F2",
            backgroundColor: "none",
            cursor: "pointer",
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const cursor = "pointer";
            return { ...provided, opacity, transition, cursor };
        },
        menu: (provided) => ({
            ...provided,
            cursor: "pointer",
            borderRadius: 10,
            paddingLest: 2,
            fontSize: 12,
            fontFamily: "Open Sans",
        }),
    };
    return (
        <ClickAwayListener onClickAway={() => {setShow(false);}}>
            <div className="drop-down" onClick={() => {setShow(!show);}}>
                <label htmlFor={label}>{label}</label>
                <span className='drop-down-span'>
                    <DropdownButton
                        id={id ? id : null}
                        name={name}
                        title={title}
                        show={show}
                        styles={customStyles}
                    >
                        {item.map((val, i) => (
                            <Dropdown.Item onSelect={() => onSelect(val)} key={i}>
                                {val}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <span style={show ? { display: "none" } : null}>
                        <IoMdArrowDropdown onClick={() => {setShow(!show);}} className='icon' size={iconSize} color="#0000009e"/>
                    </span>
                    <span style={!show ? { display: "none" } : null}>
                        <IoMdArrowDropup onClick={() => {setShow(!show);}} className='icon' size={iconSize} color="#0000009e"/>
                    </span>
                    
                </span>
            </div>
        </ClickAwayListener>
    );
};

DropdownInput.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    item: PropTypes.any,
    label: PropTypes.any,
    title: PropTypes.any,
    onSelect: PropTypes.func,
    iconSize: PropTypes.number
};

export default DropdownInput;
