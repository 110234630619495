import React, { useState, useEffect } from "react";
import "../styles/pages/outOfService.scss";
import { getCloudStatus } from "../graphql/queries";
import { graphqlOperation, API } from "aws-amplify";
import maintanence from "../assets/images/out-of-service.png"




const OutOfService = () => {

    const [ displayMessage, setDisplayMessage ] = useState("");

    useEffect(() => {
        API.graphql(graphqlOperation(getCloudStatus, { id: "CloudStatusId" }))
            .then(({ data }) => {
                setDisplayMessage(data.getCloudStatus.displayMessage);
            });
    }, []);
  
    return (
        <React.Fragment>
            <div className="out-of-service">
                <div className="out-of-service__inner">
                    <img src={maintanence} alt="out-of-service" />
                    <h2>{displayMessage}</h2>
                </div>
            </div>
        </React.Fragment>
    );
};

export default OutOfService;