import React from "react";

const UsersPageManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>Gebruikers </h2>
                Hier kun je andere mensen, zoals collega's of klanten, uitnodigen voor het project. Het enige wat je nodig hebt is de naam en het e-mailadres van de nieuwe gebruiker.
                <br/><br/>

                Onder Rol geef je aan of de nieuwe gebruiker alle afbeeldingen in het archief kan zien (gebruiker) of alleen de huidige afbeelding (beperkte gebruiker). 
                <br/><br/>

                Onder dit selectieveld kunt u een of meer van uw projecten toewijzen aan de nieuwe gebruiker. Door op het betreffende project te klikken, verandert de kleur in blauw en wordt het project geselecteerd.    
                <br/><br/>

                Zodra je op "+ Toevoegen" klikt, ontvangt de nieuwe gebruiker een e-mail met zijn persoonlijke toegang tot je geselecteerde projecten. 
            </div></div>
    );
};

export default UsersPageManual;