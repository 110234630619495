import React, { useState, useEffect } from "react";
import Proptypes from "prop-types";
import { NavLink } from "react-router-dom";
import "../styles/components/mobile-navbar.scss";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { slidesItemsAdmin, slidesItemsSuperUser } from "../helpers/navbarItems";

const MobileNavbar = ({ role }) => {
    const [ selectedIdx, setSelectedIdx ] = React.useState(0);
    const [ slideOrder, setSlideOrder ] = React.useState([
        "s5",
        "s6",
        "s7",
        "s8",
        "s1",
        "s2",
        "s3",
        "s4",
    ]);
    const [ slideStyles, setSlideStyles ] = React.useState({});
    const [ slides, setSlides ] = useState([]);

    useEffect(() => {
        if (role === "admin" || role === "crew") {
            setSlides(slidesItemsAdmin);
        } else if (role === "superuser") {
            setSlides(slidesItemsSuperUser);
        }
    }, [ role ]);

    const rotate = (slides) => {
        const [
            s1,
            s2,
            s3,
            s4,
            s5,
            s6,
            s7,
            s8,
        ] = slides;
        setSlideStyles({
            [ s1 ]: { transform: "translateX(-48rem)", opacity: 0 },
            [ s2 ]: { transform: "translateX(-36rem)", opacity: 0 },
            [ s3 ]: { transform: "translateX(-24rem)", opacity: 0 },
            [ s4 ]: { transform: "translateX(-12rem)", opacity: 1 },
            [ s5 ]: { transform: "translateX(0rem)", opacity: 1 },
            [ s6 ]: { transform: "translateX(12rem)", opacity: 1 },
            [ s7 ]: { transform: "translateX(24rem)", opacity: 0 },
            [ s8 ]: { transform: "translateX(36rem)", opacity: 0 },
        });
        setSlideOrder(slides);
    };

    // rotate slides left by n spaces: e.g. 2 spaces - [1, 2, 3, 4, 5] -> [3, 4, 5, 1, 2]
    const rotateLeft = (spaces = 1) => {
        const s = slideOrder.map((_, i) => slideOrder[ (i + spaces) % slideOrder.length ]);

        setSelectedIdx((selectedIdx + spaces) % 8);
        rotate(s);
    };

    // rotate slides right by n spaces: e.g. 2 spaces - [1, 2, 3, 4, 5] -> [4, 5, 1, 2, 3]
    const rotateRight = (spaces = 1) => {
        const s = slideOrder.reduce((result, slide, i) => {
            result[ (i + spaces) % slideOrder.length ] = slide;
            return result;
        }, []);

        setSelectedIdx( 7 - ( (7 - selectedIdx + spaces ) % 8) );
        rotate(s);
    };

    return (
        <div className="carousel-wrap">
            <div className="carousel-container">
                <button className="carousel-btn prev-btn" onClick={() => rotateRight()}>
                    <FaCaretLeft color="#1181F2" size={20} />
                </button>
                <ul className="carousel-slide-list">
                    {slides.map((slide, i) => (
                        <CarouselSlideItem
                            key={slide.id}
                            slide={slide}
                            style={slideStyles[ `s${slide.id}` ]}
                            active={selectedIdx === i}
                            className={`carousel-slide-item s${slide.id}`}
                        />
                    ))}
                </ul>
                <button className="carousel-btn next-btn" onClick={() => rotateLeft()}>
                    <FaCaretRight color="#1181F2" size={20} />
                </button>
            </div>
        </div>
    );
};


const CarouselSlideItem = ({
    slide, style, className,
}) => (
    <>
        {slide.id === 1 ? 
            <li className={className} style={style}>
                <NavLink to={slide.path} activeStyle={{ color: "#1181F2" }} exact
                    isActive={(match, location) => {
                        if (location.pathname === "/cams" || location.pathname === "/") {
                            return true;
                        } else return false;
                    }}
                >
                    {slide.title}
                </NavLink>
            </li> :
            <li className={className} style={style}>
                <NavLink to={slide.path} activeStyle={{ color: "#1181F2" }} exact>
                    {slide.title}
                </NavLink>
            </li>
        }
    </>
    
);

MobileNavbar.propTypes = {
    role: Proptypes.string,
};

export default MobileNavbar;
