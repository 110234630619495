import React, { useState, useEffect, useRef } from "react";
import { forgotPasswordSubmit } from "../service/AuthService";
import Card from "./Card";
import { TextInput } from "./TextInput";
import { ButtonPrimary } from "./ButtonsPrimary";
import { Image, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import "../styles/components/ConfirmForgotPassword.scss";
import translate from "../i18n/translate";
import { I18nProvider, LOCALES } from "../i18n";

const ConfirmForgotPassword = ({ username, code, setToRender }) => {
    const [ state, setState ] = useState({
        password: "",
        confirmPassword: "",
        error: "",
        loader: false,
        passwordSubmit: false,
        disable: false,
    });
    const confirmPassFunc = useRef();

    const onChangePassword = (e) => setState({ ...state, [ e.target.name ]: e.target.value, error: "", disable: false });

    const onChangeConfirmPassword = (e) =>
        setState({ ...state, [ e.target.name ]: e.target.value, error: "", disable: false });

    const confirmPass = () => {
        if (state.password !== state.confirmPassword) {
            setState({ ...state, error: "Password does not match", disable: true });
        }
    };
    confirmPassFunc.current = confirmPass;

    useEffect(() => {
        confirmPassFunc.current();
    }, [ state.confirmPassword, state.password ]);

    const submit = (e) => {
        e.preventDefault();
        const { password } = state;

        setState({ ...state, loader: true });
        forgotPasswordSubmit(username, code, password)
            .then((res) => {
                setState({
                    ...state,
                    loader: false,
                    passwordSubmit: !state.passwordSubmit,
                });
            })
            .catch((err) => {
                setState({ ...state, error: err.message, loader: false });
            });
    };
    const { password, confirmPassword, loader, error } = state;
    let [ locale ] = useState(LOCALES.EN);
    const language = localStorage.getItem("language");
    if (language === "EN") {
        locale = LOCALES.EN;
    }
    if (language === "DE") {
        locale = LOCALES.DE;
    }
    if (language === "NL") {
        locale = LOCALES.NL;
    }

    return (
        <I18nProvider locale={locale}>
            <div className="ConfirmForgotPassword-container">
                <Col md={7} sm={12} xs={12} lg={5}>
                    <Card>
                        <Image
                            src={require("../assets/images/logos/BAUCAMERA.png")}
                            alt="baucamera-logo"
                            className="ConfirmForgotPassword-container__baucamera-logo"
                        />
                        {!state.passwordSubmit ? (
                            <>
                                <h2 className="ConfirmForgotPassword-container__title">
                                    {translate("reset-password")}
                                </h2>
                                <form onSubmit={submit} className="ConfirmForgotPassword-container__login-form">
                                    <TextInput
                                        label={translate("password")}
                                        value={password}
                                        placeholder=""
                                        type="password"
                                        onChange={onChangePassword}
                                        name="password"
                                    />
                                    <TextInput
                                        label={translate("confirm-password")}
                                        placeholder=""
                                        type="password"
                                        name="confirmPassword"
                                        onChange={onChangeConfirmPassword}
                                        value={confirmPassword}
                                    />
                                    <ButtonPrimary
                                        disabled={state.disable}
                                        className="ConfirmForgotPassword-container__btn-submit"
                                        title={translate("submit")}
                                        type="submit"
                                        loader={loader}
                                    />
                                </form>
                            </>
                        ) : (
                            <>
                                <h2 className="ConfirmForgotPassword-container__title-success">
                                    {translate("confirm-new-password")}
                                </h2>
                                <h4
                                    onClick={() => setToRender()}
                                    className="ConfirmForgotPassword-container__back-to-login"
                                >
                                    {translate("back-to-login")}
                                </h4>
                            </>
                        )}
                        <span className="ConfirmForgotPassword-container__error">{error ? error : null}</span>
                    </Card>
                </Col>
            </div>
        </I18nProvider>
    );
};

ConfirmForgotPassword.propTypes = {
    username: PropTypes.string,
    code: PropTypes.string,
    setToRender: PropTypes.func,
};

export default ConfirmForgotPassword;