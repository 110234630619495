import { Auth, API } from "aws-amplify";

export const login = (email, password) => {
    return new Promise(async (resolve, reject) => {
        try {
            const user = await Auth.signIn(email, password);
            resolve(user);
        } catch (e) {
            reject(e);
        }
    });
};

export const forgotPassword = (username) => {
    return new Promise(async (resolve, reject) => {
        try {
            const user = await Auth.forgotPassword(username);
            resolve(user);
        } catch (e) {
            reject(e);
        }
    });
};

export const forgotPasswordSubmit = (username, code, new_password) => {
    return new Promise(async (resolve, reject) => {
        try {
            const user = await Auth.forgotPasswordSubmit(
                username,
                code,
                new_password
            );
            resolve(user);
        } catch (e) {
            reject(e);
        }
    });
};

export const completeNewPassword = (user, password) => {
    return new Promise(async (resolve, reject) => {
        try {
            delete user?.challengeParam?.userAttributes.email_verified;
            delete user?.challengeParam?.userAttributes.email;
            const loggedUser = await Auth.completeNewPassword(user, password);
            resolve(loggedUser);
        } catch (e) {
            reject(e.message);
        }
    });
};

export const createUserInCognito = async (
    username,
    email,
    TemporaryPassword,
    role,
    language
) => {
    try {
        const apiName = "AdminQueries";
        const path = "/createUser";
        const myInit = {
            body: {
                username,
                TemporaryPassword,
                email,
                groupname: role,
                language,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
        };
        const createUser = await API.post(apiName, path, myInit);
        return createUser;
    } catch (e) {
        return e;
    }
};


export const resetUserCredentials = async (
    username,
    TemporaryPassword,
    email,
    groupname,
    language
) => {
    try {
        const apiName = "AdminQueries";
        const path = "/resetUserCredentials";
        const myInit = {
            body: {
                username,
                TemporaryPassword,
                email,
                groupname,
                language
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
        };
        const createUser = await API.post(apiName, path, myInit);
        return createUser;
    } catch (e) {
        return e;
    }
};


export const addUserToGroup = async (username, email, groupname, OldRole) => {
    try {
        const apiName = "AdminQueries";
        const path = "/addUserToGroup";
        const myInit = {
            body: {
                username,
                groupname,
                email,
                oldRole: OldRole
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
        };
        const addUser = await API.post(apiName, path, myInit);
        return addUser;
    } catch (e) {
        return e;
    }
};

export const removeUserFromGroup = async (username, groupname) => {
    try {
        const apiName = "AdminQueries";
        const path = "/removeUserFromGroup";
        const myInit = {
            body: {
                username,
                groupname,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
        };
        const removeUser = await API.post(apiName, path, myInit);
        return removeUser;
    } catch (e) {
        return e;
    }
};

export const disableUserInCognito = async (username) => {
    try {
        const apiName = "AdminQueries";
        const path = "/disableUser";
        const myInit = {
            body: {
                username,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
        };
        const disableUser = await API.post(apiName, path, myInit);
        return disableUser;
    } catch (e) {
        return e;
    }
};


export const removeUserInCognito = async (username, groupname) => {
    try {
        const apiName = "AdminQueries";
        const path = "/deleteUser";
        const myInit = {
            body: {
                username,
                groupname
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
        };
        const disableUser = await API.post(apiName, path, myInit);
        return disableUser;
    } catch (e) {
        return e;
    }
};

