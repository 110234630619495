import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { TextInput } from "../../components/TextInput";
import "../../styles/pages/companies/add-edit-company.scss";
import { API, graphqlOperation } from "aws-amplify";
import { getCompany } from "../../graphql/queries";
import { createCompany, createEvent } from "../../graphql/mutations";
import PropTypes from "prop-types";
import { Row, Modal } from "react-bootstrap";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import translate from "../../i18n/translate";
import { BiX } from "react-icons/bi";
import { updateCompany } from "../../helpers/manualMutations";

const AddEditCompany = ({ onHide, modal }) => {

    const { editCompany, id, show } = modal;
    const [ companyData, setCompanyData ] = useState({
        name: ""
    });
    const [ loader, setLoader ] = useState(false);
    const [ error, setError ] = useState("");
    const events = {
        log: "",
        user: Auth.user.username,
        expire: "",
        logType: "company"
    };
    let now = (Date.now() + 15552000000) / 1000;
    events.expire =  Math.round(now);

    useEffect(() => {
        fetchRequests();
    }, [ show ]);

    useEffect(() => {
        if (editCompany && id) {
            API.graphql(graphqlOperation(getCompany, { id })).then(({ data }) => {
                const editCompanyData = data?.getCompany;
                setCompanyData({
                    name: editCompanyData.name
                });
            });
        }
    }, [ editCompany, id ]);

    const handleOnChange = (e) => {
        setCompanyData({
            ...companyData,
            [ e.target.name ]: e.target.value
        });
        setError("");
    };

    const fetchRequests = async () => {
        try {

        } catch (err) {
            throw err;
        }
    };

    const addCompanySubmit = (e) => {
        e.preventDefault();
        let { name } = companyData;
        if (name) {
            setLoader(true);
            try {
                const company = { ...companyData };
                setCompanyData({ ...companyData, company });
                API.graphql(graphqlOperation(createCompany, { input: { name, id: name } }));

                try {
                    events.log = "A company " + companyData.name + " has been created";
                    const event = { ...events };
                    API.graphql(graphqlOperation(createEvent, { input: event }));
                } catch (err) {
                    throw err;
                }
                onCloseModal();
            } catch ({ errors }) {
                setLoader(false);
                setError(errors[ 0 ].message);
            }
        } else {
            setError("Please fill up all fields");
            setLoader(false);
        }

    };

    const editCompanySubmit = async (e) => {
        e.preventDefault();
        let { name } = companyData;
        if (name) {
            setLoader(true);
            await API.graphql(graphqlOperation(updateCompany, {
                input: {
                    ...companyData,
                    id
                }
            }))
                .then(() => {
                    onCloseModal();
                });
        } else {
            setError("Please fill out company name");
        }
    };

    const onCloseModal = () => {
        setLoader(false);
        onHide();
        setError("");
        setCompanyData({
            name: "",
        });
    };

    return (
        <Modal className="add-company" show={show} onHide={() => onCloseModal()}>
            <div className="add-user__cross">
                <BiX
                    key={2}
                    color="#1181F2"
                    size={30}
                    onClick={() => onCloseModal()}
                    className="add-user__cross-image"
                />
            </div>
            <h2>{editCompany ? translate("edit-company") : translate("add-customer")}</h2>
            <span className='text-center text-danger'>{error}</span>
            <form onSubmit={editCompany ? editCompanySubmit : addCompanySubmit}>
                <Row>
                    <TextInput
                        type="text"
                        value={companyData.name}
                        onChange={(e) => handleOnChange(e)}
                        label={translate("company-name")}
                        name="name"
                        required={true}
                    />
                </Row>
                <div className="add-user__btn-div">
                    <ButtonPrimary
                        title={editCompany ? translate("save") : translate("add")}
                        type="submit"
                        backgroundColor="#fff"
                        color="#000"
                        disabled={loader ? true : false}
                        loader={loader}
                        loaderColour='#1181f2'
                    />
                </div>
            </form>
        </Modal>
    );
};

AddEditCompany.propTypes = {
    onHide: PropTypes.func,
    modal: PropTypes.object
};

export default AddEditCompany;
