export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        projects {
          items {
            id
            name
            cameras {
              items {
                id
              }
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCompanysForUsersTable = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        projects {
          items {
            id
            name
            users {
              items{
                users{
                  id
                  username
                  firstname
                  lastname
                  email
                  role
                  createdBy
                  projects {
                    items {
                      id
                      userID
                      projectID
                      createdAt
                      updatedAt
                      projects {
                        id
                        companyID
                        name
                        endedAt
                      }
                    }
                    nextToken
                  }
                }
              }
            }
            cameras {
              items {
                id
                cameraName
              }
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCompaniesByName = /* GraphQL */ `
  query ListCompaniesByName(
    $__typename: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompaniesByName(
      __typename: $__typename
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        projects {
          items {
            id
            companyID
            name
            cameras {
              items {
                id
              }
            }
          }
          nextToken
        }
        __typename
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            imagesByDate(sortDirection: DESC) {
              items {
                id
                cameraID
                datetime
                date
                time
                intTime
                workday
                s3ObjectPathOriginal
                s3ObjectPathThumbnail
                s3ObjectPathBlurred
                s3ObjectPathBlurringPro
                blurringPro
                blurred
                masked
                createdAt
                updatedAt
              }
              nextToken
            }
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          name
          projects {
            nextToken
          }
          __typename
          createdAt
          updatedAt
        }
        users {
          items {
            id
            userID
            projects {
              id
            }
            pinnedCams
            createdAt
            updatedAt
          }
          nextToken
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCloudStatus = /* GraphQL */ `
  query GetCloudStatus($id: ID!) {
    getCloudStatus(id: $id) {
      id
      displayStatus
      displayMessage
      bannerStatus
      bannerMessage
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        firstname
        lastname
        language
        email
        notificationsEnabled
        role
        archiving
        projects {
          items {
            id
            userID
            projectID
            createdAt
            updatedAt
            projects {
              id
              companyID
              name
              location
              latitude
              longitude
              cameraIDs
              shareableLink
              cameraIDs
              updatedAt
              cameras {
                items {
                  id
                  cameraName
                  blurringEnabled
                  cameraAlias
                  username
                  password
                  status
                  liveStream
                  liveStreamLink
                  latestPictureLink
                  ftpLink
                  rmsLink
                  createdAt
                  updatedAt
                  imagesByDate(sortDirection: $sortDirection) {
                    items {
                      id
                      blurred
                      cameraID
                      datetime
                      date
                      time
                      intTime
                      workday
                      s3ObjectPathOriginal
                      s3ObjectPathThumbnail
                      s3ObjectPathBlurred
                      createdAt
                      updatedAt
                    }
                    nextToken
                  }
                }
                nextToken
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUsersForUserTable = /* GraphQL */ `
  query ListUsersForUserTable(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        firstname
        lastname
        language
        email
        role
        createdAt
        updatedAt
        createdBy
        projects {
          items {
            projectID
            id
            projects {
              name
              endedAt
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listUsersForUserProjects = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        firstname
        lastname
        language
        email
        role
        projects {
          items {
            id
            userID
            projectID
            createdAt
            updatedAt
            projects {
              id
              companyID
              name
              location
              latitude
              longitude
              cameraIDs
              shareableLink
              cameraIDs
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getCamera = /* GraphQL */ `
  query GetCamera(
    $id: ID!
    $datetime: ModelStringKeyConditionInput
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    ) {
    getCamera(id: $id) {
      id
      cameraName
      cameraAlias
      username
      password
      status
      project {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        weather_key
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            timelapseEnabled
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            externalCamera
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          name
          projects {
            nextToken
          }
          createdAt
          updatedAt
        }
        users {
          items {
            id
            userID
            projectID
            createdAt
            updatedAt
          }
          nextToken
        }
        endedAt
        cameraIDs
        deleteAllImages
        createdAt
        updatedAt
      }
      imagesByDate(datetime: $datetime, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
        items {
          id
          blurred
          cameraID
          datetime
          date
          time
          intTime
          workday
          s3ObjectPathOriginal
          s3ObjectPathThumbnail
          s3ObjectPathBlurred
          s3ObjectPathBlurringPro
          createdAt
          updatedAt
        }
        nextToken
      }
      liveStream
      liveStreamLink
      latestPictureLink
      ftpLink
      rmsLink
      downloadImagesLink
      deleteAllImages
      changePassword
      hideCopyLink
      blurringEnabled
      createdAt
      updatedAt
    }
  }
`;

export const getCameraFirstImageDateTime = /* GraphQL */ `
  query GetCamera(
    $id: ID!
    $datetime: ModelStringKeyConditionInput
    $filter: ModelImageFilterInput
    $sortDirection: ModelSortDirection
    $limit: Int
    $nextToken: String
    ) {
    getCamera(id: $id) {
      id
      cameraName
      imagesByDate(datetime:$datetime, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
        items {
          id
          datetime
        }
        nextToken
      }
    }
  }
`;

export const listCameras = /* GraphQL */ `
  query ListCameras(
    $filter: ModelCameraFilterInput
    $limit: Int
    $nextToken: String
    $imagesLimit: Int
  ) {
    listCameras(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cameraName
        cameraAlias
        username
        blurringEnabled
        timelapseEnabled
        lastDayTimelapseStatus
        lastWeekTimelapseStatus
        lastMonthTimelapseStatus
        externalCamera
        password
        status
        project {
          id
          name
          cameraIDs
          company {
            id
            name
          }
          createdAt
          updatedAt
        }
        imagesByDate(sortDirection: DESC, limit: $imagesLimit) {
          items {
            id
            blurred
            cameraID
            datetime
            date
            time
            workday
            s3ObjectPathOriginal
            s3ObjectPathThumbnail
            s3ObjectPathBlurred
            createdAt
            updatedAt
          }
          nextToken
        }
        liveStream
        liveStreamLink
        latestPictureLink
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCamerasByName = /* GraphQL */ `
  query ListCamerasByName(
    $__typename: String
    $cameraName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCameraFilterInput
    $limit: Int
    $imagesLimit: Int
    $nextToken: String
  ) {
    listCamerasByName(
      __typename: $__typename
      cameraName: $cameraName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cameraName
        cameraAlias
        username
        password
        status
        project {
          id
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            nextToken
          }
          company {
            id
            name
            createdAt
            updatedAt
          }
          users {
            nextToken
          }
          endedAt
          cameraIDs
          deleteAllImages
          __typename
          createdAt
          updatedAt
        }
        imagesByDate(limit: $imagesLimit, sortDirection: DESC) {
          items {
            id
            cameraID
            datetime
            date
            time
            intTime
            workday
            s3ObjectPathOriginal
            s3ObjectPathThumbnail
            s3ObjectPathBlurred
            s3ObjectPathBlurringPro
            blurred
            masked
            createdAt
            updatedAt
          }
          nextToken
        }
        liveStream
        liveStreamLink
        latestPictureLink
        ftpLink
        rmsLink
        downloadImagesLink
        deleteAllImages
        changePassword
        hideCopyLink
        blurringEnabled
        blurringProEnabled
        maskEnabled
        timelapseEnabled
        externalCamera
        lastDayTimelapseStatus
        lastWeekTimelapseStatus
        lastMonthTimelapseStatus
        __typename
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      companyID
      name
      location
      latitude
      longitude
      shareableLink
      pendingDeletion
      cameras {
        items {
          id
          cameraName
          cameraAlias
          username
          blurringEnabled
          timelapseEnabled
          lastDayTimelapseStatus
          lastWeekTimelapseStatus
          lastMonthTimelapseStatus
          externalCamera
          password
          status
          project {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            createdAt
            updatedAt
          }
          imagesByDate(sortDirection: DESC) {
            items {
              id
              cameraID
              blurred
              datetime
              date
              time
              intTime
              workday
              s3ObjectPathOriginal
              s3ObjectPathThumbnail
              s3ObjectPathBlurred
              s3ObjectPathBlurringPro
              createdAt
              updatedAt
            }
            nextToken
          }
          liveStream
          liveStreamLink
          latestPictureLink
          ftpLink
          rmsLink
          downloadImagesLink
          deleteAllImages
          changePassword
          createdAt
          updatedAt
        }
        nextToken
      }
      company {
        id
        name
        projects {
          items {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      users {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            createdAt
            updatedAt
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            createdAt
            updatedAt
            createdBy
            projects {
              items {
                id
                userID
                projectID
                createdAt
                updatedAt
                projects {
                  id
                  name
                  endedAt
                }
              }
              nextToken
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      endedAt
      cameraIDs
      deleteAllImages
      createdAt
      updatedAt
    }
  }
`;

export const getUsersForSuperUser = `
  query GetUsersForSuperUser($id: ID!) {
    getProject(id: $id) {
      users {
        items {
          users {
            id
            lastname
            role
            firstname
            status
            email
            username
            createdBy
            projects {
              items {
                id
                userID
                projectID
                createdAt
                updatedAt
                projects {
                  id
                  companyID
                  name
                  location
                  latitude
                  longitude
                  cameraIDs
                  shareableLink
                  cameraIDs
                  updatedAt
                  endedAt
                }
              }
            }
          }
        }
     }
      name
    }
  }
`;

export const getSuperUserProjects = `
query MyQuery($id: ID!) {
  getUser(id: $id) {
    projects {
      items {
        projects {
          name
          id
        }
      }
    }
  }
}`;

export const listProjectsByName = /* GraphQL */ `
  query ListProjectsByName(
    $__typename: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByName(
      __typename: $__typename
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            timelapseEnabled
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            externalCamera
            imagesByDate(sortDirection: DESC) {
              items {
                id
                blurred
                cameraID
                datetime
                date
                time
                intTime
                workday
                s3ObjectPathOriginal
                s3ObjectPathThumbnail
                s3ObjectPathBlurred
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          name
          projects {
            nextToken
          }
          createdAt
          updatedAt
        }
        endedAt
        cameraIDs
        deleteAllImages
        __typename
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listProjectsOptions = /* GraphQL */ `
  query ListProjectsByName(
    $__typename: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByName(
      __typename: $__typename
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        latitude
        longitude
        location
        name
      }
      nextToken
    }
  }
`;

export const getCameraForTimelapse = /* GraphQL */ `
  query GetCamera($id: ID!) {
    getCamera(id: $id) {
      id
      cameraName
      timelapseEnabled
      lastDayTimelapseStatus
      lastWeekTimelapseStatus
      lastMonthTimelapseStatus
      status
      createdAt
      updatedAt
      project {
        id
      }
    }
  }
`;

export const listUserProjectsByUser = /* GraphQL */ `
  query ListUserProjectsByUser(
    $filter: ModelUserProjectFilterInput
    $limit: Int
    $nextToken: String
    $projectID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $userID: ID
  ) {
    listUserProjectsByUser(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      projectID: $projectID
      sortDirection: $sortDirection
      userID: $userID
    ) {
      items {
        createdAt
        id
        pinnedCams
        projectID
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listWeatherDatas = /* GraphQL */ `
  query ListWeatherDatas(
    $filter: ModelWeatherDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeatherDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        location_date
        utc_timestamp
        summary
        temperature
        precipitation
        wind
        wind_gust
        pressure
        cloud_cover
        location_key
        icon_id
      }
      nextToken
    }
  }
`;

export const getProjectWeatherKey = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      weather_key
      cameraIDs
    }
  }
`;

export const getRecentProjectsByUser = /* GraphQL */ `
query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      recentProjects
      __typename
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
