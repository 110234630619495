import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import GoogleMapReact from "google-map-react";
import { Link } from "react-router-dom";
import { RiMapPin2Fill } from "react-icons/ri";
import "../styles/components/googlemap.scss";
import { getCredentials } from "../graphql/queries";

const styles = {
    default: [],
    silver: [
        {
            elementType: "labels.icon",
            stylers: [ { visibility: "off" } ]
        },
        {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [ { color: "#dadada" } ]
        },
        {
            featureType: "water",
            elementType: "geometry",
            stylers: [ { color: "#B4F0F0" } ]
        },
    ]
};

class GoogleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pinSize: 24,
            projectIndex: null,
            apiKey: ""
        };
    }

    static defaultProps = {
        center: {
            lat: 51.2414889,
            lng: 10.42862
        },
        zoom: 7,
    };

    UNSAFE_componentWillMount() {
        document.body.removeEventListener("click", this.handleClick, false);
    }

    componentDidMount() {
        document.body.addEventListener("click", this.handleClick, false);
        this.fetchApiKey();
    }

    handleClick = (e) => {
        if (this.state.projectIndex !== null) {
            this.setState({ projectIndex: null });
        }
    }

    fetchApiKey = async () => {
        try {
            const requestData = await API.graphql(graphqlOperation(getCredentials, { id: "react-bootstrap-url-key" }));
            const data = requestData.data.getCredentials;
            this.setState({ apiKey: data.googleApiKey });
        } catch (err) {
        }
    }


    render() {
        const { projects, role } = this.props;
        return (
            // Important! Always set the container height explicitly
            <div
                className="googlemap"
                style={{ height: "100vh", width: "100%", borderRadius: "20px", overflow: "hidden" }}
            >
                {this.state.apiKey && 
                <GoogleMapReact
                    options={{
                        styles: styles.silver,
                    }}
                    bootstrapURLKeys={{ key: this.state.apiKey }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >

                    {(role === "admin" || role === "crew") ? projects && projects.map((project, i) => (
                        <div
                            className="googlemap__pin"
                            key={i}
                            lat={project.latitude}
                            lng={project.longitude}
                        >
                            <RiMapPin2Fill
                                color={this.state.projectIndex === i ? "red" : "#1181F2"}
                                size={24}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({ projectIndex: i });
                                }
                                }
                            />
                            <Link to={`/projects/${project.id}`}>
                                <div
                                    ref={node => this.ref = node}
                                    className="googlemap__label"
                                    style={this.state.projectIndex === i ?
                                        { display: "flex" } :
                                        { display: "none" }}
                                >
                                    <div>
                                        <span className="googlemap__location">
                                            {project.location}
                                        </span>
                                        <span className="googlemap__project-company">
                                            {project.name}
                                        </span>
                                    </div>
                                </div>
                            </Link>

                        </div>
                    )) : (projects && projects.map((project, i) => (
                        (project.projects.longitude && project.projects.latitude) ?
                            <div
                                className="googlemap__pin"
                                key={i}
                                lat={project.projects.latitude}
                                lng={project.projects.longitude}
                            >
                                <RiMapPin2Fill
                                    color={this.state.projectIndex === i ? "red" : "#1181F2"}
                                    size={24}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({ projectIndex: i });
                                    }
                                    }
                                />
                                <Link to={`/projects/${project.projects.id}`}>
                                    <div 
                                        ref={node => this.ref = node}
                                        className="googlemap__label"
                                        style={this.state.projectIndex === i ?
                                            { display: "flex" } :
                                            { display: "none" }}
                                    >
                                        <div>
                                            <span className="googlemap__location">
                                                {project.projects.location}
                                            </span>
                                            <span className="googlemap__project-company">
                                                {project.projects.name}
                                            </span>
                                        </div>
                                    </div>
                                </Link>

                            </div> : null
                    )))}

                </GoogleMapReact>}

            </div>
        );
    }
}

export default GoogleMap;
