import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Storage } from "aws-amplify";
import "../../styles/pages/Cams/cams.scss";
import { IoMdRefresh } from "react-icons/io";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import { Row } from "react-bootstrap";
import Pagination from "../../components/Pagination";
import DropdownInput from "../../components/Dropdown";
import useMediaQuery from "@mui/material/useMediaQuery";
import CreateEditCamera from "../Camera/CreateEditCamera";
import CameraPopUp from "./CamsPopUp";
import DeleteModal from "../../components/DeleteModal";
import { listProjects } from "../../graphql/queries";
import { getProject, listCamerasByName } from "../../helpers/manualQueries";
import { deleteCamera, createEvent } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import Select from "react-select";
import {
    onCreateCamera,
    onUpdateCamera,
    onDeleteCamera,
    onCreateImage,
} from "../../graphql/subscriptions";
import { Auth } from "aws-amplify";
import translate from "../../i18n/translate";
import Loader from "../../components/Loader";
import LiveVideoPopup from "../../components/LiveVideoPopup";
import TimelapsePopup from "../../components/TimelapesPopup";
import _ from "lodash";
import CamsCard from "./CamsCard";

Storage.configure({
    customPrefix: {
        public: "",
    },
});

const Cams = ({ location, history }) => {
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ itemPerPage ] = useState(36);
    const [ cameraPopUp, setCameraPopUp ] = useState(false);
    const [ modal, openModal ] = useState({
        editCamera: false,
        show: false,
        id: "",
    });
    const [ filter, setFilter ] = useState(translate("cams"));
    const [ filterValue, setFilterValue ] = useState(null);
    const [ projects, setProjects ] = useState([]);
    const [ listCamerasData, setListCamerasData ] = useState([]);
    const [ loader, setLoader ] = useState(false);
    const [ dataLoader, setDataLoader ] = useState(false);
    const [ deleteError, setDeleteError ] = useState("");
    const [ cameraId, setCameraId ] = useState("");
    const [ popupDatetime, setPopupDatetime ] = useState("");
    const [ deleteState, openDeleteModal ] = useState({
        name: "",
        show: false,
        id: "",
    });
    const [ livePopUp, setLivePopUp ] = useState(false);
    const [ openTimelapse, setOpenTimelapse ] = useState(false);
    const [ liveState, setLiveState ] = useState({
        cameraName: "",
        liveStreamLink: "",
    });

    const [ events ] = useState({
        log: "",
        user: Auth.user.username,
        expire: "",
        logType: "camera"
    });
    const [ projectID, setProjectID ] = useState([]);
    const isSM = useMediaQuery("(max-width:900px)");
    const [ popupCam, setPopupCam ] = useState();
    const [ S3ObjectPathBlurred, setS3ObjectPathBlurred ] = useState();
    const [ CameraKey, setCameraKey ] = useState();
    const [ nextToken, setNextToken ] = useState(undefined);
    const [ nextNextToken, setNextNextToken ] = useState();
    const [ previousTokens, setPreviousTokens ] = useState([]);
    const tempUseEffectFunc = useRef();
    const tempGetCamerasListFunc = useRef();
    const [ val, setVal ] = useState(null);
    const tempUseEfectFunc = useRef();
    const onCreateCameraFunc = useRef();
    const onUpdateCameraFunc = useRef();
    const onDeleteCameraFunc = useRef();
    const onCreateImageFunc = useRef();

    let now = (Date.now() + 15552000000) / 1000;
    events.expire = Math.round(now);

    const tempUseEffect = () => {
        fetchListProjects();
        getCamerasList(true);
    };

    useEffect(() => {
        tempUseEffectFunc.current();
    }, [ location.state ]);
    tempUseEffectFunc.current = tempUseEffect;

    useEffect(() => {
        onCreateCameraFunc.current = API.graphql(
            graphqlOperation(onCreateCamera)
        ).subscribe({
            next: ({
                value: {
                    data: { onCreateCamera },
                },
            }) => {
                setListCamerasData((previousData) => [ ...previousData, onCreateCamera, ]);
            },
        });
        onUpdateCameraFunc.current = API.graphql(
            graphqlOperation(onUpdateCamera)
        ).subscribe({
            next: ({
                value: {
                    data: { onUpdateCamera },
                },
            }) => {
                setListCamerasData((previousData) =>
                    previousData.map((camera) => {
                        if (camera.id === onUpdateCamera.id) {
                            return (camera = onUpdateCamera);
                        } else return camera;
                    })
                );
            },
        });

        onDeleteCameraFunc.current = API.graphql(
            graphqlOperation(onDeleteCamera)
        ).subscribe({
            next: ({
                value: {
                    data: { onDeleteCamera },
                },
            }) => {
                setListCamerasData((previousData) =>
                    previousData.filter((camera) => {
                        if (camera.id !== onDeleteCamera.id) return camera;
                        return false;
                    })
                );
            },
        });

        onCreateImageFunc.current = API.graphql(
            graphqlOperation(onCreateImage)
        ).subscribe({
            next: ({
                value: {
                    data: { onCreateImage },
                },
            }) => {
                setListCamerasData((previousData) => {
                    let allCameras = [ ...previousData ];
                    allCameras.filter((camera) => {
                        if (camera.id === onCreateImage.cameraID) {
                            camera.latestPictureLink =
                                onCreateImage.s3ObjectPathOriginal;
                            camera.createdAt = onCreateImage.createdAt;
                        } else return camera;
                        return false;
                    });
                    return allCameras;
                });
            },
        });

        return () => {
            onCreateCameraFunc.current.unsubscribe();
            onUpdateCameraFunc.current.unsubscribe();
            onDeleteCameraFunc.current.unsubscribe();
            onCreateImageFunc.current.unsubscribe();
        };
    }, []);

    const setHeight = () => {
        const height = localStorage.getItem("height");
        document.documentElement.scrollTop = height;
        localStorage.removeItem("height");
    };

    const getCamerasList = async (loader) => {
        setDataLoader(loader);
        if (filter?.props.id === "status" && filterValue !== null) {
            API.graphql(
                graphqlOperation(listCamerasByName, {
                    filter: { status: { eq: filterValue } },
                    limit: 10000,
                    imagesLimit: 2,
                })
            )
                .then(
                    ({
                        data: {
                            listCamerasByName: { items },
                        },
                    }) => {
                        setListCamerasData(items);
                        setDataLoader(false);
                        setHeight();
                    }
                )
                .catch(() => {
                    setDataLoader(false);
                });
        } else if (filter?.props.id === "project" && filterValue !== null) {
            API.graphql(
                graphqlOperation(getProject, {
                    id: filterValue,
                    limit: 10000,
                })
            )
                .then(({ data: { getProject } }) => {
                    setListCamerasData(getProject?.cameras?.items);
                    setDataLoader(false);
                    setHeight();
                    
                })
                .catch(() => {
                    setDataLoader(false);
                });
        } else if (filter?.props.id === "cams" && filterValue !== null) {
            API.graphql(
                graphqlOperation(listCamerasByName, {
                    cameraName: { beginsWith: filterValue },
                    limit: 10000,
                    imagesLimit: 2,
                })
            )
                .then(
                    ({
                        data: {
                            listCamerasByName: { items },
                        },
                    }) => {
                        setListCamerasData(items);
                        setDataLoader(false);
                        setHeight();
                    }
                )
                .catch(() => {
                    setDataLoader(false);
                });
        } else if (filter?.props.id === "blurring" && filterValue !== null) {
            API.graphql(
                graphqlOperation(listCamerasByName, {
                    filter: { blurringEnabled: { eq: filterValue } },
                    limit: 10000,
                    imagesLimit: 2,
                })
            )
                .then(
                    ({
                        data: {
                            listCamerasByName: { items },
                        },
                    }) => {
                        setListCamerasData(items);
                        setDataLoader(false);
                        setHeight();
                    }
                )
                .catch(() => {
                    setDataLoader(false);
                });
        } else if (location.state) {
            API.graphql(
                graphqlOperation(listCamerasByName, {
                    cameraName: { beginsWith: location.state.camera },
                    limit: 10000,
                    imagesLimit: 2,
                })
            )
                .then(
                    ({
                        data: {
                            listCamerasByName: { items },
                        },
                    }) => {
                        setListCamerasData(items);
                        setDataLoader(false);
                        setHeight();
                    }
                )
                .catch(() => {
                    setDataLoader(false);
                });
        }
        else {
            API.graphql(
                graphqlOperation(listCamerasByName, { limit: 36, imagesLimit: 2, nextToken })
            )
                .then(
                    ({
                        data: {
                            listCamerasByName: { items, nextToken },
                        },
                    }) => {
                        setListCamerasData(items);
                        setNextNextToken(nextToken);
                        setDataLoader(false);
                        setHeight();
                    }
                )
                .catch(() => {
                    setDataLoader(false);
                });
        }
    };

    const tempGetCamerasList = () => {
        getCamerasList(false);
    };

    useEffect(() => {
        tempGetCamerasListFunc.current();
        const intervalForCamera = setInterval(() => {
            tempGetCamerasListFunc.current();
        }, 60000);
        return () => clearInterval(intervalForCamera);
    }, [ filterValue, nextToken ]);

    tempGetCamerasListFunc.current = tempGetCamerasList;

    const fetchListProjects = () => {
        API.graphql(graphqlOperation(listProjects, { limit: 1000 })).then(
            ({
                data: {
                    listProjects: { items },
                },
            }) => {
                setProjects(items);
            }
        );
    };

    const paginate = (selectedPage) => {
        let selected = selectedPage.selected;
        setCurrentPage(selected);
        document.documentElement.scrollTop = 0;
    };

    const deleteCameraFunc = () => {
        setLoader(true);
        API.graphql(
            graphqlOperation(deleteCamera, {
                input: {
                    id: deleteState.id,
                },
            })
        )
            .then(() => {
                try {
                    events.log =
                        "A camera " + deleteState.name + " has been deleted";
                    const event = { ...events };
                    API.graphql(
                        graphqlOperation(createEvent, { input: event })
                    );
                } catch (err) {
                    throw err;
                }
                setLoader(false);
                openDeleteModal({ name: "", show: false });
            })
            .catch(({ errors }) => {
                setLoader(false);
                setDeleteError(errors[ 0 ]?.message);
            });
    };

    const filterOptions = {
        blurring: [ { value: "false", label: translate("disabled") }, { value: "true", label: translate("enabled") }, ],
        status: [
            { value: "active", label: translate("active") },
            { value: "inactive", label: translate("inactive") },
            { value: "failure", label: translate("failure") },
        ],
        project: projects.map((project) => ({
            value: project.id,
            label: project.name,
        })),
    };

    const handleFilterChange = (filterValue) => {
        setListCamerasData([]);
       
        setFilterValue(filterValue.value);
        setVal(filterValue);
           
    };

    const tempUseEfect = () => {
        if (filterValue) {
            const debouncedSave = _.debounce(() => getCamerasList(false), 500);
            debouncedSave();
            return debouncedSave.cancel;
        } else if (filterValue !== null) {
            setTimeout(() => {
                setFilterValue(null);
                getCamerasList(true);
            }, 1000);
        }
    };
    useEffect(() => {
        tempUseEfectFunc.current();
    }, [ filterValue ]);
    tempUseEfectFunc.current = tempUseEfect;

    const indexOfLastItem = (currentPage + 1) * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItem = listCamerasData.slice(indexOfFirstItem, indexOfLastItem);

    const onClickFunc = (e) => {
        setPopupCam(e);
        setCameraKey(e.imagesByDate.items[ 0 ]?.s3ObjectPathThumbnail);
        setS3ObjectPathBlurred(e.imagesByDate.items[ 0 ]?.s3ObjectPathBlurred);
    };

    const handleNextPage = () => {
        setPreviousTokens([ ...previousTokens, nextToken ]);
        setNextToken(nextNextToken);
    };

    const handlePreviousPage = () => {
        setNextToken(previousTokens.pop());
    };


    return (
        <div className='overview'>
            {isSM ? <div className="users__cover">
                <ButtonPrimary
                    backgroundColor='#fff'
                    color='#000'
                    title={translate("new-camera")}
                    onClick={() =>
                        openModal({
                            ...modal,
                            show: true,
                        })
                    }
                />
            </div> :
                <div className="users__filter" style={{ width: "50%", float: "left", justifyContent: "flex-start" }}>
                    {isSM ? null :  <ButtonPrimary
                        backgroundColor='#fff'
                        color='#000'
                        title={translate("new-camera")}
                        onClick={() =>
                            openModal({
                                ...modal,
                                show: true,
                            })
                        }
                    />}

                </div>}
            <div className="users__filter" style={{ width: isSM ? "100%" : "50%", float: "left" }}>

                <div className="overview__dropdown">
                    <DropdownInput
                        name='filter'
                        item={[
                            translate("status"),
                            translate("project"),
                            translate("cams"),
                            translate("blurring"),
                        ]}
                        label={translate("filter-by")}
                        title={filter ? filter : translate("cams")}
                        iconSize={16}
                        onSelect={(val) => {
                            setFilter(val);
                            setFilterValue(null);
                        }}
                    />
                </div>

                <form autoComplete='off'>
                    {filter?.props?.id !== "cams" ? (
                        <Select
                            options={filterOptions[ filter?.props.id ]}
                            onChange={handleFilterChange}
                            value={val}
                        />
                    ) : (
                        <input
                            onChange={(e) => setFilterValue(e.target.value)}
                            value={filterValue || ""}
                            className='overview__searchInput'
                        />
                    )}
                </form>
                <button
                    onClick={() => {setFilterValue(null); setVal(null); history.replace({ state: {} }); }}
                    className='overview__reset-btn'
                >
                    <IoMdRefresh color='#1181F2' size={28} />
                </button>


            </div>
            <div className="overview__dropdown">
                <Row>
                    {dataLoader ? (
                        <Loader />
                    ) : currentItem.length ? ( 
                        currentItem.map((val, i) => (
                            <CamsCard
                                key={i}
                                cameraData={val}
                                setCameraPopUp={() => setCameraPopUp(true)}
                                setCameraId={() => setCameraId(val.id)}
                                setPopupDatetime={(datetime) =>
                                    setPopupDatetime(datetime)
                                }
                                setProjectID={() =>
                                    setProjectID(val.project?.id)
                                }
                                onClickFunc={() => onClickFunc(val)}
                                setLivePopUp={(boolean) =>
                                    setLivePopUp(boolean)
                                }
                                setLiveState={(data) => setLiveState(data)}
                                openModal={(modalData) => openModal(modalData)}
                                openDeleteModal={(deleteModalData) =>
                                    openDeleteModal(deleteModalData)
                                }
                                openTimelapseFunc={(boolean) => setOpenTimelapse(boolean)}
                            />
                        ))
                    ) : (
                        <div style={{ width: "100%" }}>
                            <Loader></Loader>
                            {dataLoader ? <h2 className='handle-empty-element'>
                                {translate("no-cameras")}
                            </h2> : null}
                        </div>
                    )}
                    <CreateEditCamera
                        modal={modal}
                        onHide={() =>
                            openModal({
                                editUser: false,
                                show: false,
                                id: "",
                            })
                        }
                    />
                    {cameraPopUp && (
                        <CameraPopUp
                            open={cameraPopUp}
                            id={cameraId}
                            onHide={() => setCameraPopUp(false)}
                            rmsLink={true}
                            projectID={projectID}
                            datetime={popupDatetime}
                            popupCam={popupCam}
                            CameraKey={CameraKey}
                            S3ObjectPathBlurred={S3ObjectPathBlurred}
                        />
                    )}
                    <DeleteModal
                        open={deleteState}
                        onHide={() =>
                            openDeleteModal({ name: "", show: false })
                        }
                        deleteFunc={() => deleteCameraFunc()}
                        error={deleteError}
                        loader={loader}
                        canDelete={true}
                    />
                    {dataLoader ?  <Pagination
                        currentPage={currentPage}
                        itemPerPage={itemPerPage}
                        totalItems={listCamerasData.length}
                        paginate={paginate}
                        path='/'
                    /> : null}
                   
                </Row>
            </div>
            <div className='pagination-container'>
                <button
                    onClick={handlePreviousPage}
                    className='pagination-arrow'
                    disabled={previousTokens?.length === 0}
                >
                    <FaChevronLeft
                        color={previousTokens?.length === 0 ? "lightgrey" : "#1181F2"}
                        size={14}
                        className='pagination__btn mr-2'
                    />{" "}
                </button>
                <div className="pagination__page">Page {previousTokens.length + 1}</div>
                <button
                    onClick={handleNextPage}
                    className='pagination-arrow'
                    disabled={!nextNextToken}
                >
                    <FaChevronRight
                        color={!nextNextToken ? "lightgrey " : "#1181F2"}
                        size={14}
                        className='pagination__btn ml-2'
                    />
                </button>
            </div>
            <div className='status-container'>
                <LiveVideoPopup
                    open={livePopUp}
                    onHide={() => setLivePopUp(false)}
                    cameraName={liveState.cameraName}
                    liveStreamLink={liveState.liveStreamLink}
                />
            </div>
            <TimelapsePopup 
                cameraId={liveState.id} 
                open={openTimelapse} 
                onHide={() => setOpenTimelapse(false)}
                projectId={liveState.project?.id}
            />
        </div>
    );
};

Cams.propTypes = {
    location: PropTypes.any
};

export default Cams;



