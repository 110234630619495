import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FiMaximize2, FiMinimize2 } from "react-icons/fi";
import "../styles/pages/Cams/cams-popup.scss";
import { GrClose } from "react-icons/gr";

export const PopupNavBtns = ({ onClickFullscreen, onClickSeek, archive }) => {
    const [isHidden, setIsHiddenTo] = useState(false);
    const [buttonClassMin, setButtonClassMin] = useState("mod expand-btn--max");
    const [buttonClassMax, setButtonClassMax] = useState(
        "mod_ expand-btn--max"
    );

    const iconHandler = () => {
        if (isHidden) {
            setIsHiddenTo(false);
        } else {
            setIsHiddenTo(true);
        }
    };
    
    useEffect(() => {
        if (isHidden) {
            setButtonClassMin("mod expand-btn--max");
            setButtonClassMax("mod_ expand-btn--max");
        } else {
            setButtonClassMin("mod_ expand-btn--max");
            setButtonClassMax("mod expand-btn--max");
        }
    }, [isHidden]);

    const onClickFunc = () => {
        iconHandler();
        onClickFullscreen();
    };

    return (
        <div className='wrapper' style={{ display: "inline" }}>
            <button onClick={onClickFunc} className={buttonClassMin} style={archive === false ? { top: "0px " } : { top: "35px" }}>
                <FiMaximize2 size={20} color='black' />
            </button>
            <button onClick={onClickFunc} className={buttonClassMax} style={archive === false ? { top: "0px " } : { top: "35px" }}>
                <FiMinimize2 size={20} color='black' />
            </button>
            <button onClick={onClickSeek} className='expand' style={archive === false ? { top: "0px" } : { top: "35px" }}>
                <GrClose size={20} color='black' />
            </button>
        </div>
    );
};

PopupNavBtns.propTypes = {
    onClickFullscreen: PropTypes.func,
    onClickSeek: PropTypes.func,
    archive: PropTypes.bool,
};