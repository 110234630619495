import React from "react";

const UsersPageManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>Users</h2>
                Here you can invite other people, such as colleagues or customers, to the project. All you need is the name and email address of the new user.  
                <br/><br/>

Under Role, you specify whether the new user can see all images in the archive (user) or whether they can only see the current image (limited user).  
                <br/><br/>

Below this selection field, you can assign one or more of your projects to the new user. Clicking on the respective project changes the color to blue and the project is selected.
                <br/><br/>

As soon as you click on "+ Add", the new user will receive an e-mail with their personal access to your selected projects.  
            </div></div>
    );
};

export default UsersPageManual;