import React, { useState, useEffect, useRef } from "react";
import "../../styles/pages/Archive/ArchiveHead.scss";
import { Col } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import PropTypes from "prop-types";
import translate from "../../i18n/translate";
import SelectDropdown from "../../components/SelectDropdown";
import { getProjectWeatherKey } from "../../helpers/manualQueries";
import { API, graphqlOperation, Auth } from "aws-amplify";
import ReactTooltip from "react-tooltip";
import { useIntl } from "react-intl";

export const ArchiveHead = ({ history, camera, action, match, hasTimeRange, downloadStatus }) => {

    const [ options, setOptions ] = useState([]);
    const [ isProjectFetched, setIsProjectFetched ] = useState(false);
    const [ projectName, setProjectName ] = useState("");
    const [ role, setRole ] = useState("");
    const [ selectedCamera, setSelectedCamera ] = useState({
        value: "",
        label: ""
    });
    const [ statusDotColor, setStatusDotColor ] = useState("");
    const [ tooltipStatus, setTooltipStatus ] = useState("");
    const fetchProjectFunc = useRef();
    const intl = useIntl();

    useEffect(() => {
        if (downloadStatus === "available") {
            setStatusDotColor("#00B607");
            setTooltipStatus(intl.formatMessage({ id: "ready-to-download" }));
        } else if (downloadStatus === "requested") {
            setStatusDotColor("#FFBB29");
            setTooltipStatus(intl.formatMessage({ id: "requested-download" }));
        }
        else if (downloadStatus === "in progress") {
            setStatusDotColor("#1181f2");
            setTooltipStatus(intl.formatMessage({ id: "download-in-progress" }));
        }
        else if (downloadStatus === "failed") {
            setStatusDotColor("red");
            setTooltipStatus(intl.formatMessage({ id: "download-failed" }));
        }
    }, [ downloadStatus ]);

    const fetchProject = async () => {
        try {
            const project = await API.graphql(graphqlOperation(getProjectWeatherKey, { id: match.params.id }));
            const projectData = project.data.getProject;
            setProjectName(projectData.name);

            let allOptions = Object.entries(JSON.parse(projectData.cameraIDs)).map(([ key, value ]) => ({
                value: key,
                label: key,
                startAndEndDate: value
            }));

            const cameraId = match.params.cameraId;
            let selectedCamera;
            if (cameraId !== "noCamera") {
                selectedCamera = allOptions.find((camera) => camera.value === cameraId);
            } else {
                selectedCamera = allOptions[ 0 ];
            }

            camera(selectedCamera);
            setOptions(allOptions);
            setSelectedCamera(selectedCamera);
            setIsProjectFetched(true);

        } catch (err) {
            setIsProjectFetched(true);
        }
    };
    fetchProjectFunc.current = fetchProject;

    useEffect(() => {
        fetchProjectFunc.current();
        getRole();
    }, []);

    const handleChangeSelectCam = (selectedCam) => {
        camera(selectedCam);
    };

    const getRole = async () => {
        const userDetails = await Auth.currentAuthenticatedUser();
        const role = userDetails?.signInUserSession?.accessToken?.payload[ "cognito:groups" ][ 0 ];
        setRole(role.toString().toLowerCase());
    };

    const isAdminAndCrewRole = role === "admin" || role === "crew" ? true : false;

    return (
        <>
            <div className="Archive__Head">
                <div
                    className="Archive__Head-allProjects"
                    onClick={() => history.goBack()}
                >
                    <BsArrowLeft
                        className="Archive__Head-arrow"
                        size={18}
                        color="#1181F2"
                    />
                    {translate("go-back")}
                </div>
                <div className="Archive__Head-Heading">
                    <span>{translate("archive")}</span>
                </div>
                <div className="Archive__Head-Address">{projectName}</div>
                <Col md={12} sm={12} className="Archive__Head-dropdown">
                    <div className="Archive__Head-Filter">
                        <label className="mb-0 mr-2">{translate("select-camera-view")}</label>
                        {isProjectFetched && (
                            <SelectDropdown
                                onChange={handleChangeSelectCam}
                                options={options}
                                defaultValue={selectedCamera}
                            />)}
                    </div>

                    <Col md={7} sm={12} className="Archive__Head-dropdown-btns">
                        <Col 
                            sm={12} 
                            md={isAdminAndCrewRole ? 8 : 4} 
                            lg={isAdminAndCrewRole ? 7 : 3} 
                            className="Archive__Head-primary-btns"
                        >
                            {isAdminAndCrewRole && (
                                <ButtonPrimary
                                    backgroundColor="#fff"
                                    color="#000"
                                    title={translate("delete")}
                                    onClick={() => action("Delete")}
                                />
                            )}
                            <ButtonPrimary
                                backgroundColor="#fff"
                                color="#000"
                                title={
                                    <div className="d-flex align-items-center" data-tip={tooltipStatus}>
                                        <div className="download-status" style={{ background: statusDotColor }} />
                                        &nbsp;&nbsp;
                                        {translate("download")}
                                    </div>
                                }
                                onClick={() => action("Download")}
                                disabled={!hasTimeRange}
                            />
                            <ReactTooltip
                                place='top'
                                type='dark'
                                effect='float'
                            />
                        </Col>
                    </Col>
                </Col>
            </div>
            <hr />
        </>
    );
};

ArchiveHead.propTypes = {
    history: PropTypes.any,
    camera: PropTypes.func,
    action: PropTypes.func,
    match: PropTypes.any,
    hasTimeRange: PropTypes.bool,
    downloadStatus: PropTypes.string,
};
