import React from 'react';
import { Form } from 'react-bootstrap';
import '../styles/components/textInput.scss';
import PropTypes from 'prop-types';

export const TextInput = ({
    label,
    type,
    placeholder,
    onChange,
    value,
    formText,
    name,
    required,
    disabled,
    step,
    newComponent
}) => (
    <>
        <Form.Group >
            <Form.Label className="label">{label}</Form.Label>
            <Form.Control
                className="inputField"
                onChange={onChange}
                type={type}
                value={value}
                placeholder={placeholder}
                name={name}
                required={required}
                disabled={disabled}
                step={step}
            />
        </Form.Group>
        {formText ? <p className="textMuted" onClick = {()=> newComponent()}>{formText}</p> : null}
    </>
);

TextInput.propTypes = {
    label: PropTypes.any,
    step: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    formText: PropTypes.object,
    name: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    newComponent:PropTypes.func
};