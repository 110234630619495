import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { MdClose } from "react-icons/md";
import Loader from "./Loader";
import { AiFillCaretRight } from "react-icons/ai";
import "../styles/components/livestream.scss";

const LiveVideoPopup = ({ open, onHide, cameraName, liveStreamLink }) => {
    const [loaded, setLoaded] = useState(false);
    const onClose = () => {
        setLoaded(false);
        onHide();
    };

    return (
        <Modal
            className="camera-popup"
            show={open}
            onHide={() => onClose()}
            centered
            styles={{paddingRight: "0px"}}
        >
            <div className="livestream">
                <button 
                    className="livestream__close" 
                    onClick={() => onClose()}
                >
                    <MdClose size={22} />
                </button>
                <div className="livestream__live-cam">
                    <span className="livestream__live-name">
                        {cameraName}
                    </span>
                    <span className="livestream__live-text">
                        <AiFillCaretRight color="#00B607"/>&nbsp;
                        Live
                    </span>
                </div>
                <img 
                    src={liveStreamLink}
                    alt=""
                    className="livestream__image"
                    onLoad={() => setLoaded(true)}
                />
                {(loaded? null : <Loader />)}
            </div>
        </Modal>
    );

};


LiveVideoPopup.propTypes = {
    open: PropTypes.bool,
    onHide: PropTypes.func,
    cameraName: PropTypes.string,
    liveStreamLink: PropTypes.string,
};

export default LiveVideoPopup;