import React, { useState, useEffect, useRef } from "react";
import { ArchiveHead } from "./ArchiveHead";
import { Row, Col } from "react-bootstrap";
import { GoPrimitiveDot } from "react-icons/go";
import { IoMdRefresh } from "react-icons/io";
import { FaTimes, FaRegCalendarAlt } from "react-icons/fa";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import DatePicker from "rsuite/DatePicker";
import DateRangePicker from "rsuite/DateRangePicker";
import DeleteModal from "../../components/DeleteModal";
import Checkbox from "@mui/material/Checkbox";
import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import "rc-time-picker/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/pages/Archive/Archive.scss";
import "rsuite/dist/rsuite.css";
import translate from "../../i18n/translate";
import SelectDropdown from "../../components/SelectDropdown";
import { MdArrowDropDown } from "react-icons/md";
import { getCamera } from "../../helpers/manualQueries";
import Loader from "../../components/Loader";
import SimpleModal from "../../components/SimpleModal";
import DownloadingPopup from "../../components/DowloadingPopup";
import moment from "moment";
import { ArchiveImage } from "./ArchiveImage";
import { deleteImage, updateProject, updateUser } from "../../graphql/mutations";
import { onDeleteImage } from "../../graphql/subscriptions";
import ArchiveImagePopup from "./ArchiveImagePopup";
import { listUsers, getUser } from "../../graphql/queries";


Storage.configure({
    customPrefix: {
        public: ""
    },
});

const Archive = ({ history, match }) => {
    const mobileView = useMediaQuery("(max-width:769px");
    const [ camFilter, setCamFilter ] = useState("");
    const [ dateFilter, setDateFilter ] = useState("By Period");
    const [ camTitle, setCamTitle ] = useState("");
    const [ error, setError ] = useState("");
    const [ Delete, setDelete ] = useState(false);
    const [ remainingImagesState, setRemainingImagesState ] = useState([]);
    const [ open, setOpen ] = useState({
        show: false,
        name: "",
        selected: ""
    });
    const [ fromDate, setFromDate ] = useState();
    const [ toDate, setToDate ] = useState();
    const [ fromTime, setFromTime ] = useState();
    const [ toTime, setToTime ] = useState();
    const [ onlyWorkingDay, setOnlyWorkingDay ] = useState(false);
    const [ deleteLoader, setDeleteLoader ] = useState(false);
    const [ selectedAction, setSelectedAction ] = useState("Download");
    const [ cameras, setCameras ] = useState([]);
    const [ loader, setLoader ] = useState(true);
    const [ token, setToken ] = useState(false);
    const [ status, setStatus ] = useState(null);
    const [ role, setRole ] = useState();
    const [ loadWithDate, setLoadWithDate ] = useState({
        from: "",
        to: ""
    });
    const [ loadWithTime, setLoadWithTime ] = useState({
        from: "",
        to: ""
    });
    const [ toDeleteImage, setToDeleteImage ] = useState([]);
    const [ selectImages, setSelectImages ] = useState(0);
    const [ cameraPopUp, setCameraPopUp ] = useState(false);
    const [ cameraKey, setCameraKey ] = useState(null);
    const [ userId, setUserId ] = useState(null);
    const [ simpleModalOpen, setSimpleModalOpen ] = useState(false);
    const [ downloadingModalOpen, setDownloadingModalOpen ] = useState(false);
    const [ imagesToDownload, setImagesToDownload ] = useState([]);
    const [ email, setEmail ] = useState(null);
    const [ startAndEndDate, setStartAndEndDate ] = useState([]);
    const [ archiving, setArchiving ] = useState(null);
    const [ downloadStatus, setDownloadStatus ] = useState(null);
    const fetchArchive = useRef();
    const onDeleteImageFunc = useRef();
    const fetchImage = useRef();
    const { allowedRange } = DateRangePicker;

    const toDelete = async () => {
        const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
        setEmail(currentAuthenticatedUser.attributes.email);
        const role = currentAuthenticatedUser.signInUserSession.accessToken.payload[ "cognito:groups" ][ 0 ];
        if (role.toString().toLowerCase() === "admin") setDelete(true);
        await API.graphql(graphqlOperation(listUsers, {
            filter: {
                username: {
                    eq: currentAuthenticatedUser.username
                }
            },
            limit: 10000,
        })).then(({ data: {
            listUsers: {
                items
            }
        } }) => setUserId(items[ 0 ].id)
        );
    };

    // To clean all filter values when cam get changed
    const cleanUpFilterState = () => {
        setLoadWithDate({
            from: "",
            to: ""
        });
        setLoadWithTime({
            from: "",
            to: ""
        }); 
        setFromDate("");
        setToDate("");
        setFromTime("");
        setToTime("");
    };

    useEffect(() => {
        cleanUpFilterState();
    }, [ camFilter ]);

    useEffect(() => {
        toDelete();
        onDeleteImageFunc.current = API.graphql(graphqlOperation(onDeleteImage)).subscribe({
            next: ({ value: {
                data: {
                    onDeleteImage
                }
            } }) => setCameras(previousCameras => previousCameras.filter((camera) => {
                if (camera.id !== onDeleteImage.id) return camera;
                return false;
            }))
        });
        return () => {
            onDeleteImageFunc.current.unsubscribe();
        };
    }, [ onDeleteImageFunc ]);

    const fetchArchiving = async () => {
        if (userId) {
            try {
                const userData = await API.graphql(graphqlOperation(getUser, { id: userId }));
                const data = userData.data.getUser.archiving;
                setArchiving(data);
                setDownloadStatus(userData.data.getUser.archiveStatus);
            } catch (err) {
                setLoader(false);
            }
        }
    };

    fetchArchive.current = fetchArchiving;
    const changeIndex = (index) => {
        setCameraKey(cameras[ index ]);
    };
    useEffect(() => {
        fetchArchive.current();
        const intervalForCheckingStatus = setInterval(() => {
            fetchArchive.current();
        }, 60000);
        return () => clearInterval(intervalForCheckingStatus);
    }, [ userId ]);

    let fetchedImages = [];
    var nextToken = null;
    let projectId = match.params.id;

    const fetchImages = async (loadMore, loadMoreImages, loadRemainingImages) => {
        try {
            if (camFilter) {
                let cameraData;
                if (!loadMore) {
                    setLoader(true);
                    if (dateFilter === "By Period") {
                        if (onlyWorkingDay) {
                            if (!loadWithDate.to && !loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                if (startAndEndDate[ 1 ] !== "end_date") {
                                    cameraData = await API.graphql(graphqlOperation(getCamera, {
                                        id: camFilter, limit: 36,
                                        datetime: {
                                            between: [ startAndEndDate[ 0 ], startAndEndDate[ 1 ] ],
                                        },
                                        filter: {
                                            workday: {
                                                eq: onlyWorkingDay
                                            },
                                        },
                                        sortDirection: "DESC"
                                    }));
                                } else {
                                    cameraData = await API.graphql(graphqlOperation(getCamera, {
                                        id: camFilter, limit: 36,
                                        datetime: {
                                            between: [ startAndEndDate[ 0 ], moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]") ]
                                        },
                                        filter: {
                                            workday: {
                                                eq: onlyWorkingDay
                                            },
                                        },
                                        sortDirection: "DESC"
                                    }));
                                }

                            } else if (loadWithDate.to && loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ loadWithDate.from, loadWithDate.to ]
                                    },
                                    filter: {
                                        workday: {
                                            eq: onlyWorkingDay
                                        },
                                    },
                                    sortDirection: "DESC"
                                }));
                            } else if (!loadWithDate.to && !loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ startAndEndDate[ 0 ], startAndEndDate[ 1 ] === "end_date" ? moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]") : startAndEndDate[ 1 ] ]
                                    },
                                    filter: {
                                        workday: {
                                            eq: onlyWorkingDay
                                        },
                                        time: {
                                            between: [ loadWithTime.from, loadWithTime.to ]
                                        }
                                    },
                                    sortDirection: "DESC"
                                }));
                            } else if (loadWithDate.to && loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ loadWithDate.from, loadWithDate.to ]
                                    },
                                    filter: {
                                        workday: {
                                            eq: onlyWorkingDay
                                        },
                                        time: {
                                            between: [ loadWithTime.from, loadWithTime.to ]
                                        }
                                    },
                                    sortDirection: "DESC"
                                }));
                            }
                        } else {
                            if (!loadWithDate.to && !loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                if (startAndEndDate[ 1 ] !== "end_date") {
                                    cameraData = await API.graphql(graphqlOperation(getCamera, {
                                        id: camFilter, limit: 36,
                                        datetime: {
                                            between: [ startAndEndDate[ 0 ], startAndEndDate[ 1 ] ],
                                        },
                                        sortDirection: "DESC",
                                    }));
                                } else {
                                    cameraData = await API.graphql(graphqlOperation(getCamera, {
                                        id: camFilter, limit: 36,
                                        datetime: {
                                            between: [ startAndEndDate[ 0 ], moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]") ]
                                        },
                                        sortDirection: "DESC",
                                    }));
                                }
                            } else if (loadWithDate.to && loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ loadWithDate.from, loadWithDate.to ]
                                    },
                                    sortDirection: "DESC"
                                }));
                            } else if (!loadWithDate.to && !loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ startAndEndDate[ 0 ], startAndEndDate[ 1 ] === "end_date" ? moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]") : startAndEndDate[ 1 ] ]
                                    },
                                    filter: {
                                        time: {
                                            between: [ loadWithTime.from, loadWithTime.to ]
                                        }
                                    },
                                    sortDirection: "DESC"
                                }));
                            } else if (loadWithDate.to && loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ loadWithDate.from, loadWithDate.to ]
                                    },
                                    filter: {
                                        time: {
                                            between: [ loadWithTime.from, loadWithTime.to ]
                                        },
                                    },
                                    sortDirection: "DESC"
                                }));
                            }
                        }
                    } else {
                        if (loadWithDate.to && loadWithDate.from) {
                            cameraData = await API.graphql(graphqlOperation(getCamera, {
                                id: camFilter, limit: 36,
                                datetime: {
                                    between: [ loadWithDate.from, loadWithDate.to ]
                                },
                                sortDirection: "DESC"
                            }));
                        } else {
                            cameraData = await API.graphql(graphqlOperation(getCamera, {
                                id: camFilter,
                                limit: 36,
                                sortDirection: "DESC"
                            }));
                        }
                    }
                    const allImages = cameraData?.data?.getCamera?.imagesByDate;
                    setStatus(cameraData?.data?.getCamera?.status);
                    fetchedImages.push(...allImages.items);
                    let newRemainingImages = [];
                    if ((fetchedImages.length + remainingImagesState.length) >= 36) {
                        fetchedImages.push(...remainingImagesState);
                        let allCameras = fetchedImages.map((image, index) => {
                            if (index <= 35) {
                                let time = image.time;
                                let newTime = time.split(":")[ 0 ].concat(".", time.split(":")[ 1 ]);
                                let date = image.date;
                                let newDate = date.split("Z")[ 0 ].replaceAll("-", ".");
                                let reverseDate = newDate.split(".").reverse().join(".");
                                let dateTime = reverseDate.concat(" | ", newTime);
                                if (image.s3ObjectPathThumbnail) {
                                    return {
                                        camera: image.s3ObjectPathThumbnail,
                                        cameraBluredPath: image.s3ObjectPathBlurringPro ? image.s3ObjectPathBlurringPro : image.s3ObjectPathBlurred,
                                        cameraOriginalPath: image.s3ObjectPathOriginal,
                                        camName: image.cameraID,
                                        dateTime: dateTime,
                                        id: image.id,
                                        datetime: image.datetime,
                                        fetchedImages: fetchedImages,
                                    };
                                } else {
                                    return;
                                }

                            } else {
                                return newRemainingImages.push(image);
                            }
                        });
                        setRemainingImagesState(newRemainingImages);
                        setCameras(allCameras);
                        setError("");
                        nextToken = allImages?.nextToken;
                        setToken(nextToken);
                        fetchedImages = [];
                        setLoader(false);
                    } else {
                        nextToken = allImages?.nextToken;
                        setToken(nextToken);
                        if (nextToken) {
                            fetchImages(true);
                        } else {
                            fetchImages(true, false, true);
                        };
                    };
                } else if (loadRemainingImages) {
                    let allCameras = [];
                    if (loadMoreImages) {
                        allCameras = [ ...cameras ];
                    }
                    cameraData = await API.graphql(graphqlOperation(getCamera, {
                        id: camFilter, limit: 36,
                        sortDirection: "DESC"
                    }));
                    fetchedImages.push(...remainingImagesState);
                    let newRemainingImages = [];
                    fetchedImages.forEach((image, index) => {
                        if (index <= 35) {
                            let time = image.time;
                            let newTime = time.split(":")[ 0 ].concat(".", time.split(":")[ 1 ]);
                            let date = image.date;
                            let newDate = date.split("Z")[ 0 ].replaceAll("-", ".");
                            let reverseDate = newDate.split(".").reverse().join(".");
                            let dateTime = reverseDate.concat(" | ", newTime);
                            if (image.s3ObjectPathThumbnail) {
                                allCameras.push({
                                    camera: image.s3ObjectPathThumbnail,
                                    cameraBluredPath: image.s3ObjectPathBlurringPro ? image.s3ObjectPathBlurringPro : image.s3ObjectPathBlurred,
                                    cameraOriginalPath: image.s3ObjectPathOriginal,
                                    camName: image.cameraID,
                                    dateTime: dateTime,
                                    id: image.id,
                                    datetime: image.datetime,
                                    fetchedImages: fetchedImages,
                                });
                            } else {
                                return;
                            }

                        } else newRemainingImages.push(image);
                    });
                    setRemainingImagesState(newRemainingImages);
                    setCameras(allCameras);
                    setError("");
                    setLoader(false);
                    return fetchedImages = [];
                } else if (loadMore && nextToken) {
                    if (dateFilter === "By Period") {
                        if (onlyWorkingDay) {
                            if (!loadWithDate.to && !loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    filter: {
                                        workday: {
                                            eq: onlyWorkingDay
                                        },
                                    },
                                    sortDirection: "DESC",
                                    nextToken: nextToken
                                }));
                            } else if (loadWithDate.to && loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ loadWithDate.from, loadWithDate.to ]
                                    },
                                    filter: {
                                        workday: {
                                            eq: onlyWorkingDay
                                        },
                                    },
                                    sortDirection: "DESC",
                                    nextToken: nextToken
                                }));
                            } else if (!loadWithDate.to && !loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ startAndEndDate[ 0 ], startAndEndDate[ 1 ] === "end_date" ? moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]") : startAndEndDate[ 1 ] ]
                                    },
                                    filter: {
                                        workday: {
                                            eq: onlyWorkingDay
                                        },
                                        time: {
                                            between: [ loadWithTime.from, loadWithTime.to ]
                                        }
                                    },
                                    sortDirection: "DESC",
                                    nextToken: nextToken
                                }));
                            } else if (loadWithDate.to && loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ loadWithDate.from, loadWithDate.to ]
                                    },
                                    filter: {
                                        workday: {
                                            eq: onlyWorkingDay
                                        },
                                        time: {
                                            between: [ loadWithTime.from, loadWithTime.to ]
                                        }
                                    },
                                    sortDirection: "DESC",
                                    nextToken: nextToken
                                }));
                            }
                        } else {
                            if (!loadWithDate.to && !loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter,
                                    limit: 36,
                                    nextToken
                                }));
                            } else if (loadWithDate.to && loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ loadWithDate.from, loadWithDate.to ]
                                    },
                                    sortDirection: "DESC",
                                    nextToken: nextToken
                                }));
                            } else if (!loadWithDate.to && !loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ startAndEndDate[ 0 ], startAndEndDate[ 1 ] === "end_date" ? moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]") : startAndEndDate[ 1 ] ]
                                    },
                                    filter: {
                                        time: {
                                            between: [ loadWithTime.from, loadWithTime.to ]
                                        }
                                    },
                                    sortDirection: "DESC",
                                    nextToken: nextToken
                                }));
                            } else if (loadWithDate.to && loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ loadWithDate.from, loadWithDate.to ]
                                    },
                                    filter: {
                                        time: {
                                            between: [ loadWithTime.from, loadWithTime.to ]
                                        }
                                    },
                                    sortDirection: "DESC",
                                    nextToken: nextToken
                                }));
                            }
                        }
                    } else if (loadWithDate.to && loadWithDate.from) {
                        cameraData = await API.graphql(graphqlOperation(getCamera, {
                            id: camFilter, limit: 36,
                            datetime: {
                                between: [ loadWithDate.from, loadWithDate.to ]
                            },
                            sortDirection: "DESC",
                            nextToken: nextToken
                        }));
                    } else {
                        cameraData = await API.graphql(graphqlOperation(getCamera, {
                            id: camFilter, limit: 36,
                            sortDirection: "DESC",
                            nextToken: nextToken,
                        }));
                    }
                    const allImages = cameraData?.data?.getCamera?.imagesByDate;
                    setStatus(cameraData?.data?.getCamera?.status);
                    let newRemainingImages = [];
                    fetchedImages.push(...allImages.items);
                    if ((fetchedImages.length + remainingImagesState.length) >= 36) {
                        fetchedImages.push(...remainingImagesState);
                        let allCameras = [];
                        if (loadMoreImages) {
                            allCameras.push(...cameras);
                        }
                        fetchedImages.forEach((image, index) => {
                            if (index <= 35) {
                                let time = image.time;
                                let newTime = time.split(":")[ 0 ].concat(".", time.split(":")[ 1 ]);
                                let date = image.date;
                                let newDate = date.split("Z")[ 0 ].replaceAll("-", ".");
                                let reverseDate = newDate.split(".").reverse().join(".");
                                let dateTime = reverseDate.concat(" | ", newTime);
                                if (image.s3ObjectPathThumbnail) {
                                    allCameras.push({
                                        camera: image.s3ObjectPathThumbnail,
                                        cameraBluredPath: image.s3ObjectPathBlurringPro ? image.s3ObjectPathBlurringPro : image.s3ObjectPathBlurred,
                                        cameraOriginalPath: image.s3ObjectPathOriginal,
                                        camName: image.cameraID,
                                        dateTime: dateTime,
                                        id: image.id,
                                        datetime: image.datetime,
                                        fetchedImages: fetchedImages,
                                    });
                                } else {
                                    return;
                                }

                            } else newRemainingImages.push(image);
                        });
                        nextToken = allImages?.nextToken;
                        setRemainingImagesState(newRemainingImages);
                        setCameras(allCameras);
                        setError("");
                        setToken(nextToken);
                        fetchedImages = [];
                        setLoader(false);
                    } else {
                        nextToken = allImages?.nextToken;
                        setToken(nextToken);
                        if (nextToken) {
                            if (loadMoreImages) {
                                fetchImages(true, true);
                            } else fetchImages(true);
                        } else {
                            if (loadMoreImages) {
                                fetchImages(true, true, true);
                            } else fetchImages(true, false, true);
                        }
                    }
                } else if (loadMoreImages && token) {
                    if (dateFilter === "By Period") {
                        if (onlyWorkingDay) {
                            if (!loadWithDate.to && !loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                if (startAndEndDate[ 1 ] !== "end_date") {
                                    cameraData = await API.graphql(graphqlOperation(getCamera, {
                                        id: camFilter, limit: 36,
                                        datetime: {
                                            between: [ startAndEndDate[ 0 ], startAndEndDate[ 1 ] ],
                                        },
                                        filter: {
                                            workday: {
                                                eq: onlyWorkingDay
                                            },
                                        },
                                        sortDirection: "DESC",
                                        nextToken: token
                                    }));
                                } else {
                                    cameraData = await API.graphql(graphqlOperation(getCamera, {
                                        id: camFilter, limit: 36,
                                        datetime: {
                                            between: [ startAndEndDate[ 0 ], moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]") ]
                                        },
                                        filter: {
                                            workday: {
                                                eq: onlyWorkingDay
                                            },
                                        },
                                        sortDirection: "DESC",
                                        nextToken: token
                                    }));
                                }
                            } else if (loadWithDate.to && loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ loadWithDate.from, loadWithDate.to ]
                                    },
                                    filter: {
                                        workday: {
                                            eq: onlyWorkingDay
                                        },
                                    },
                                    sortDirection: "DESC",
                                    nextToken: token
                                }));
                            } else if (!loadWithDate.to && !loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ startAndEndDate[ 0 ], startAndEndDate[ 1 ] === "end_date" ? moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]") : startAndEndDate[ 1 ] ]
                                    },
                                    filter: {
                                        workday: {
                                            eq: onlyWorkingDay
                                        },
                                        time: {
                                            between: [ loadWithTime.from, loadWithTime.to ]
                                        }
                                    },
                                    sortDirection: "DESC",
                                    nextToken: token
                                }));
                            } else if (loadWithDate.to && loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ loadWithDate.from, loadWithDate.to ]
                                    },
                                    filter: {
                                        workday: {
                                            eq: onlyWorkingDay
                                        },
                                        time: {
                                            between: [ loadWithTime.from, loadWithTime.to ]
                                        }
                                    },
                                    sortDirection: "DESC",
                                    nextToken: token
                                }));
                            }
                        } else {
                            if (!loadWithDate.to && !loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                if (startAndEndDate[ 1 ] !== "end_date") {
                                    cameraData = await API.graphql(graphqlOperation(getCamera, {
                                        id: camFilter, limit: 36,
                                        datetime: {
                                            between: [ startAndEndDate[ 0 ], startAndEndDate[ 1 ] ],
                                        },
                                        sortDirection: "DESC",
                                        nextToken: token
                                    }));
                                } else {
                                    cameraData = await API.graphql(graphqlOperation(getCamera, {
                                        id: camFilter, limit: 36,
                                        datetime: {
                                            between: [ startAndEndDate[ 0 ], moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]") ]
                                        },
                                        sortDirection: "DESC",
                                        nextToken: token
                                    }));
                                }
                            } else if (loadWithDate.to && loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ loadWithDate.from, loadWithDate.to ]
                                    },
                                    sortDirection: "DESC",
                                    nextToken: token
                                }));
                            } else if (!loadWithDate.to && !loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ startAndEndDate[ 0 ], startAndEndDate[ 1 ] === "end_date" ? moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]") : startAndEndDate[ 1 ] ]
                                    },
                                    filter: {
                                        time: {
                                            between: [ loadWithTime.from, loadWithTime.to ]
                                        }
                                    },
                                    sortDirection: "DESC",
                                    nextToken: token
                                }));
                            } else if (loadWithDate.to && loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                cameraData = await API.graphql(graphqlOperation(getCamera, {
                                    id: camFilter, limit: 36,
                                    datetime: {
                                        between: [ loadWithDate.from, loadWithDate.to ]
                                    },
                                    filter: {
                                        time: {
                                            between: [ loadWithTime.from, loadWithTime.to ]
                                        }
                                    },
                                    sortDirection: "DESC",
                                    nextToken: token
                                }));
                            }
                        }
                    } else if (loadWithDate.from && loadWithDate.to) {
                        cameraData = await API.graphql(graphqlOperation(getCamera, {
                            id: camFilter, limit: 36,
                            datetime: {
                                between: [ loadWithDate.from, loadWithDate.to ]
                            },
                            sortDirection: "DESC",
                            nextToken: token
                        }));
                    } else {
                        cameraData = await API.graphql(graphqlOperation(getCamera, {
                            id: camFilter, limit: 36,
                            nextToken: token,
                            sortDirection: "DESC"
                        }));
                    }
                    const allImages = cameraData?.data?.getCamera?.imagesByDate;
                    setStatus(cameraData?.data?.getCamera?.status);
                    fetchedImages.push(...allImages.items);
                    let newRemainingImages = [];
                    if ((fetchedImages.length + remainingImagesState.length) >= 36) {
                        fetchedImages.push(...remainingImagesState);
                        let allCameras = [ ...cameras ];
                        fetchedImages.forEach((image, index) => {
                            if (index <= 35) {
                                let time = image.time;
                                let newTime = time.split(":")[ 0 ].concat(".", time.split(":")[ 1 ]);
                                let date = image.date;
                                let newDate = date.split("Z")[ 0 ].replaceAll("-", ".");
                                let reverseDate = newDate.split(".").reverse().join(".");
                                let dateTime = reverseDate.concat(" | ", newTime);
                                if (image.s3ObjectPathThumbnail) {
                                    allCameras.push({
                                        camera: image.s3ObjectPathThumbnail,
                                        cameraBluredPath: image.s3ObjectPathBlurringPro ? image.s3ObjectPathBlurringPro : image.s3ObjectPathBlurred,
                                        cameraOriginalPath: image.s3ObjectPathOriginal,
                                        camName: image.cameraID,
                                        dateTime: dateTime,
                                        id: image.id,
                                        datetime: image.datetime,
                                        fetchedImages: fetchedImages,
                                    });
                                } else {
                                    return;
                                }

                            } else newRemainingImages.push(image);
                        });
                        setCameras(allCameras);
                        setRemainingImagesState(newRemainingImages);
                        setError("");
                        nextToken = allImages?.nextToken;
                        setLoader(false);
                        setToken(nextToken);
                        fetchedImages = [];
                    } else {
                        nextToken = allImages?.nextToken;
                        setToken(nextToken);
                        if (nextToken) {
                            fetchImages(true, true);
                        } else {
                            fetchImages(true, true, true);
                        }
                    }
                }
            }
        } catch (err) {
            setLoader(false);
        };
    };
    fetchImage.current = fetchImages;

    useEffect(() => {
        fetchImage.current();
    }, [
        startAndEndDate,
        loadWithDate.to,
        loadWithDate.from,
        loadWithTime.to,
        loadWithTime.from,
        onlyWorkingDay
    ]);

    const onHide = () => {
        setOpen({
            show: false,
            name: "", selected: ""
        });
        setError("");
        setSimpleModalOpen(false);
        setDownloadingModalOpen(false);
        fetchArchiving();
    };

    const deleteFunc = () => {
        if (Delete) {
            if (open.selected === "single") {
                setDeleteLoader(true);
                Storage.remove(open.name).then(() => {
                    API.graphql(graphqlOperation(deleteImage, { input: { id: open.cameraId } })).then(() => {
                        onHide();
                        setDeleteLoader(false);
                    }).catch(({ errors }) => {
                        setError(errors[ 0 ].message);
                    });
                }).catch((err) => {
                    throw (err);
                });
            } else if (selectImages) {
                if (selectImages <= 50) {
                    setDeleteLoader(true);
                    return cameras?.filter((selected) => {
                        if (selected?.selected) {
                            return Storage.remove(selected.camera).then(() => {
                                API.graphql(graphqlOperation(deleteImage, { input: { id: selected.id } })).then(() => {
                                    setSelectImages(selectImages - 1);
                                    if (!selectImages - 1) {
                                        onHide();
                                        setDeleteLoader(false);
                                    }
                                }).catch(({ errors }) => {
                                    setError(errors[ 0 ].message);
                                    setDeleteLoader(false);
                                });
                            }).catch((err) => {
                                throw (err);
                                setDeleteLoader(false);
                            });
                        }
                        return "";
                    });
                } else {
                    setError(translate("only-50-images-delete"));
                }
            } else {
                setDeleteLoader(true);
                API.graphql(graphqlOperation(updateProject, { input: { id: projectId, deleteAllImages: camFilter } })).then(() => {
                    setOpen({
                        show: false,
                        name: "", selected: ""
                    });
                    setDeleteLoader(false);
                    setCameras([]);
                }).catch(({ errors }) => {
                    setError(errors[ 0 ].message);
                    setDeleteLoader(false);
                });
            }
        } else {
            setError(translate("no-delete-image-permission"));
        }
    };

    const downloadFunc = async () => {
        if (!archiving) {
            try {
                setDeleteLoader(true);
                if (imagesToDownload?.length) {
                    let imagesKeys = imagesToDownload?.map((ind) =>
                        cameras[ ind ].cameraBluredPath ? cameras[ ind ].cameraBluredPath : cameras[ ind ].cameraOriginalPath);
                    await API.graphql(graphqlOperation(updateUser, {
                        input: {
                            imagesKeys,
                            id: userId,
                            filteredImages: false,
                            allImages: false,
                            archiving: true,
                            archiveStatus: "requested",

                        }
                    }));
                } else if (!imagesToDownload?.length) {
                    if (dateFilter === "By Period") {
                        if (onlyWorkingDay) {
                            if (!loadWithDate.to && !loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                await API.graphql(graphqlOperation(updateUser, {
                                    input: {
                                        id: userId,
                                        allImages: false,
                                        filteredImages: true,
                                        imagesKeys: [ cameras[ 1 ].camera ],
                                        workday: true,
                                        archiving: true,
                                        archiveStatus: "requested",
                                    }
                                }));
                            } else if (loadWithDate.to && loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                await API.graphql(graphqlOperation(updateUser, {
                                    input: {
                                        id: userId,
                                        allImages: false,
                                        filteredImages: true,
                                        imagesKeys: [ cameras[ 1 ].camera ],
                                        workday: true,
                                        dateRange: [ loadWithDate.from, loadWithDate.to ],
                                        archiving: true,
                                        archiveStatus: "requested",
                                    }
                                }));
                            } else if (!loadWithDate.to && !loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                await API.graphql(graphqlOperation(updateUser, {
                                    input: {
                                        id: userId,
                                        allImages: false,
                                        filteredImages: true,
                                        imagesKeys: [ cameras[ 1 ].camera ],
                                        workday: true,
                                        timeRange: [ loadWithTime.from, loadWithTime.to ],
                                        archiving: true,
                                        archiveStatus: "requested",
                                    }
                                }));
                            } else if (loadWithDate.to && loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                await API.graphql(graphqlOperation(updateUser, {
                                    input: {
                                        id: userId,
                                        allImages: false,
                                        filteredImages: true,
                                        imagesKeys: [ cameras[ 1 ].camera ],
                                        workday: true,
                                        timeRange: [ loadWithTime.from, loadWithTime.to ],
                                        dateRange: [ loadWithDate.from, loadWithDate.to ],
                                        archiving: true,
                                        archiveStatus: "requested",
                                    }
                                }));
                            }
                        } else {
                            if (!loadWithDate.to && !loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                await API.graphql(graphqlOperation(updateUser, {
                                    input: {
                                        id: userId,
                                        allImages: true,
                                        filteredImages: false,
                                        imagesKeys: [ cameras[ 1 ].camera ],
                                        workday: false,
                                        archiving: true,
                                        archiveStatus: "requested",
                                    }
                                }));
                            } else if (loadWithDate.to && loadWithDate.from && !loadWithTime.to && !loadWithTime.from) {
                                await API.graphql(graphqlOperation(updateUser, {
                                    input: {
                                        id: userId,
                                        allImages: false,
                                        filteredImages: true,
                                        imagesKeys: [ cameras[ 1 ].camera ],
                                        dateRange: [ loadWithDate.from, loadWithDate.to ],
                                        workday: false,
                                        archiving: true,
                                        archiveStatus: "requested",
                                    }
                                }));

                            } else if (!loadWithDate.to && !loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                await API.graphql(graphqlOperation(updateUser, {
                                    input: {
                                        id: userId,
                                        allImages: false,
                                        filteredImages: true,
                                        imagesKeys: [ cameras[ 1 ].camera ],
                                        timeRange: [ loadWithTime.from, loadWithTime.to ],
                                        workday: false,
                                        archiving: true,
                                        archiveStatus: "requested",
                                    }
                                }));
                            } else if (loadWithDate.to && loadWithDate.from && loadWithTime.to && loadWithTime.from) {
                                await API.graphql(graphqlOperation(updateUser, {
                                    input: {
                                        id: userId,
                                        allImages: false,
                                        filteredImages: true,
                                        imagesKeys: [ cameras[ 1 ].camera ],
                                        timeRange: [ loadWithTime.from, loadWithTime.to ],
                                        dateRange: [ loadWithDate.from, loadWithDate.to ],
                                        workday: false,
                                        archiving: true,
                                        archiveStatus: "requested",
                                    }
                                }));
                            }
                        }
                    } else if (loadWithDate.from && loadWithDate.to) {
                        await API.graphql(graphqlOperation(updateUser, {
                            input: {
                                id: userId,
                                allImages: false,
                                filteredImages: true,
                                imagesKeys: [ cameras[ 1 ].camera ],
                                dateRange: [ loadWithDate.from, loadWithDate.to ],
                                timeRange: [ "00:00:00.0000Z", "23:59:59.0000Z" ],
                                archiving: true,
                                archiveStatus: "requested",
                            }
                        }));
                    } else {
                        await API.graphql(graphqlOperation(updateUser, {
                            input: {
                                id: userId,
                                allImages: true,
                                filteredImages: false,
                                imagesKeys: [ cameras[ 1 ].camera ],
                                archiving: true,
                                archiveStatus: "requested",
                            }
                        }));
                    }
                }
                setDeleteLoader(false);
                setOpen({
                    show: false,
                    name: "", selected: ""
                });
                setSimpleModalOpen(true);
                setError("");
            } catch (err) {
                setDeleteLoader(false);
            }
        } else {
            setDownloadingModalOpen(true);
        }

    };
    const selectItemFunc = (index) => {
        const selectImage = [ ...cameras ];
        if (selectImage[ index ][ "selected" ]) {
            selectImage[ index ][ "selected" ] = false;
            setCameras(selectImage);
            setSelectImages(selectImages - 1);
            const update = imagesToDownload.filter((val) => val !== index);
            setImagesToDownload([ ...update ]);
            setToDeleteImage([ ...update ]);

        } else {
            selectImage[ index ][ "selected" ] = true;
            setCameras(selectImage);
            setSelectImages(selectImages + 1);
            setImagesToDownload(previousData => [ ...previousData, index ]);
            setToDeleteImage(previousData => [ ...previousData, index ]);
        }
    };

    const allCameras = cameras.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));

    const retrieveRole = async () => {
        const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
        setRole(
            currentAuthenticatedUser.signInUserSession.accessToken.payload[
                "cognito:groups"
            ][ 0 ]
        );

    };

    useEffect(() => {
        retrieveRole();
    }, []);


    return (
        <div className="Archive">
            <ArchiveHead
                camera={(val) => {
                    setCamTitle(val.label);
                    setCamFilter(val.value);
                    setStartAndEndDate(val.startAndEndDate);
                }}
                history={history}
                downloadStatus={downloadStatus}
                action={(val) => {
                    setSelectedAction(val);
                    setOpen({ show: true, name: "", selected: "" });
                    fetchArchiving();
                }}
                Delete={Delete}
                match={match}
                hasTimeRange={
                    role === "Admin" || role === "Crew"
                        ? true
                        : (fromDate && toDate) || selectImages || (dateFilter === "By Date" && fromDate)
                            ? true
                            : false
                }
            />

            <div className="Archive__mobile-filter-sec">
                <div className="Archive__dropdown Archive__dropdown-mobile">
                    <SelectDropdown
                        options={[ { value: "By Period", label: translate("by-period") }, { value: "By Date", label: translate("by-date") }, ]}
                        defaultValue={{ value: "By Period", label: translate("by-period") }}
                        onChange={(dateFilter) => {
                            setLoadWithDate({ from: "", to: "" });
                            setDateFilter(dateFilter.value);
                            setLoadWithTime({ from: "", to: "" });
                            setToDate();
                            setFromDate();
                            setRemainingImagesState([]);
                            fetchImages();
                        }}
                    />
                    <button className="Archive__reset-btn" onClick={() => window.location.reload()}>
                        <IoMdRefresh color="#1181F2" size={28} />
                    </button>
                </div>

                <span className="Archive__mobile-filter-sec-span">
                    {dateFilter === "By Date" ? (
                        <DateRangePicker
                            oneTap
                            showOneCalendar
                            cleanable={false}
                            placeholder="Select date"
                            style={{ width: 230 }}
                            disabledDate={
                                allowedRange(
                                    moment(new Date(startAndEndDate[ 0 ])).format("YYYY-MM-DD"),
                                    startAndEndDate[ 1 ] !== "end_date" ? moment(new Date(startAndEndDate[ 1 ])).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"))
                            }
                            onChange={(value) => {
                                setFromDate(value[ 0 ]);
                                setLoadWithDate({
                                    from: moment(value[ 0 ]).format("YYYY-MM-DD").concat("T00:00Z"),
                                    to: moment(value[ 1 ]).format("YYYY-MM-DD").concat("T23:59Z")
                                });
                                setRemainingImagesState([]);
                            }}
                        />
                    ) : ""}

                    <IoMdRefresh className="ML-10" color="#1181F2" size={28} onClick={() => window.location.reload()} />
                </span>
            </div>
            {dateFilter === "By Period" ? <hr className="By-period-line" /> : ""}

            <div className="Archive__navbar">
                <div className="Archive__navbar--left">
                    <span className="Archive__all-camera-btn">
                        <GoPrimitiveDot color={
                            status === "active"
                                ? " #00B607"
                                : status === "inactive"
                                    ? "#C5C5C5"
                                    : status === "failure" ? "#D05E5E" : ""
                        } size={20} /> {camTitle}
                    </span>
                    <div className="Archive__dropdown">
                        <label className="mb-0 mr-2">Filter: </label>
                        <SelectDropdown
                            options={[ { value: "By Period", label: translate("by-period") }, { value: "By Date", label: translate("by-date") }, ]}
                            defaultValue={{
                                value: "By Period",
                                label: translate("by-period"),
                            }}
                            onChange={(dateFilter) => {
                                setLoadWithDate({ from: "", to: "" });
                                setDateFilter(dateFilter.value);
                                setLoadWithTime({ from: "", to: "" });
                                setToDate();
                                setFromDate();
                                setRemainingImagesState([]);
                                fetchImages();
                            }}
                        />
                    </div>
                    <div className="Archive__navbar--right">
                        {dateFilter === "By Period" ? (
                            <>
                                <span md={6} className="Archive__navbar--date-time-main">
                                    <label>
                                        {translate("date-range")}:
                                    </label>
                                    <DateRangePicker
                                        showOneCalendar
                                        appearance="default"
                                        placeholder="Select dates"
                                        style={{ width: 230 }}
                                        cleanable={true}
                                        ranges={[]}
                                        disabledDate={
                                            allowedRange(
                                                moment(new Date(startAndEndDate[ 0 ])).format("YYYY-MM-DD"),
                                                startAndEndDate[ 1 ] !== "end_date" ? moment(new Date(startAndEndDate[ 1 ])).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"))
                                        }
                                        onChange={(value) => {
                                            setFromDate(value[ 0 ]);
                                            setToDate(value[ 1 ]);
                                            setLoadWithDate({
                                                ...loadWithDate,
                                                from: moment(value[ 0 ]).format("YYYY-MM-DD").concat("T00:00Z"),
                                                to: moment(value[ 1 ]).format("YYYY-MM-DD").concat("T23:59Z")
                                            });
                                            setRemainingImagesState([]);
                                        }}
                                        value={[ fromDate, toDate ]}
                                    />
                                </span>
                                <span className="Archive__navbar--date-time-main">
                                    <label>{translate("from")}:</label>
                                    <DatePicker
                                        format="HH:mm"
                                        cleanable={false}
                                        ranges={[]}
                                        placement="bottomStart"
                                        style={{ width: 140 }}
                                        onChange={(value) => {
                                            setFromTime(value);
                                            setRemainingImagesState([]);
                                            setLoadWithTime({
                                                ...loadWithTime,
                                                from: moment(value).format("HH:mm:ss.sss").concat("Z"),
                                            });
                                        }}
                                        value={fromTime}
                                    />
                                </span>
                                <span className="Archive__navbar--date-time-main">
                                    <label>{translate("to")}:</label>
                                    <DatePicker
                                        format="HH:mm"
                                        style={{ width: 140 }}
                                        cleanable={false}
                                        ranges={[]}
                                        placement={mobileView ? "bottomStart" : "bottomEnd"}
                                        onChange={(value) => {
                                            setToTime(value);
                                            setRemainingImagesState([]);
                                            setLoadWithTime({
                                                ...loadWithTime,
                                                to: moment(value).format("HH:mm:ss.sss").concat("Z"),
                                            });
                                        }}
                                        value={toTime}
                                    />
                                    <button className="Archive__reset-btn" onClick={() => window.location.reload()}>
                                        <IoMdRefresh color="#1181F2" size={28} />
                                    </button>
                                </span>
                            </>
                        ) : (
                            <div className="Archive__navbar--by-date">
                                <label style={{ paddingRight: "5px" }}>{translate("select-date")}:</label>
                                <DateRangePicker
                                    oneTap
                                    showOneCalendar
                                    cleanable={false}
                                    placeholder="Select date"
                                    style={{ width: 230 }}
                                    disabledDate={
                                        allowedRange(
                                            moment(new Date(startAndEndDate[ 0 ])).format("YYYY-MM-DD"),
                                            startAndEndDate[ 1 ] !== "end_date" ? moment(new Date(startAndEndDate[ 1 ])).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"))
                                    }
                                    onChange={(value) => {
                                        setFromDate(value[ 0 ]);
                                        setLoadWithDate({
                                            from: moment(value[ 0 ]).format("YYYY-MM-DD").concat("T00:00Z"),
                                            to: moment(value[ 1 ]).format("YYYY-MM-DD").concat("T23:59Z")
                                        });
                                        setRemainingImagesState([]);
                                    }}
                                />
                                <button className="Archive__reset-btn" onClick={() => window.location.reload()}>
                                    <IoMdRefresh color="#1181F2" size={28} />
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                {dateFilter === "By Period" ? (
                    <div className="Archive__navbar--weekdays-main-div">
                        <div className="weekdays">
                            <Checkbox
                                onChange={(e => {
                                    setOnlyWorkingDay(e.target.checked);
                                    setRemainingImagesState([]);
                                })}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 24, color: "#1181f2" } }}
                            />
                            <p>{translate("exclude-weekend")}</p>
                        </div>

                    </div>
                ) : ""}
            </div>

            <div className="Archive__main">
                <div className="Archive-main-for-invidual-camera">
                    {loader ? (
                        <Loader />
                    ) : (
                        <Row>
                            {allCameras.length ?
                                cameras.map((value, index) => {
                                    if (value?.camera){
                                        return (
                                            <Col className='Archive__item' key={index} sm={12} md={6} lg={4}>
                                                <div className="Archive__tile" style={{ border: value?.selected ? "2px solid #007bff" : 0 }}>
                                                    <span>
                                                        <ArchiveImage
                                                            cameraKey={value.camera}
                                                            onClick={() => { setCameraPopUp(true); setCameraKey(value); setSelectedAction("Download"); }}
                                                        />
                                                        <span className="Archive__date-time" onClick={() => selectItemFunc(index)}>
                                                            <div
                                                                className="Archive__date-time-col"
                                                                md={3}
                                                                sm={12}
                                                            >
                                                                <FaRegCalendarAlt size={16} />
                                                                <p>{value.dateTime}</p>
                                                            </div>
                                                        </span>
                                                    </span>
                                                    {Delete ? (
                                                        <span
                                                            className="Archive__tile-delete-col"
                                                            onClick={() => { setOpen({ show: true, name: value.id, selected: "single", cameraId: value.id }); setSelectedAction("Delete"); }}
                                                        >
                                                            <FaTimes color="#1181f2"/>
                                                        </span>
                                                    ) : ""}
                                                </div>
                                            </Col>
                                        );
                                    } else return "";
                                }) : <div className='Archive__no-Camera'>
                                    {translate("no-archive-image")}
                                </div>}
                        </Row>
                    )}
                </div>
            </div>
            <DeleteModal
                onHide={onHide}
                open={open}
                deleteFunc={selectedAction === "Download" ? downloadFunc : deleteFunc}
                error={error}
                loader={deleteLoader}
                name={selectedAction === "Download" ?
                    (imagesToDownload?.length ? translate("download-multiple-images") :
                        loadWithDate.to || loadWithDate.from || loadWithTime.to || loadWithTime.from ? translate("download-filtered-images") : translate("download-all-images")) :
                    (toDeleteImage?.length ? translate("delete-multiple-images") :
                        translate("delete-all-images"))
                }
                canDelete={true}
            />

            <ArchiveImagePopup
                match={match}
                open={cameraPopUp}
                change={changeIndex}
                onHide={() => setCameraPopUp(false)}
                cameraKey={cameraKey}
                allCameras={cameras}
                loadMoreFunc={
                    () => {
                        if (token) {
                            fetchImages(true, true);
                        } else fetchImages(true, true, true);
                    }
                }
            />
            <SimpleModal open={simpleModalOpen} onHide={onHide} email={email} />
            <DownloadingPopup open={downloadingModalOpen} onHide={onHide} />

            {((cameras.length && (token || remainingImagesState.length) && !loader)) ? <div
                className="Archive__loadmore"
                onClick={() => {
                    if (token) {
                        fetchImages(true, true);
                    } else fetchImages(true, true, true);
                }}
            >
                {translate("load-more-images")}
                <MdArrowDropDown color="#1181f2" size={22} />
            </div> : ""}
        </div>
    );
};

Archive.propTypes = {
    history: PropTypes.any,
    match: PropTypes.any,
};

export default Archive;
