import React, { useState } from "react";
import { forgotPassword } from "../service/AuthService";
import Card from "./Card";
import { TextInput } from "./TextInput";
import { ButtonPrimary } from "./ButtonsPrimary";
import { Image, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import "../styles/components/ForgotPassword.scss";
import translate from "../i18n/translate";
import { I18nProvider, LOCALES } from "../i18n";

const ForgotPassword = ({ setToRender }) => {
    const [ state, setState ] = useState({
        username: "",
        error: "",
        loader: false,
        usernameSubmit: false,
    });

    const onChange = (e) => setState({ ...state, [ e.target.name ]: e.target.value, error: false });

    const usernameSubmit = (e) => {
        e.preventDefault();
        const { username } = state;
        setState({ loader: true });
        forgotPassword(username)
            .then((res) => {
                setState({ ...state, loader: false, usernameSubmit: true });
            })
            .catch((err) => {
                setState({ ...state, error: err.message, loader: false });
            });
    };

    const { username, loader, error } = state;
    let [ locale ] = useState(LOCALES.EN);
    const language = localStorage.getItem("language");
    if (language === "EN") {
        locale = LOCALES.EN;
    }
    if (language === "DE") {
        locale = LOCALES.DE;
    }
    if (language === "NL") {
        locale = LOCALES.NL;
    }

    return (
        <I18nProvider locale={locale}>
            <div className="ForgotPassword-container">
                <Col md={7} sm={12} xs={12} lg={5}>
                    <Card>
                        <Image
                            src={require("../assets/images/logos/BAUCAMERA.png")}
                            alt="baucamera-logo"
                            className="ForgotPassword-container__baucamera-logo"
                        />
                        {!state.usernameSubmit ? (
                            <>
                                <h3 className="ForgotPassword-container__title">{translate("forgot-password")}</h3>

                                <form onSubmit={usernameSubmit} className="ForgotPassword-container__login-form">
                                    <TextInput
                                        label={translate("username")}
                                        value={username}
                                        placeholder=""
                                        type="text"
                                        formText={translate("back-to-login")}
                                        onChange={onChange}
                                        name="username"
                                        newComponent={() => setToRender()}
                                    />
                                    <ButtonPrimary title={translate("submit")} type="submit" loader={loader} />
                                </form>
                            </>
                        ) : (
                            <>
                                <h2 className="ForgotPassword-container__title-success">{translate("check-email")}</h2>
                                <h4 onClick={() => setToRender()} className="ForgotPassword-container__back-to-login">
                                    {translate("back-to-login")}
                                </h4>
                            </>
                        )}
                        <span className="ForgotPassword-container__error">{error && error}</span>
                    </Card>
                </Col>
            </div>
        </I18nProvider>
     
    );
};

ForgotPassword.propTypes = {
    setToRender: PropTypes.func,
};

export default ForgotPassword;