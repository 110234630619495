import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import '../styles/components/pagination.scss';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

const Pagination = ({ itemPerPage, totalItems, paginate, currentPage }) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ReactPaginate
                pageCount={pageNumbers.length}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                forcePage={currentPage}
                onPageChange={paginate}
                previousLabel={<FaChevronLeft color="#1181F2" size={12} className="pagination__btn"/>}
                nextLabel={<FaChevronRight color="#1181F2" size={12} className="pagination__btn"/>}
                activeClassName="pagination-active"
                activeLinkClassName="pagination-active"
                pageClassName="pagination__number"
                pageLinkClassName=""
                breakClassName="pagination__number"
                breakLinkClassName=""
            />
        </div>
    );
};

Pagination.propTypes = {
    itemPerPage: PropTypes.number,
    totalItems: PropTypes.number,
    paginate: PropTypes.func,
    currentPage: PropTypes.number
};

export default Pagination;