import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { API, graphqlOperation } from "aws-amplify";
import { listWeatherDatas } from "../helpers/manualQueries";
import translate from "../i18n/translate";

const WeatherData = ({ weatherKey, imageDateTime }) => {
    const [ weatherDatas, setWeatherDatas ] = useState(null);
    const mobileView = useMediaQuery("(max-width:550px)");

    function generateWeatherFilter(weather_key, imageDateTime) {
    /**
    This is the filter generator function that would need two attributes:
    @param {weather_key} weather_key value is available on project data and info and should be proxies/passed to
    this API
    @param {imageDateTime} imageDateTime value belongs to the image object we are showing and is defined per image,
    this helps us to show the weather data for the specific time the image was taken
    */
        const localDateTime = new Date(imageDateTime);
        // We have to subtract 2 hours because the timezone of the images are not right and are in CET where as they're
        // being sent as UTC
        localDateTime.setHours(localDateTime.getHours() - 2);
        const dateObject = new Date(localDateTime);
        // Calculate nearest hour in UTC
        const minutes = dateObject.getUTCMinutes();
        if (minutes >= 30) {
            dateObject.setUTCHours(dateObject.getUTCHours() + 1);
        }
        // Set minutes, seconds, and milliseconds to zero to round to the hour
        dateObject.setUTCMinutes(0);
        dateObject.setUTCSeconds(0);
        dateObject.setUTCMilliseconds(0);

        // Extract the YYYY-MM-DD part from the ISO string
        const isoDate = dateObject.toISOString();
        const formattedDate = isoDate.split("T")[ 0 ];
        // Compute numeric timestamp (in seconds since epoch)
        const utc_timestamp = Math.floor(dateObject.getTime() / 1000);

        // Combine the weather_key with the date
        const location_date = `${weather_key}_${formattedDate}`;
        return {
            filter: {
                location_date: { eq: location_date },
                utc_timestamp: { eq: utc_timestamp }
            }
        };
    }

    useEffect(() => {
        if (weatherKey && imageDateTime) {
            API.graphql(
                graphqlOperation(listWeatherDatas, generateWeatherFilter(weatherKey, imageDateTime))
            ).then((res) => setWeatherDatas(res?.data?.listWeatherDatas?.items[ 0 ]));
        }
            
    }, [ weatherKey, imageDateTime ]);

    return (
        <>
            {weatherDatas?.icon_id &&  
                <div
                    className="weather-data"
                    style={{ 
                        alignItems: "baseline",
                        display: "flex", 
                        justifyContent: "space-between", 
                        width: "100%",
                        fontSize: mobileView ? "8px" : "12px",
                        padding: "8px"
                    }}
                >
                    <div>
                        <img 
                            src={require(`../assets/icons/weather_icons/${weatherDatas.icon_id}.png`)} alt="" 
                            height="26px"
                        />
                        {translate(weatherDatas.summary)}
                    </div>
                    <div><div><b>{translate("temperature")}:</b></div> {weatherDatas.temperature}</div>
                    <div><div><b>{translate("wind-speed")}:</b></div> {weatherDatas.wind}</div>
                    {
                        weatherDatas.precipitation && <div>
                            <div>
                                <b>{translate("precipitation")}:</b>
                            </div>
                            {translate(weatherDatas.precipitation.split("-")[ 0 ].trim())}
                            {weatherDatas.precipitation.split("-").pop()}
                        </div>
                    }
                    <div><div><b>{translate("cloud-cover")}:</b></div> {weatherDatas.cloud_cover.replace("%", "")}%</div>

                </div>}
        </>
       
    );
};

export default WeatherData;

WeatherData.propTypes = {
    weatherKey: PropTypes.string,
    imageDateTime: PropTypes.string,
};