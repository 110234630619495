import { LOCALES } from "../locales";

export default {
    [ LOCALES.NL ]: {
        "help": "Ondersteuning",
        "docs": "Hulp",
        "no-project": "Geen Project",
        "go-back": "Teruggaan",
        "all-projects": "Alle projecten",
        "archive": "Archief",
        "archiving-date": "Archivering datum",
        "add": "+ Toevoegen",
        "copy-url": "Kopieer URL",
        "create": "+ Toevoegen",
        "timelapse": "Time-lapse",
        "timelapse-enabled": "Time-lapse ingeschakeld",
        "external-camera": "Externe camera",
        "delete": "Verwijderen",
        "download": "Download",
        "edit": "Bewerken",
        "edit-project": "Project bewerken",
        "edit-user": "User bewerken",
        "end": "Afronden",
        "new-camera": "+ Nieuwe Cam",
        "new-project": "+ Nieuwe Project",
        "no": "Nee",
        "reload": "Herladen",
        "Reset-temporary-password": "Reset tijdelijk wachtwoord",
        "save": "Opslaan",
        "off": "Off",
        "on": "On",
        "yes": "Ja",
        "company": "Bedrijf: ",
        "no-signal": "Geen signaal",
        "not-working": "Buiten dienst",
        "on-pause": "Gepauzeerd",
        "all-cameras": "Alle cam’s",
        "live-stream-enabled": "Live-stream ingeschakeld",
        "all-cams": "Alle cam’s",
        "all-images": "Alle beelden",
        "by-date": "Per datum",
        "by-period": "Per periode",
        "project": "project",
        "by-company": "Per Bedrijf",
        "company": "Bedrijf",
        "from": "Van",
        "notification": "Meldingen Cam Offline",
        "disclaimer": " Wanneer de gebruiker niet is toegewezen aan een project, verdwijnt de gebruiker automatisch uit uw gebruikerslijst.",
        "select": "Selecteer",
        "select-camera-view": "Selecteer Cam: ",
        "project-is-deleted": "Het project is verwijderd of is beschadigd.",
        "pending-deletion": "De verwijdering van het project is in behandeling.",
        "select-date": "Selecteer datum",
        "selected-images": "Geselecteerde afbeeldingen",
        "select-cam": "Cam selecteer",
        "select-company": "Bedrijf selecteer",
        "to": "Tot",
        "monday-friday": "Maandag tot Vrijdag",
        "monday-sunday": "Maandag tot Zondag",
        "add-user": "+ User toevoegen",
        "add-user2": "User toevoegen",
        "confirm-password": "Bevestig wachtwoord",
        "first-name": "Voornaam",
        "surname": "achternaam",
        "forgot-password": "WACHTWOORD VERGETEN?",
        "last-name": "Naam",
        "login": "Aanmelden",
        "login-in-service": "Inloggen",
        "password": "Wachtwoord",
        "sign-up": "Registratie",
        "wrong-password": "Wachtwoorden komen niet overeen",
        "user-role": "User rol",
        "username": "User naam",
        "edit-profile": "Profiel bewerken",
        "language": "Taal",
        "log-out": "Uitloggen",
        "companies": "Bedrijf",
        "historical-archive": "Historisch archief",
        "log-file": "Logbestand",
        "cams": "Cams",
        "projects": "Projecten",
        "users": "Users",
        "number-of-cameras": "Aantal Cams",
        "number-of-images": "Aantal beelden",
        "number-of-projects": "Aantal projecten",
        "assign-to-company": "Toewijzen aan een bedrijf",
        "camera-name": "Cam naam",
        "choose-cameras": "Selecteer Cam",
        "comment": "Commentaar",
        "company-name": "Bedrijfsnaam",
        "gps-location": "gps-locatie",
        "latitude": "Breedtegraad",
        "location": "Locatie",
        "longitude": "Lengtegraad",
        "add-company": "+ Bedrijf toevoegen",
        "day": "DAG",
        "week": "WEEK",
        "month": "MAAND",
        "add-name": "Naam toevoegen",
        "create-new-camera": "Creëer nieuwe Cam",
        "create-new-project": "Creëer een nieuw project",
        "delete-ask": "Zal worden verwijderd:",
        "edit-camera": "Cam wijzigen",
        "edit-company": "Bedrijf wijzigen",
        "project-name": "Projectnaam",
        "storage-size": "Grootte",
        "telephone": "Telefoon",
        "view-by": "Weergave",
        "client-all": "Klant (alle)",
        "client-cam-only": "Klant (alleen Cam’s)",
        "day-dropdown": "Dag",
        "week-dropdown": "Week",
        "user-logs": "Gebruikers",
        "camera-logs": "Camera",
        "project-logs": "Projecten",
        "month-dropdown": "Maand",
        "filter-by": "Filteren op: ",
        "crew": "Crew",
        "role": "Rol",
        "active": "Actief",
        "enabled": "Actief",
        "inactive": "Inactief",
        "disabled": "Inactief",
        "failure": "Mislukking",
        "latest-picture-link": "Laatste foto-link",
        "copy-picture-link": "Kopieer Picture Link",
        "regenerate-credentials": "Geloofsbrieven herscheppen",
        "name": "Naam",
        "status": "Status",
        "blurring": "Blurring",
        "email-address": "E-mailadres",
        "English": "Engels",
        "Dutch": "Nederlands",
        "German": "Duitse",
        "admin": "Admin",
        "filter-by-name": "Filter per naam",
        "filter-by-company": "Filter per Bedrijf",
        "filter-by-project": "Filter per project",
        "add-customer": "Bedrijf toevoegen",
        "load-more-images": "Laad meer afbeeldingen",
        "website": "Website",
        "no-camera-project": "Geen camera gekoppeld aan dit project",
        "cannot-delete-company": "heeft een aantal projecten en kan niet worden verwijderd",
        "no-archive-image": "Geen foto ́s beschikbaar",
        "end-project": "Wil je dit project beëindigen?",
        "download-multiple-images": "Wilt u geselecteerde afbeeldingen downloaden?",
        "download-filtered-images": "Wilt u deze afbeeldingen downloaden?",
        "download-all-images": "Wilt u alle afbeeldingen downloaden?",
        "delete-multiple-images": "Wilt u deze afbeeldingen verwijderen?",
        "delete-all-images": "Wilt u alle afbeeldingen verwijderen?",
        "no-delete-image-permission": "U heeft geen toestemming om afbeeldingen te verwijderen",
        "only-50-images-delete": "U kunt maximaal 50 afbeeldingen per keer verwijderen",
        "send-download-link": "Uw downloadlink wordt binnenkort naar de e-mail {email} gestuurd",
        "email": "E-mail",
        "wait-for-next-download": "Je vorige download is nog niet voltooid. Afhankelijk van de hoeveelheid gegevens kan het tot 90 minuten duren om een downloadlink te verstrekken.  Zodra je de downloadlink per e-mail hebt ontvangen, kun je een nieuwe download starten.",
        "no-projects": "Geen projecten beschikbaar",
        "no-cameras": "Geen camera's beschikbaar",
        "no-users": "Er zijn momenteel geen gebruikers",
        "no-historical-archive": "Er zijn geen historische archieven",
        "no-companies": "Er zijn momenteel geen bedrijven",
        "blurring-enabled": "Blurring ingeschakeld",
        "blurring-pro-enabled": "Blurring pro ingeschakeld",
        "reset-password": "Wachtwoord opnieuw instellen",
        "back-to-login": "Terug naar Inloggen",
        "confirm-new-password": "Je hebt met succes je wachtwoord veranderd",
        "check-email": "Controleer uw e-mail",
        "submit": "Zwichten",
        "new-password": "Nieuw paswoord",
        "change-password": "Wachtwoord wijzigen",
        "super-user": "Super user",
        "success": "Succesvol",
        "error": "Fout",
        "successfully-copied-image-link": "Afbeeldingslink succesvol gekopieerd naar klembord",
        "no-image-availabe": "Er zijn geen afbeeldingen beschikbaar!",
        "time-lapse-not-ready": "Time-lapse not available",
        "time-lapse-not-created": "Request time-lapse",
        "time-lapse-not-requested": "Time-lapse is aangevraagd",
        "time-lapse-missing": "Time-lapse ontbreekt",
        "time-lapse-in-progress": "Er wordt gewerkt aan een time-lapse",
        "time-lapse-available": "Time-lapse is available",
        "time-lapse-outdated": "Time-lapse is achterhaald",
        "to-project-start": "projectstart",
        "to-latest-image": "naar de laatste afbeelding",
        "cleint-all-description": "heeft toegang tot het archief van de camera van een toegewezen project",
        "client-cam-description": "heeft geen toegang tot het archief van de camera van een toegewezen project",
        "note-user": "Als een user niet wordt toegewezen aan een project, verdwijnt hij automatisch uit je gebruikerslijst.",
        "date-range": "datumbereik",
        "exclude-weekend": "weekend uitsluiten",
        "note": "Note",
        "temperature": "Temperatuur",
        "wind-speed": "Windsnelheid",
        "cloud-cover": "Bewolking",
        "precipitation": "Neerslag",
        "test-mode": "Test mode",
        "no-last-view-projects": "Er zijn momenteel geen laatst bekeken projecten.",
        "last-viewed-projects": "Recent projecten",
        "Sunny": "Zonnig",
        "Mostly sunny": "Overwegend zonnig",
        "Partly sunny": "Teilweise sonnig",
        "Intermittent clouds": "Afwisselend bewolkt",
        "Hazy sunshine": "Nevelige zonneschijn",
        "Mostly cloudy": "Meestal bewolkt",
        "Cloudy": "Bewolkt",
        "Dreary (overcast)": "Somber (bewolkt)",
        "Fog": "Mist",
        "Showers": "Buien",
        "Mostly cloudy w/ Showers": "Meestal bewolkt met buien",
        "Partly sunny w/ Showers": "Gedeeltelijk zonnig met buien",
        "T-Storms": "Onweersbuien",
        "Mostly cloudy w/ T-Storms": "Meestal bewolkt met onweersbuien",
        "Partly sunny w/ T-Storms": "Gedeeltelijk zonnig met onweersbuien",
        "Rain": "Regen",
        "Flurries": "Sneeuwbuien",
        "Mostly cloudy w/ Flurries": "Meestal bewolkt met sneeuwbuien",
        "Partly sunny w/ Flurries": "Gedeeltelijk zonnig met sneeuwbuien",
        "Snow": "Sneeuw",
        "Mostly cloudy w/ Snow": "Meestal bewolkt met sneeuw",
        "Ice": "IJs",
        "Sleet": "Natte sneeuw",
        "Freezing rain": "Ijzel",
        "Rain and snow": "Regen en sneeuw",
        "Hot": "Heet",
        "Cold": "Koud",
        "Windy": "Winderig",
        "Clear": "Helder",
        "Mostly clear": "Overwegend helder",
        "Partly cloudy": "Gedeeltelijk bewolkt",
        "Hazy moonlight": "Nevelig maanlicht",
        "Partly cloudy w/ Showers": "Gedeeltelijk bewolkt met buien",
        "Partly cloudy w/ T-Storms": "Gedeeltelijk bewolkt met onweersbuien",
        "Light rain": "Lichte regen",
        "Drizzle": "Motregenen",
        "Dense fog": "Dichte mist",
        "Light rain shower": "Lichte regenbui",
        "Some clouds": "Enkele wolken",
        "Light snow": "Lichte sneeuw",
        "ready-to-download": "Llaar om te downloaden",
        "requested-download": "Downloaden aangevraagd",
        "download-in-progress": "Het downloaden is bezig",
        "download-failed": "Download mislukt",
        "delete-selected-archive": "Verwijder geselecteerde archieven",
        "delete-selected-company": "Geselecteerd bedrijf verwijderen",
        "user-guide": "User Guid",
    }
};