import React, { Fragment, useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./theme/global";
import { lightTheme, darkTheme } from "./theme/theme";
import awsconfig from "./custom-aws-exports";
import { API, graphqlOperation } from "aws-amplify";
import { getCloudStatus } from "./graphql/queries";
import Cams from "./pages/Cams/Cams";
import Projects from "./pages/Projects/Projects";
import SingleProject from "./pages/Projects/SingleProject";
import Companies from "./pages/Companies/Companies";
import LogFile from "./pages/LogFile/LogFile";
import Users from "./pages/Users/Users";
import HistoricalArchive from "./pages/HistoricalArchive/HistoricalArchive";
import Header from "./components/Header";
import LoginResetPassword from "./components/LoginResetPassword";
import Archive from "./pages/Archive/Archive";
import PageNotFound from "./pages/NotFound";
import "./styles/main.scss";
import { I18nProvider, LOCALES } from "./i18n";
import OutOfService from "./pages/OutOfService";
import Home from "./pages/Home/Home";
import UserManual from "./pages/UserManual/UserManual";


awsconfig.Auth = {
    authenticationFlowType: "CUSTOM_AUTH"
};
Amplify.configure(awsconfig);

function App() {
    const [ Role, setRole ] = useState("");
    const [ hideNavBar, setHideNavbar ] = useState(null);
    let [ locale ] = useState(LOCALES.EN);
    const [ language, setLanguage ] = useState(localStorage.getItem("language"));
    const [ theme, setTheme ] = useState("light");
    const [ isCloudOffLine, setIsCloudOffLine ] = useState(null);


    const getRole = async () => {
        const role = (await Auth.currentAuthenticatedUser()).signInUserSession
            .accessToken.payload[ "cognito:groups" ][ 0 ];
        setRole(role.toString().toLowerCase());
    };

    useEffect(() => {
        getRole();
        API.graphql(graphqlOperation(getCloudStatus, { id: "CloudStatusId" })).then(({ data }) => setIsCloudOffLine(data.getCloudStatus.displayStatus));
    }, []);

    useEffect(() => {
        const localTheme = window.localStorage.getItem("theme");
        setTheme(localTheme);
    }, [ theme ]);


    useEffect(() => {
        setLanguage(localStorage.getItem("language"));
    }, [ localStorage.getItem("language") ]);

    if (language === "EN") {
        locale = LOCALES.EN;
    }
    if (language === "DE") {
        locale = LOCALES.DE;
    }
    if (language === "NL") {
        locale = LOCALES.NL;
    }

    //console.log(localStorage.getItem("language"));
    useState(() => {
     
    }, []);
    

    return (
        <Router>
            <I18nProvider locale={ locale }>
                <ThemeProvider theme={theme === "light" || theme === null ? lightTheme : darkTheme}>
                    <GlobalStyles />
                    <Fragment>
                        <Header 
                            Role={Role} 
                            ShowNavbar={val => setHideNavbar(val)} 
                            selectedLanguage={val => setLanguage(val)} 
                            selectedTheme = {val => setTheme(val)}
                        />
                      
                        
                        <div
                            className="wrapper"
                            style={(!hideNavBar) && (Role === "admin" || Role === "crew" || Role === "superuser") ? { paddingTop: "117px" } : { paddingTop: "62px" }}
                        >
                            {Role ? (
                                Role === "admin" || Role === "crew" ? (
                                    <Switch>

                                        <Route path="/cams" exact component={ Cams } />
                                        <Route path="/help" exact component={UserManual} />
                                        <Route path="/companies" render={() => <Companies isNavbarHidden={hideNavBar} />} />
                                        <Route path="/logfile" render={() => <LogFile isNavbarHidden={hideNavBar} />} />
                                        <Route path="/users" render={(props) => <Users role={Role} isNavbarHidden={hideNavBar} {...props}/>}/>
                                        <Route
                                            path="/historical-archive"
                                            render={(props) => <HistoricalArchive isNavbarHidden={hideNavBar} {...props} />}
                                        />
                                        <Route path="/" exact component={Home} />
                                        <Route path="/projects" exact component={Projects} />
                                        <Route path="/projects/:id" exact component={SingleProject} />
                                        <Route path="/projects/:id/archive/:cameraId" component={Archive} />
                                        <Route path="*" component={PageNotFound} />
                                    </Switch>
                                ) : (
                                    !isCloudOffLine ? (
                                        <Switch>
                                            <Route path="/" exact component={Projects} />
                                            <Route path="/projects" exact component={Projects} />
                                            <Route path="/projects/:id" exact component={SingleProject} />
                                            <Route path="/help" exact component={UserManual} />
                                            {(Role === "clientall" || Role === "superuser") && <Route path="/projects/:id/archive/:cameraId" component={Archive} />}
                                            {Role === "superuser" && <Route path="/users" render={(props) => <Users role={Role} {...props}/>} />}
                                            <Route path="*" component={PageNotFound} />                                     
                                        </Switch> 
                                    ) : (
                                        <Switch>
                                            <Route path="*" component={OutOfService} />   
                                        </Switch>
                                    ) 
                                )
                            ) : (
                                ""
                            )}
                        </div>
                    </Fragment>
                </ThemeProvider>
            </I18nProvider>
        </Router>
    );
}

export default withAuthenticator(App, false, [ <LoginResetPassword key="Auth" />, ]);
