import { LOCALES } from "../locales";

export default {
    [ LOCALES.EN ]: {
        "help": "Support",
        "docs": "Help",
        "no-project": "No Project",
        "go-back": "Go Back",
        "all-projects": "All Projects",
        "archive": "Archive",
        "archiving-date": "Archiving date",
        "add": "+ Add",
        "copy-url": "Copy URL",
        "create": "+ Create",
        "delete": "Delete",
        "download": "Download",
        "edit": "Edit",
        "edit-project": "Edit Project",
        "edit-user": "Edit User",
        "end": "End",
        "new-camera": "+ New Cam",
        "new-project": "+ New Project",
        "no": "No",
        "reload": "Reload",
        "Reset-temporary-password": "Reset temporary password",
        "save": "Save",
        "off": "Off",
        "on": "On",
        "num-of-cams": "Cams: ",
        "company": "Company: ",
        "yes": "Yes",
        "no-signal": "No Signal",
        "external-camera": "External camera",
        "timelapse-enabled": "Time-lapse enabled",
        "timelapse": "Time-lapse",
        "day": "DAY",
        "week": "WEEK",
        "month": "MONTH",
        "day-dropdown": "Day",
        "week-dropdown": "Week",
        "month-dropdown": "Month",
        "view-by": "View by",
        "live-stream-activated": "Live-stream activated",
        "not-working": "Not Working",
        "on-pause": "On Pause",
        "all-cameras": "All Cams",
        "all-cams": "All Cams",
        "project-is-deleted": "The project you are trying to view has been deleted or is corrupted.",
        "pending-deletion": "Project deletion is pending.",
        "all-images": "All Images",
        "disclaimer": " When the user is not assigned to a project, the user will dissapear from your user list automatically.",
        "by-date": "By Date",
        "by-period": "By Period",
        "project": "Project",
        "by-company": "By Company",
        "company": "Company",
        "from": "From",
        "notification": "Notification Cam Offline",
        "select": "Select",
        "select-camera-view": "Select cam: ",
        "select-date": "Select Date",
        "selected-images": "Selected Images",
        "select-cam": "Select cam",
        "select-company": "Select company",
        "to": "To",
        "monday-friday": "Monday to Friday",
        "monday-sunday": "Monday to Sunday",
        "add-user": "+ Add User",
        "add-user2": "Add User",
        "confirm-password": "Confirm Password",
        "first-name": "First name",
        "surname": "Surname",
        "forgot-password": "FORGOT PASSWORD?",
        "last-name": "Last name",
        "login": "Login",
        "login-in-service": "Login",
        "password": "Password",
        "sign-up": "Sign Up",
        "wrong-password": "Password doesn't match",
        "user-role": "User role",
        "username": "Username",
        "edit-profile": "Edit Profile",
        "log-out": "Log Out",
        "language": "Language",
        "companies": "Companies",
        "historical-archive": "Historical Archive",
        "log-file": "Events Log",
        "cams": "Cams",
        "projects": "Projects",
        "users": "Users",
        "number-of-cameras": "Number of Cams",
        "number-of-images": "Number of Images",
        "number-of-projects": "Number of Projects",
        "assign-to-company": "Assign to a company",
        "camera-name": "Cam name",
        "choose-cameras": "Choose cams",
        "comment": "Comment",
        "company-name": "Company name",
        "gps-location": "GPS Location",
        "latitude": "Latitude",
        "location": "Location",
        "longitude": "Longitude",
        "add-company": "+ Add Company",
        "add-name": "Add name",
        "create-new-camera": "Create New Cam",
        "create-new-project": "Create New Project",
        "delete-ask": "You want to delete",
        "edit-camera": "Edit Cam",
        "edit-company": "Edit Company",
        "project-name": "Project name",
        "storage-size": "Size",
        "telephone": "Telephone",
        "client-all": "User",
        "client-cam-only": "Limited user",
        "crew": "Crew",
        "role": "Role",
        "active": "Active",
        "disabled": "Disabled",
        "enabled": "Enabled",
        "inactive": "Inactive",
        "failure": "Failure",
        "latest-picture-link": "Latest Picture Link",
        "copy-picture-link": "Copy Picture Link",
        "regenerate-credentials": "Regenerate Credentials",
        "name": "Name",
        "status": "Status",
        "blurring": "Blurring",
        "email-address": "Email address",
        "English": "English",
        "Dutch": "Dutch",
        "German": "German",
        "admin": "Admin",
        "filter-by-name": "Filter by Name",
        "filter-by-company": "Filter by Company",
        "filter-by-project": "Filter by Project",
        "filter-by": "Filter by: ",
        "add-customer": "Add Company",
        "load-more-images": "Load more images",
        "website": "Website",
        "user-logs": "Users",
        "camera-logs": "Cameras",
        "project-logs": "Projects",
        "no-camera-project": "No camera associated with this project",
        "cannot-delete-company": "has some projects and cannot be deleted",
        "no-archive-image": "No images available",
        "end-project": "Do you want to end this project?",
        "download-multiple-images": "Do you want to download selected images?",
        "download-filtered-images": "Do you want to download these images?",
        "download-all-images": "Do you want to download all images?",
        "delete-multiple-images": "Do you want to delete these images?",
        "delete-all-images": "Do you want to delete all images?",
        "no-delete-image-permission": "You do not have permission to delete images",
        "only-50-images-delete": "You can delete maximum 50 images at a time",
        "send-download-link": "Your download link will be sent to email {email} shortly",
        "email": "E-mail",
        "wait-for-next-download": "Your previous download is not yet complete. Depending on the amount of data, it can take up to 90 minutes to provide a download link.  As soon as you have received your download link by e-mail, you can initiate a new download.",
        "no-projects": "No projects available",
        "no-cameras": "No cameras available",
        "no-users": "There are no users at the moment",
        "no-historical-archive": "There are no historical archive",
        "no-companies": "There are no companies at the moment",
        "blurring-enabled": "Blurring enabled",
        "blurring-pro-enabled": "Blurring pro enabled",
        "reset-password": "Reset password",
        "back-to-login": "Back to login",
        "confirm-new-password": "You have successfully changed your password",
        "check-email": "Please check your email",
        "submit": "Submit",
        "new-password": "New password",
        "change-password": "Change password",
        "super-user": "Super user",
        "success": "Success",
        "error": "Error",
        "successfully-copied-image-link": "Image link copied to clipboard.",
        "no-image-availabe": "No image available!",
        "time-lapse-not-ready": "Time-lapse not available",
        "time-lapse-not-created": "Request time-lapse",
        "time-lapse-not-requested": "Time-lapse is requested",
        "time-lapse-missing": "Time-lapse is missing",
        "time-lapse-in-progress": "Time-lapse is in production",
        "time-lapse-available": "Time-lapse is available",
        "time-lapse-outdated": "request Time-lapse",
        "to-project-start": "To project start",
        "to-latest-image": "To lastest image",
        "cleint-all-description": "does have access to the archive of a camera within an assigned project",
        "client-cam-description": "does not have access to the archive of a camera within an assigned project",
        "note-user": "If a user is not assigned to a project, he will automatically disappear from your user list",
        "date-range": "Date range",
        "exclude-weekend": "Exclude weekend",
        "note": "Note",
        "temperature": "Temperature",
        "wind-speed": "Wind speed",
        "cloud-cover": "Cloud cover",
        "precipitation": "Precipitation",
        "test-mode": "Test mode",
        "no-last-view-projects": "There is no last viewed projects at the moment.",
        "last-viewed-projects": "Recent projects",
        "Sunny": "Sunny",
        "Mostly sunny": "Mostly Sunny",
        "Partly sunny": "Partly Sunny",
        "Intermittent clouds": "Intermittent Clouds",
        "Hazy sunshine": "Hazy Sunshine",
        "Mostly cloudy": "Mostly Cloudy",
        "Cloudy": "Cloudy",
        "Dreary (overcast)": "Dreary (Overcast)",
        "Fog": "Fog",
        "Showers": "Showers",
        "Mostly cloudy w/ Showers": "Mostly Cloudy w/ Showers",
        "Partly sunny w/ Showers": "Partly Sunny w/ Showers",
        "T-Storms": "T-Storms",
        "Mostly cloudy w/ T-Storms": "Mostly Cloudy w/ T-Storms",
        "Partly sunny w/ T-Storms": "Partly Sunny w/ T-Storms",
        "Rain": "Rain",
        "Flurries": "Flurries",
        "Mostly cloudy w/ Flurries": "Mostly Cloudy w/ Flurries",
        "Partly sunny w/ Flurries": "Partly Sunny w/ Flurries",
        "Snow": "Snow",
        "Mostly cloudy w/ Snow": "Mostly Cloudy w/ Snow",
        "Ice": "Ice",
        "Sleet": "Sleet",
        "Freezing rain": "Freezing Rain",
        "Rain and snow": "Rain and Snow",
        "Hot": "Hot",
        "Cold": "Cold",
        "Windy": "Windy",
        "Clear": "Clear",
        "Mostly clear": "Mostly Clear",
        "Partly cloudy": "Partly Cloudy",
        "Hazy moonlight": "Hazy Moonlight",
        "Partly cloudy w/ Showers": "Partly Cloudy w/ Showers",
        "Partly cloudy w/ T-Storms": "Partly Cloudy w/ T-Storms",
        "Light rain": "Light rain",
        "Drizzle": "Drizzle",
        "Dense fog": "Dense fog",
        "Light rain shower": "Light rain shower",
        "Some clouds": "Some clouds",
        "Light snow": "Lisht snow",
        "ready-to-download": "Ready to download",
        "requested-download": "Requested download",
        "download-in-progress": "Download in progress",
        "download-failed": "Download failed",
        "delete-selected-archive": "Delete selected archives",
        "delete-selected-company": "Delete selected company",
        "user-guide": "User Guid",
    }
};