import React from "react";
import helpCamera from "./assets/hilfe_kamera.png";

const CameraManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>Camera</h2>
                Klik op de afbeelding in de projectweergave om de player te openen. Met de player kun je gemakkelijk door de afbeeldingen in het archief bladeren. Dit is handig als je op zoek bent naar specifieke beeldinhoud.  

                <br /><br/>

                <img src={helpCamera} alt="help_project"/>
            
                <br /><br/>

                Klik vanuit de huidige afbeelding naar achteren op de pijl aan de linkerkant van de afbeelding tot de gewenste afbeelding wordt weergegeven. De pijl naar rechts gaat in de tegenovergestelde richting.  


                <h3>Tijdlijn</h3>
                Klik op het blauwe driehoekje onder de afbeelding om de tijdbalk te openen. Hier kun je instellen vanaf welk punt in de tijd je wilt scrollen. 
                De tijdlijn geeft automatisch de laatste maand weer, je kunt ook een andere maand selecteren, je vindt de selectie binnen de tijdlijn links in het maandveld.  

                In de speler kun je de weergegeven afbeelding eenvoudig downloaden door op de knop te klikken.  

                Binnen de tijdlijn kun je naar het begin of einde van het projectarchief springen met de pijlen aan de rechterkant van de speler. 
                <br/>

                <h3>Afbeeldingsgrootte en -resolutie</h3>
                Alle afbeeldingen worden in de cloud weergegeven in een verkleinde voorbeeldgrootte zodat gegevens snel en eenvoudig kunnen worden verwerkt. 
                De originele afbeeldingen worden niet gewijzigd en worden opgeslagen in hun originele grootte. Je ontvangt alle afbeeldingen in hun originele grootte zodra je ze hebt gedownload. 

                <h3>Link naar afbeelding kopiëren</h3>
                Knop "Kopieer afbeeldingslink": De link verwijst naar het laatste beeld zonder toegangsgegevens op te vragen. Hiermee kun je het laatste beeld van de camera insluiten op je homepage of intranet. 
                Deze link is alleen beschikbaar zodra de camera de eerste beelden heeft overgedragen. Deze functie is alleen beschikbaar voor hoofdgebruikers.  
                <br/>

                <h3>Weerinformatie</h3>
                Weerinformatie over de bouwplaats wordt boven de afbeelding weergegeven. Deze informatie is ook te vinden bij de afbeeldingen in het archief.
                <br/>

                <h3>Melding Cam Offline</h3>
                Als deze schakelaar is geactiveerd, wordt u per e-mail op de hoogte gesteld als een BAU.CAMERA niet langer wordt doorgestuurd naar de cloud. Elke gebruiker kan dit individueel instellen. 

                <h3>Link naar afbeelding kopiëren</h3>
                Hoofdgebruikers kunnen hier een link naar de huidige afbeelding kopiëren. Je kunt deze link bijvoorbeeld op je homepage gebruiken. De link toont altijd de huidige afbeelding.
            </div></div>
    );
};

export default CameraManual;