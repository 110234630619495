import React, { useEffect, useState, useRef } from "react";
import { Auth, graphqlOperation, API } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import "../styles/components/header.scss";
import bauCameraLogo from "../assets/images/logos/BAUCAMERA.png";
import bauCameraLogoWhite from "../assets/images/logos/logo-white.png";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { MdArrowDropDown } from "react-icons/md";
import NavBar from "./Navbar";
import MobileNavbar from "./MobileNavbar";
import Proptypes from "prop-types";
import translate from "../i18n/translate";
import { listUsers } from "../helpers/manualQueries";
import { getCloudStatus } from "../helpers/manualQueries";
import { updateUser } from "../graphql/mutations";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Alert from "@mui/material/Alert";
import { ButtonPrimary } from "./ButtonsPrimary";
import { TextInput } from "./TextInput";
import { BiX } from "react-icons/bi";
import { updateCloudStatus } from "../helpers/manualMutations";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import ReactTooltip from "react-tooltip";
import Fab from "@mui/material/Fab";
import { FaQuestion, FaHome } from "react-icons/fa";

const Header = ({ Role, history, ShowNavbar, selectedLanguage, selectedTheme }) => {
    const [ showNavbar, setShowNavbar ] = useState(true);
    const [ marginTop, setTransform ] = useState( "0px" );
    const [ open, setOpen ] = React.useState(true);
    const [ display, setDisplay ] = useState("none");
    const [ btnZindex, setBtnZindex ] = useState("3");
    const [ choicesDisplay, setChoicesDisplay ] = useState("none");
    const [ nameDisplay, setNameDisplay ] = useState("none");
    const [ userID, setUserID ] = useState(null);
    const [ isDarkMode, setIsDarkMode ] = useState(null);
    const helpModalRef = useRef(null);
    const langModalRef = useRef(null);
    const nameRef = useRef(null);
    const [ isCloudOffLine, setIsCloudOffLine ] = useState(null);
    const [ openSettingsModal, setOpenSettingsModal ] = useState(false);
    const [ offlineText, setOfflineText ] = useState( "" );
    const [ bannerText, setBannerText ] = useState( "" );
    const [ bannerStatus, setBannnerStatus ] = useState(null);

    useEffect(() => {
        API.graphql(graphqlOperation(getCloudStatus, { id: "CloudStatusId" }))
            .then( ( { data } ) =>
            {
                setBannnerStatus( data.getCloudStatus.bannerStatus );
                setBannerText( data.getCloudStatus.bannerMessage );
                setIsCloudOffLine(data.getCloudStatus.displayStatus);
                setOfflineText(data.getCloudStatus.displayMessage);
            });
    }, []);

    const toggleShowNavbar = () => {
        setShowNavbar(!showNavbar);
        showNavbar ? setTransform("-55px") : setTransform("0px");
    };

    function handleClickOutside(event) {
        if (helpModalRef.current && !helpModalRef.current.contains(event.target)) {
            setDisplay("none");
            setBtnZindex(3);
        }
        if (langModalRef.current && !langModalRef.current.contains(event.target)) {
            setChoicesDisplay("none");
        }
        if (nameRef.current && !nameRef.current.contains(event.target)) {
            setNameDisplay("none");
        }
    }

    const tempUseEffect = () => {
        document.body.addEventListener("click", handleClickOutside);
        API.graphql(graphqlOperation(listUsers, { filter: { username: { eq: Auth.user.username } }, limit: 10000 }))
            .then(({ data: { listUsers: { items } } }) => {
                items[ 0 ].language === "English" ?
                    localStorage.setItem("language", "EN") :
                    items[ 0 ].language === "German" ?
                        localStorage.setItem("language", "DE") :
                        items[ 0 ].language === "Dutch" ?
                            localStorage.setItem("language", "NL") :
                            selectedLanguage(localStorage.getItem("language"));
                setUserID(items[ 0 ].id);
            });
    };

    let tempUseEffectFunc = useRef();
    tempUseEffectFunc.current = tempUseEffect;

    useEffect(() => {
        tempUseEffectFunc.current();
    }, []);

    const changeLang = (lang) => {
        localStorage.setItem("language", lang);
        selectedLanguage(localStorage.getItem("language"));
        let language;
        lang === "EN" ? language = "English" :
            lang === "DE" ? language = "German" : language = "Dutch";
        API.graphql(graphqlOperation(updateUser, { input: { id: userID, language } }));
    };

    const MaterialUISwitch = styled(Switch)(() => ({
        width: 62,
        height: 34,
        padding: 7,
        "& .MuiSwitch-switchBase": {
            margin: 1,
            padding: 0,
            transform: "translateX(6px)",
            "&.Mui-checked": {
                color: "#fff",
                transform: "translateX(22px)",
                "& .MuiSwitch-thumb:before": {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-moon" viewBox="0 0 16 16"><path fill="${encodeURIComponent(
                        "#fff",
                    )}" d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z"/></svg>')`,
                },
                "& + .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: isDarkMode ? "#8796A5" : "#aab4be",
                },
            },
        },
        "& .MuiSwitch-thumb": {
            backgroundColor: isDarkMode ? "#003892" : "#001e3c",
            width: 32,
            height: 32,
            "&:before": {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    "#fff",
                )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
            },
        },
        "& .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: isDarkMode ? "#8796A5" : "#aab4be",
            borderRadius: 20 / 2,
        },
    }));

    useEffect(() => {
        setIsDarkMode(window.localStorage.getItem("theme") === "dark" ? true : false);
    }, [ isDarkMode ]);

    const toggleTheme = (dark) => {
        setIsDarkMode(dark);
        window.localStorage.setItem("theme", dark ? "dark" : "light");
        selectedTheme(dark ? "dark" : "light");
    };

    const onCloseModal = () => {
        setOpenSettingsModal(false);
    };

    const handleSubmitCloudStatus = (e) => {
        e.preventDefault();
        API.graphql(graphqlOperation(updateCloudStatus,
            { input: { id: "CloudStatusId", displayStatus: isCloudOffLine, displayMessage: offlineText, bannerStatus: bannerStatus,  bannerMessage: bannerText } }
        )).then(() => onCloseModal());
    };

    return (
        <div className={bannerStatus ? "header header-banner" : "header" }>
            { bannerStatus ?
                <div className="banner" style={ { height: "30px", width: "100%", backgroundColor: "red", position: "fixed", zIndex: 3, top: "0", display: "block" } }>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={0}  alignItems="center">
                            <Grid style={{ alignItems: "center", justifyContent: "flex-end" }} justifyContent="flex-end" item xs={1}>

                            </Grid>
                            <Grid item xs={ 12}>
                                <span className="special">{ bannerText }</span>
                                <div className="mobile-banner">
                                    <Collapse in={open}>
                                        <Alert
                                            sx={{ fontSize: "20px" }}
                                            severity="error"
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                        >
                                            { bannerText }
                                        </Alert>
                                    </Collapse>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
                : null }
            <div className="header__upper">
                <Link to={"/"}>
                    <img className="header__upper-logo" src={ isDarkMode ? bauCameraLogoWhite : bauCameraLogo} alt="" />
                </Link>
                <div className="header__upper-menu">
                    <div className="header__upper-language">
                        <FormControlLabel
                            control={<MaterialUISwitch checked={isDarkMode} sx={{ m: 1 }} onChange={(e) => toggleTheme(e.target.checked)}/>}
                        />
                        <div className="help">
                            <span
                                className="help__text"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDisplay("flex");
                                    setBtnZindex("3");
                                }}
                            >
                                {translate("help")} <MdArrowDropDown />
                            </span>
                            <div
                                className="help__modal"
                                style={{ display: display }}
                                ref={helpModalRef}
                            >
                                <div>
                                    <span className="help__text--highlight">{translate("email")}:</span>
                                    <span><a href="mailto:support@baucamera.com">support@baucamera.com</a></span>
                                </div>
                                <div>
                                    <span className="help__text--highlight"> {translate("telephone")}:</span>
                                    <span>+49 511 2833858</span>
                                </div>
                                <div>
                                    <span className="help__text--highlight"> {translate("website")}:</span>
                                    <span><a rel="noopener noreferrer" target="_blank" href="https://bau.camera">bau.camera</a></span>
                                </div>
                               
                            </div>
                        </div>
                        <div className="header__languages">
                            <span
                                onClick={(e) => {e.stopPropagation(); setChoicesDisplay("flex");}}
                                style={{ cursor: "pointer" }}
                            >
                                {localStorage.getItem("language") !== null ? localStorage.getItem("language") : "En"} <MdArrowDropDown />
                            </span>

                            <div
                                className="header__language-choices"
                                style={{ display: choicesDisplay }}
                                onClick={() => setChoicesDisplay("none")}
                                ref={langModalRef}
                            >
                                <button onClick={() => changeLang("EN")}>EN</button>
                                <button onClick={() => changeLang("DE")}>DE</button>
                                <button onClick={() => changeLang("NL")}>NL</button>
                            </div>
                        </div>
                    </div>
                    <div className="header__upper-profile">
                        <span
                            className="header__upper-signout"
                            onClick={() => Auth.signOut().then(() => history.push("/"))}
                        >
                            {translate("log-out")}
                        </span>
                        <span
                            onClick={() => setNameDisplay("block")}
                            ref={nameRef}
                        >
                            <IconButton
                                color="primary"
                                aria-label="clickable profile icon"
                            >
                                <AccountCircleIcon sx={{ fontSize: "38px" }} />
                            </IconButton>
                        </span>
                        <div
                            className="header__upper-name"
                            style={{ display: nameDisplay }}
                        >
                            {Auth.user.username}
                        </div>
                    </div>

                    { (Role === "admin" || Role === "crew")  &&
                    <IconButton
                        color="primary"
                        aria-label="clickable settings cog icon"
                        onClick={() => setOpenSettingsModal(true)}
                    >
                        <SettingsIcon sx={{ fontSize: "38px" }} />
                    </IconButton>}
                </div>
            </div>

            {Role === "admin" || Role === "crew" || Role === "superuser" ? (
                <>
                    <div className="navbar" style={{ marginTop: marginTop }}>
                        <MobileNavbar role={Role} />
                        <NavBar Role={Role} />
                    </div>

                    <button
                        onClick={() => { toggleShowNavbar(); ShowNavbar(showNavbar); }}
                        className="header__extend-btn"
                        style={{ zIndex: btnZindex }}
                    >
                        <span className="header__traiangle">
                            {showNavbar ? (
                                <FaCaretUp color="#1181F2" size={20} />
                            ) : (
                                <FaCaretDown color="#1181F2" size={20} />
                            )}
                        </span>
                    </button>
                </>
            ) : (
                ""
            )}

            <Modal className="header__settings-modal" show={openSettingsModal} onHide={onCloseModal}>
                <Box sx={{ height: "100%", padding: "35px" }}>
                    <BiX
                        color="#1181F2"
                        size={30}
                        onClick={() => onCloseModal()}
                        className="setting-modal-close"
                    />
                    <h1>Settings</h1>
                    <Box sx={{ paddingTop: "35px" }}>
                        <h3>Maintenance mode</h3>
                        <form onSubmit={handleSubmitCloudStatus}>
                            <FormControlLabel
                                checked={isCloudOffLine}
                                onChange={() => setIsCloudOffLine(!isCloudOffLine)}
                                control={
                                    <Switch name="gilad" />
                                }
                                label={isCloudOffLine ? "Maintenance is on" :  "Maintenance is off"}
                            />
                            <TextInput
                                type="text"
                                value={offlineText}
                                onChange={(e) => setOfflineText(e.target.value)}
                                label="Offline text"
                                name="offlineText"
                            />
                            <h3>Cloud Banner</h3>
                            <FormControlLabel
                                checked={bannerStatus}
                                onChange={() => setBannnerStatus(!bannerStatus)}
                                control={
                                    <Switch name="gilad" />
                                }
                                label={bannerStatus ? "Banner is on" :  "Banner is off"}
                            />
                            <TextInput
                                type="text"
                                value={bannerText}
                                onChange={(e) => setBannerText(e.target.value)}
                                label="Banner Message"
                                name="offlineText"
                            />
                            <Box sx={{ position: "absolute", bottom: "24px" }}>
                                <ButtonPrimary title="Save" backgroundColor='#1181f2' type="submit"/>
                            </Box>
                        </form>

                    </Box>

                </Box>
            </Modal>
            
            <div 
                className="sticky-help-button" 
                data-tip={ window.location.pathname === "/help" ? "Home" : "Help"}
            > <a 
                    href={ window.location.pathname === "/help" ? "/" : "/help"} 
                >
                    <Fab size="small" color="primary" aria-label="add">         
                        {window.location.pathname === "/help"  ? <FaHome size={18}/> :   <FaQuestion size={18}/>}                    
                    </Fab> 
                    <ReactTooltip
                        place='top'
                        type='dark'
                        effect='float'
                    />
                </a> 
            </div> 
            
        </div>
    );
};

Header.propTypes = {
    Role: Proptypes.string,
    ShowNavbar: Proptypes.func,
    history: Proptypes.any,
    selectedLanguage: Proptypes.func,
    selectedTheme: Proptypes.func
};

export default withRouter(Header);
