import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";
import { deleteHistoricalArchive, createEvent } from "../../graphql/mutations";
import PropTypes from "prop-types";
import translate from "../../i18n/translate";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaCopy, FaTimes } from "react-icons/fa";
import { IoMdArrowDropup } from "react-icons/io";
import { Col } from "react-bootstrap";
import moment from "moment";
import Loader from "../../components/Loader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Checkbox from "@mui/material/Checkbox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReactTooltip from "react-tooltip";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import { useIntl } from "react-intl";

const HistoricalArchiveTable = ({ isNavbarHidden, currentItem, loader, comlumnHeaderWithSorting, showProject, showProjectFunc, openDeleteHistoricalArchivesFunc, handleSnackbarOpen }) => {
    const [ addClass, setAddClass ] = useState(null);
    const [ selectedHistoricalArchive, setSelectedHistoricalArchive ] = useState([]);
    const intl = useIntl();
    const [ events, setEvents ] = useState({
        log: "",
        user: Auth.user.username,
        expire: "",
        logType: "historicalArchive",
    });

    let now = (Date.now() + 15552000000) / 1000;
    events.expire =  Math.round(now);

    
    useEffect(() => {
        setAddClass((isNavbarHidden ? "navbar-hidden-box-hsa" : "box-historical-archive"));
    }, [ isNavbarHidden ]); 

    const handleSelectHistoricalArchive = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedHistoricalArchive(prevState => [ ...prevState, value ]); 
        } else {
            setSelectedHistoricalArchive(prevState => prevState.filter(item => item !== value));
        }
    };

    const handleDeleteHistoricalArchives = () => {
        try {
            selectedHistoricalArchive.forEach((id) =>  API.graphql(graphqlOperation(deleteHistoricalArchive, {
                input: {
                    id: id
                }
            }))
            );
            try {
                selectedHistoricalArchive.forEach((id) => {
                    events.log = "An archive " + currentItem.find(x => x.id === id).projectName + " has been deleted";
                    const event = { ...events };
                    setEvents({ ...events, event });
                    API.graphql(graphqlOperation(createEvent, { input: event }));
                });
            } catch (err) {
                throw (err);
            }
            setSelectedHistoricalArchive([]);
        } catch (err) {
            throw (err);
        }
    };

    return (
        <div className="historicalArchive__table">
            <div className="historicalArchive__table-head">
                <Col md={2}>
                    <div 
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <div 
                            data-tip={selectedHistoricalArchive.length > 0 ? 
                                intl.formatMessage({ id: "delete-selected-archive" }) 
                                : ""}
                            style={{  minWidth: "30px", height: "30px" }}
                        >
                            {selectedHistoricalArchive.length > 0 &&   
                        <DeleteForeverIcon 
                            sx={{ 
                                fontSize: "28px", 
                                cursor: "pointer",
                                marginRight: "5px",
                                color: "#1181f2"
                            }}
                            onClick={handleDeleteHistoricalArchives}
                        /> } 
                        </div>
                        {comlumnHeaderWithSorting("projectName", "project-name")}
                    </div>
                </Col>
                <Col md={2}>
                    {comlumnHeaderWithSorting("companyName", "company")}
                </Col>
                <Col md={2}>
                    {comlumnHeaderWithSorting("archivingDate", "archiving-date")}
                </Col>
                <Col md={2}>
                    {comlumnHeaderWithSorting("numberOfImages", "number-of-images")}
                </Col>
                <Col md={2}>
                    <div>
                        {translate("storage-size")}
                    </div>
                </Col>
                <Col md={3}></Col>
            </div>
            <div className="delete-historical-archive-mobile">
                {selectedHistoricalArchive.length > 0 && 
                <>
                    <DeleteForeverIcon 
                        sx={{ 
                            fontSize: "28px", 
                            cursor: "pointer",
                            marginRight: "5px",
                            color: "#1181f2"
                        }}
                        onClick={handleDeleteHistoricalArchives}
                    />
                 Delete selected historical archives
                </>
                }
               
            </div>
           
            <div className={addClass}>
                {loader ?
                    <Loader /> :
                    currentItem.length ? (currentItem.map((item, index) => (
                        <div className="historicalArchive__item row" key={item.id}>
                            {showProject.length !== 0 && (
                                !showProject[ index ].state ? (
                                    <>
                                        <div className="historicalArchive__item-main-mobile">
                                            <div className="historicalArchive__item-name-box">
                                                <Checkbox 
                                                    onChange={handleSelectHistoricalArchive}
                                                    value={item.id}
                                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24, color: "#1181f2" } }} 
                                                />
                                                <span className="historicalArchive__item-name">
                                                    {translate("project-name")}
                                                </span>
                                                <span>{item?.projectName}</span>
                                            </div>
                                            <div
                                                className="historicalArchive__info-avatar"
                                                onClick={() => {
                                                    var newShowProject = [ ...showProject ];
                                                    newShowProject[ index ].state = !showProject[ index ].state;
                                                    showProjectFunc(newShowProject);
                                                }}
                                            >
                                                <AiOutlineInfoCircle color="#1181F2" size={23} />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="historicalArchive__item-name-box-show-project">
                                            <div className="historicalArchive__item-name-show-project">
                                                {item?.projectName}
                                            </div>
                                            <div
                                                className="historicalArchive__arrow-up-avatar"
                                                onClick={() => {
                                                    var NewShowProject = [ ...showProject ];
                                                    NewShowProject[ index ].state = !showProject[ index ].state;
                                                    showProjectFunc(NewShowProject);
                                                }}
                                            >
                                                <IoMdArrowDropup color="#1181F2" size={20} />
                                            </div>
                                        </div>
                                        <div className="historicalArchive__project">
                                            <div className="historicalArchive__item-name-box">
                                                <span className="historicalArchive__item-name">
                                                    {translate("customer-name")}
                                                </span>
                                                <span className="historicalArchive__item-name-value">
                                                    {item?.companyName}
                                                </span>
                                                <span className="historicalArchive__item-name">
                                                    {translate("number-of-images")}
                                                </span>
                                                <span className="historicalArchive__item-name-value">
                                                    {item?.numberOfImages}
                                                </span>
                                            </div>
                                            <div className="historicalArchive__item-name-box">
                                                <span className="historicalArchive__item-name">
                                                    {translate("archiving-date")}
                                                </span>
                                                <span className="historicalArchive__item-name-value">
                                                    {moment(item?.archivingDate).format("MMMM DD, YYYY")}
                                                </span>
                                                <span className="historicalArchive__item-name">
                                                    {translate("storage-size")}
                                                </span>
                                                <span className="historicalArchive__item-name-value">
                                                    {item?.storageSpace}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )
                            )}
                            <Col md={2} className="historicalArchive__item-main-desktop">
                                <Checkbox 
                                    onChange={handleSelectHistoricalArchive}
                                    value={item.id}
                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24, color: "#1181f2" } }} 
                                />
                                {item?.projectName}
                            </Col>
                            <Col md={2} className="historicalArchive__item-main-desktop">
                                {item?.companyName}
                            </Col>
                            <Col md={2} className="historicalArchive__item-main-desktop">
                                {moment(item?.archivingDate).format("MMMM DD, YYYY")}
                            </Col>
                            <Col md={2} className="historicalArchive__item-main-desktop">
                                {item?.numberOfImages}
                            </Col>
                            <Col md={1} className="historicalArchive__item-main-desktop">
                                {item?.storageSpace}
                            </Col>

                            <Col
                                md={12}
                                lg={3}
                                sm={12}
                                className="historicalArchive__item-edit"
                            >
                                <span className="historicalArchive__item-edit-span">
                                    <CopyToClipboard
                                        text={item?.shareableLink}
                                    >
                                        <ButtonPrimary 
                                            title={
                                                <>
                                                    <FaCopy
                                                        className="historicalArchive__item-btn-download-icon"
                                                        color="#1181F2"
                                                        size={22}
                                                    />
                                            Link
                                                </>
                                            }
                                            onClick={() => {handleSnackbarOpen(item?.shareableLink);}}
                                        />
                                    </CopyToClipboard>
                                    <ButtonPrimary 
                                        onClick={() =>
                                            openDeleteHistoricalArchivesFunc(item.projectName, item.id)
                                          
                                        }
                                        title={<>
                                            <FaTimes color="#1181F2" size={22} />
                                        &nbsp;{translate("delete")}
                                        </>}
                                    />
                                </span>
                            </Col>
                        </div>
                    ))) : <div
                        style={{ width: "100%" }}
                    >
                        <h2 className="handle-empty-element">
                            {translate("no-historical-archive")}
                        </h2>
                    </div>
                }
            </div>
            <ReactTooltip
                place='top'
                type='dark'
                effect='float'
            />
        </div>
    );
}; export default HistoricalArchiveTable;


HistoricalArchiveTable.propTypes = {
    isNavBarHidden: PropTypes.bool,
    currentItem: PropTypes.any,
    loader: PropTypes.bool,
    comlumnHeaderWithSorting: PropTypes.func,
    showProject: PropTypes.any,
    showProjectFunc: PropTypes.func,
    openDeleteHistoricalArchivesFunc: PropTypes.func,
    handleSnackbarOpen: PropTypes.func,
    isNavbarHidden: PropTypes.bool
};