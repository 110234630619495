import React from "react";
import { Modal } from "react-bootstrap";
import { ButtonPrimary } from "./ButtonsPrimary";
import PropTypes from "prop-types";
import "../styles/components/SimpleModal.scss";
import translate from "../i18n/translate";

const DownloadingPopup = ({ open, onHide }) => {
    return (
        <Modal className="simple-modal" show={open} onHide={onHide} centered>
            <div style={{ textAlign: "center" }}>
                <h3>{translate("wait-for-next-download")}</h3>
                <div className='simple-modal__btn-div'>
                    <ButtonPrimary
                        title="Ok"
                        type="submit"
                        backgroundColor="#fff"
                        color="#000"
                        onClick={onHide}
                    />
                </div>
            </div>
        </Modal>
    );
};

DownloadingPopup.propTypes = {
    open: PropTypes.bool,
    onHide: PropTypes.func,
};


export default DownloadingPopup;
