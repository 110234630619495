import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { IoMdArrowDropright, IoMdArrowDropleft } from "react-icons/io";
import "../styles/pages/Cams/cams-popup.scss";

export const SeekBtns = ({ prevImg, nextImg, imageURIState, camDate, dateTime, i, imgP,  camImages, inverted, isArchive, cameraIDs, Role }) => 
{
    const [ isShowingBackBtn, setIsShowingBackBtn ] = useState(false);
    const [ isShowingForwardBtn, setIsShowingForwardBtn ] = useState(false);
    const [ imageUri, setImageURI ] = useState("");

    useEffect(() => {
        setImageURI(imageURIState);
    }, [ imageURIState ]);

    useEffect(() => {
        if (Role === "clientcamonly") {
            setIsShowingBackBtn(false);
            setIsShowingForwardBtn(false);
        } else if (isArchive === true && i === 0) {
            setIsShowingBackBtn(false);
            setIsShowingForwardBtn(true);
        } else if (isArchive) {
            setIsShowingBackBtn(true);
            setIsShowingForwardBtn(true);
        }
        else if (i === camImages.length) {
            setIsShowingBackBtn(inverted ? !inverted : true);
            setIsShowingForwardBtn(inverted ? inverted : false);
        } else if (dateTime && i != 0) {
            setIsShowingBackBtn(true);
            setIsShowingForwardBtn(true);
        } else if (dateTime) {
            setIsShowingBackBtn(inverted ? inverted : false);
            setIsShowingForwardBtn(inverted ? !inverted : true);
        } else if (camDate === dateTime) {
            setIsShowingBackBtn(inverted ? !inverted : true);
            setIsShowingForwardBtn(inverted ? inverted : false);
        }
    }, [
        i,
        camDate,
        dateTime,
        camImages
    ]);

    return (
        <div className='image-container'>
            {imageUri ? (
                <img
                    src={imageUri}
                    alt='camera'
                    className='camera-popup__camera-img'
                    onError={(e) =>
                        e.target.setAttribute("src", imgP)
                    }
                />
            ) : (
                <img
                    className='default_img'
                    height='100px'
                    src={require("../assets/images/greycam.png")}
                    alt='Dafault Camera'
                />
            )}
            {isShowingBackBtn && cameraIDs ? (
                <button
                    className='btn-left--mod btn-properties'
                    onClick={() => {(inverted ? nextImg() : prevImg());}}
                >
                    <span className='btn-content'>
                        <IoMdArrowDropleft
                            size={40}
                            value={{ color: "white" }}
                        />
                    </span>
                </button>
            ) : null}
            {isShowingForwardBtn && cameraIDs ? (
                <button
                    className='btn-right--mod btn-properties'
                    onClick={() => {(inverted ? prevImg() : nextImg());}}
                >
                    <span className='btn-content'>
                        <IoMdArrowDropright
                            size={40}
                            value={{ color: "white" }}
                        />
                    </span>
                </button>
            ) : null}
        </div>

    );
};

SeekBtns.defaultProps = {
    isArchive: false,
};

SeekBtns.propTypes = {
    prevImg: PropTypes.func,
    nextImg: PropTypes.func,
    imageURIState: PropTypes.string,
    camDate: PropTypes.string,
    dateTime: PropTypes.string,
    i: PropTypes.number,
    imgP: PropTypes.any,
    cameraData: PropTypes.any,
    camImages: PropTypes.array,
    inverted: PropTypes.bool,
    thumbnail: PropTypes.any,
    isArchive: PropTypes.bool,
    cameraIDs: PropTypes.any,
    Role: PropTypes.string,
};