import React, { useEffect, useState, useRef } from "react";
import greyCamera from "../../assets/images/greycam.png";
import greyCameraDark from "../../assets/images/greycam-dark.png";

import PropTypes from "prop-types";
import { Storage } from "aws-amplify";


export const ProjectImage = ({ camera, length, cameraIDs }) => {
    const [ imageURI, setImageURI ] = useState("");
    const cameraDataDepFunc = useRef();
    const image = camera?.imagesByDate?.items[ 0 ];
    const imageDateTime = camera?.imagesByDate?.items[ 0 ]?.datetime;
    const cameraAssignedDate = cameraIDs ? JSON.parse(cameraIDs)[ camera?.id ]?.[ 0 ] : null;
    const [ theme, setTheme ] = useState("light");

    useEffect(() => {
        setTheme(window.localStorage.getItem("theme"));
    }, [ window.localStorage.getItem("theme") ]);


    const cameraDataDep = () => {
        if ( cameraAssignedDate < imageDateTime) {
            if (image?.s3ObjectPathThumbnail === null) {
                const previousImage = camera?.imagesByDate?.items[ 1 ];
                Storage.get(previousImage?.s3ObjectPathThumbnail).then((res) => {
                    setImageURI(res);
                }); 
            } else {
                Storage.get(image?.s3ObjectPathThumbnail).then((res) => {
                    setImageURI(res);
                });
            }

        }
    };

    cameraDataDepFunc.current = cameraDataDep;

    useEffect(() => {
        cameraDataDepFunc.current();
    }, [
        camera,
        cameraIDs,
        length,
    image?.s3ObjectPathThumbnail,
    imageDateTime 
    ]);

    return (
        <div
            key={camera.cameraName}
            className="projects__cameras"
            style={{
                width: `${100 / length}%`,
            }}
        >
            <img
                className="projects__camera-img"
                src={
                    imageURI
                        ? imageURI
                        : (theme === "dark" ? 
                            require("../../assets/images/greycam-dark.png") : 
                            require("../../assets/images/greycam.png"))
                }
                alt={camera.camName}
                onError={(e) => {
                    if (theme === "dark") {
                        e.target.setAttribute("src", greyCameraDark);
                    } else {
                        e.target.setAttribute("src", greyCamera);
                    }
                }}
            />
            <div className="projects__camera-name">
                {camera.cameraName}
            </div>
        </div>
    );
};

ProjectImage.propTypes = {
    project: PropTypes.any,
    cameraIDs: PropTypes.string,
    camera: PropTypes.any,
    length: PropTypes.number,
};
