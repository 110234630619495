import React from "react";
import helpProject from "./assets/hilfe_projekt.png";

const ProjectManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>Project</h2>

                <p>
                Hier zie je alle camera's in het geselecteerde project. De datum en tijd van het laatst overgebrachte beeld wordt rechts boven het beeld weergegeven.
                </p>

                <img src={helpProject} alt="help_project"/>

                <h3>Navigatie</h3>
                <p>
                Klik op de afbeelding om de speler te openen. Hier kun je door alle beelden in het archief bladeren. De knoppen 'Archief', afhankelijk van de boeking ook 'Live' en 'Time-lapse', brengen je naar de respectievelijke pagina's.  
                </p>

                <h3>drie parallelle lijnen</h3>
                <p>
                Door op deze knop te klikken, worden de beelden van alle camera's zo groot mogelijk weergegeven, zodat je een goed overzicht krijgt van de huidige situatie. Om technische redenen worden alle beelden alleen in een verkleind voorbeeldformaat 
                in de cloud weergegeven; de originele beelden worden ongewijzigd in hun originele grootte opgeslagen. Je kunt beelden downloaden in hun originele grootte.  
                </p>

                <h3>Groene stip</h3>
                <p>
                Een groene stip links boven het beeld geeft aan dat de camera actief is en beelden worden overgebracht naar de BAU.CAMERA Cloud. 
                </p>

                <h3>Rode stip</h3>
                <p>
                Als hier een rode stip staat, heeft de camera onlangs nog geen overdracht naar de cloud gemaakt. Controleer voor de zekerheid de datum en tijd van de laatste overdracht rechts boven het beeld. Soms is de camera al weer aan het verzenden en is de 
                rode stip er nog steeds; hij wordt pas na een bepaalde tijd weer groen.  
                </p>
                <p>
                De meest waarschijnlijke oorzaak van een BAU.CAMERA zonder transmissie is een onderbroken voeding. Laat de stroomtoevoer naar de BAU.CAMERA ter plaatse controleren. We kunnen dit niet op afstand controleren. Als er een stroomvoorziening is, haal dan 
                ook één keer de stekker uit het stopcontact en steek deze er na ongeveer 15 seconden weer in. Als de BAU.CAMERA niet werkt ondanks een veilige stroomvoorziening en herstart, neem dan contact op met BAU.CAMERA Support.  
                </p>

                <h3>Volgorde van de camera's</h3>
                <p>
                Je kunt de volgorde van de weergegeven camera's wijzigen door op het bladwijzerpictogram voor elke camera te klikken. Elke gebruiker kan deze volgorde individueel instellen.   

                </p>     
            </div></div>
    );
};

export default ProjectManual;