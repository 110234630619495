import React from "react";

const FAQManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>FAQ</h2>

                <h3>
                When I enlarge an image in the project view or in the player, it sometimes looks a little blurred. What is the reason for this?
                </h3>
All images are displayed in the cloud in a reduced preview size to ensure fast and good data handling. The original images are not changed and are saved in their original size. You will receive all images in their original size as soon as you download them.  
                <br/>

                <h3>I would like to put the current picture of the camera on our homepage. How can I do that?</h3>
In the "Camera" view below the player, please click the "Copy image link" button. The image link will be copied and you can integrate it into your homepage or intranet. It refers to the latest image without requesting access data. This link is only available as soon as the camera has transferred the first images. This function is only available to main users.  
                <br/>

                <h3>No more current images are transferred to the cloud. What can be done?</h3>
DThe most likely cause is an interrupted power supply. Please have the power supply to the BAU.CAMERA checked on site. We cannot check this remotely. If there is a power supply, please also pull the plug once and plug it back in after approx. 15 seconds.  

If the BAU.CAMERA does not work despite a secure power supply and restart, please contact support.
                <br/>

                <h3>I no longer need the camera. What should I do?</h3>
If you no longer need the BAU.CAMERA, simply send it back to us. Written notice of termination at the end of the rental period is no longer required. This does not affect the agreed minimum term.  

Our equipment must be returned to us by the end of the current rental period at the latest in order not to trigger an automatic extension of the rental period by one month. Please ensure that the equipment is returned in good time, taking into account the package transit time. The date of the end of the agreed rental period can be found on the last invoice.  

Please pack the BAU.CAMERA equipment carefully and return it to us. The customer is responsible for damage due to inadequate packaging. For your own safety, insure the package against loss up to 2.500€. If possible, please send us the tracking number of the parcel to support@baucamera.com.  
                <br/>

                <h3>Address for return shipment:</h3>
NEXTFRAME Film + Media GmbH  
Östbergweg 14  
30559 Hannover  
                <br/>

                <h3>I would like to rent my camera for longer. What do I have to do?</h3>
You can automatically extend the camera on a monthly basis, you don't have to do anything. You will then receive a monthly invoice for another month's rent. If you prefer to be billed every six months as before, please contact Support.  
                <br/>
            </div></div>
    );
};

export default FAQManual;