import React, { useEffect, useState, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import "../../styles/pages/projects/projects.scss";
import {
    getProject,
    getRecentProjectsByUser
} from "../../helpers/manualQueries";
import { Row, Col } from "react-bootstrap";
import Card from "../../components/Card";
import { Auth } from "aws-amplify";
import translate from "../../i18n/translate";
import Loader from "../../components/Loader";
import { ProjectImage } from "../Projects/ProjectImage";

const Home = (props) => {
    const [ projects, setProjects ] = useState([]);
    const [ userName, setUserName ] = useState("");
    const [ loader, setLoader ] = useState(false);
    const [ Role, setRole ] = useState("");
    const [ theme, setTheme ] = useState("light");
    const [ recentViewedProjects, setRecentViewedProjects ] = useState([]);

    let fetchListProjectsFunc = useRef();

    useEffect(() => {
        setTheme(window.localStorage.getItem("theme"));
    }, [ window.localStorage.getItem("theme") ]);

    useEffect(() => {
        getRole();
    }, []);

    useEffect(() => {
        fetchListProjectsFunc.current();
    }, [ recentViewedProjects ]);

    useEffect(() => {
        getRecentProjectsIDs();
    }, [ userName ]);


    const setHeight = () => {
        const height = localStorage.getItem("height");
        document.documentElement.scrollTop = height;
        localStorage.removeItem("height");
    };

    const getRole = async () => {
        const userDetails = await Auth.currentAuthenticatedUser();
        const role =
            userDetails?.signInUserSession?.accessToken?.payload[
                "cognito:groups"
            ][ 0 ];
        setRole(role.toString().toLowerCase());
        setUserName(userDetails?.username);
    };

    const getRecentProjectsIDs = () => {
        if (userName) {
            API.graphql(graphqlOperation(getRecentProjectsByUser, { filter: { username: { eq: userName } } }))
                .then(({ data } ) => { 
                    const recentProjectsIDs = data.listUsers.items[ 0 ]?.recentProjects;
                    setRecentViewedProjects(recentProjectsIDs);
                });
        }
    };

    const fetchListProjects = async () => {
        setLoader( true );
        if ( !recentViewedProjects ) {
            return;
        } else if (recentViewedProjects.length) {
            recentViewedProjects.forEach((id) => {
                API.graphql(
                    graphqlOperation(getProject, { id: id })
                )
                    .then(
                        (data) => {
                            if (data.data?.getProject) {
                                setProjects(prev => [ ...prev, data.data?.getProject ]);   
                            }
                            setLoader(false);
                            setHeight();
                        }
                    )
                    .catch(() => {
                        setLoader(false);
                    });
            });
        } else {
            setLoader(false);
        }
    };

    fetchListProjectsFunc.current = fetchListProjects;

    const {
        match: { params },
    } = props;

    return (
        <div className='projects'>
            <div className='projects__cover'>
                <h1 className='projects__header'>{translate("last-viewed-projects")}</h1>
            </div>
            <Row className='thumbnails-row'>
                {loader ? (
                    <Loader />
                ) : projects.length ? (
                    Role === "admin" || Role === "crew" ? (
                        projects.map((project) => (
                            <Col
                                key={project.id}
                                sm={12}
                                md={6}
                                lg={3}
                                className='thumbnails__col'
                            >
                                <div className='projects__thumnail'>
                                    <Card
                                        onClick={() => {
                                            localStorage.setItem(
                                                "height",
                                                document.documentElement
                                                    .scrollTop
                                            );
                                            props.history.push({
                                                pathname: `/projects/${project.id}`,
                                            });
                                        }}
                                    >
                                        <div className='project__thumbnail-img'>
                                            {project.cameras?.items
                                                ?.length ? (
                                                    project.cameras.items
                                                        .slice(0, 3)
                                                        .map(
                                                            (camera, index) => (
                                                                <ProjectImage
                                                                    key={index}
                                                                    cameraIDs={
                                                                        project?.cameraIDs
                                                                    }
                                                                    camera={
                                                                        camera
                                                                    }
                                                                    length={
                                                                        project?.cameras?.items.slice(
                                                                            0,
                                                                            3
                                                                        ).length
                                                                    }
                                                                />
                                                            )
                                                        )
                                                ) : (
                                                    <img
                                                        height='100px'
                                                        src={theme === "dark" ? 
                                                            require("../../assets/images/greycam-dark.png") :  
                                                            require("../../assets/images/greycam.png")}
                                                        className='default_img'
                                                        alt='Default Camera'
                                                    />
                                                )}
                                        </div>
                                        <div className='project__thumbnails-info'>
                                            <h6>{project.name}</h6>
                                            <p>{project?.location}</p>
                                            <p>{project?.company?.name}</p>
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                        ))
                    ) : null
                ) : (
                    <h2 className='projects__thumbnail-empty'>
                        {translate("no-last-view-projects")}
                    </h2>
                )}
            </Row>
        </div>
    );
};

export default Home;
