import React, { useState, useEffect } from "react";
import Proptypes from "prop-types";
import { NavLink } from "react-router-dom";
import { menuItemsAdmin, menuItemsSuperUser } from "../helpers/navbarItems";

const NavBar = ({ Role }) => { 
    const [ menuItems, setMenuItems ] = useState([]);
    const currentClass = Role === "superuser" ? "navbar-desktop--superuser" : "navbar-desktop";

    useEffect(() => {
        if (Role === "admin" || Role === "crew") {
            setMenuItems(menuItemsAdmin);
        } else if (Role === "superuser") {
            setMenuItems(menuItemsSuperUser);
        }
    }, [ Role ]);

    return (
        <ul className={currentClass}>
            {menuItems?.map(item => 
                <li key={item.name}>
                    <NavLink 
                        to={item.path} 
                        activeStyle={{ color: "#1181F2" }} 
                        exact={item.exact ? item.exact : null}
                        isActive={item.isActive ? item.isActive : null}
                    >
                        {item.name}
                    </NavLink>
                </li>)
            }
        </ul>
    );};

NavBar.propTypes = {
    Role: Proptypes.string,
};

export default NavBar;
