import React from "react";
import { Modal } from "react-bootstrap";
import { ButtonPrimary } from "./ButtonsPrimary";
import PropTypes from "prop-types";
import "../styles/components/SimpleModal.scss";
import translate from "../i18n/translate";

const SimpleModal = ({ open, onHide, email }) => {
    return (
        <Modal className="simple-modal" show={open} onHide={onHide} centered>
            <div style={{ textAlign: "center" }}>
                <h3>{translate("send-download-link", { email: email })}</h3>
                <div className='simple-modal__btn-div'>
                    <ButtonPrimary
                        title="Ok"
                        type="submit"
                        backgroundColor="#fff"
                        color="#000"
                        onClick={onHide}
                    />
                </div>
            </div>
        </Modal>
    );
};

SimpleModal.propTypes = {
    open: PropTypes.bool,
    onHide: PropTypes.func,
    email: PropTypes.string
};


export default SimpleModal;
