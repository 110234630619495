import  React  from "react";
import Snackbar from "@mui/material/Snackbar";
import { propTypes } from "react-bootstrap/esm/Image";
import "../styles/pages/Cams/cams-popup.scss";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SnackbarContent from "@mui/material/SnackbarContent";

const ActionSnackbar = ({ openBool, onCloseSnackbar, message, severity, opacity }) => {
    const vertical = "bottom";
    const horizontal = "left";
    const isSevere = (severity === "info" ? false : true);
    const border = (isSevere ? "none" : "none");
    return (
        <div className="snackbar-container">
            <Snackbar 
                open={openBool}
                onClose={onCloseSnackbar}
                autoHideDuration={3000}
                anchorOrigin={{ vertical, horizontal }}
            >
                <SnackbarContent sx={{ color: "white", fontWeight: "bold",  border: { border }, boxShadow: 1, fontSize: 14, borderRadius: "18px",
                    backgroundColor: opacity ? "rgba(17, 129, 242, 1)" : "rgba(17, 129, 242, 1)" }} message={message}  action={
                    <React.Fragment>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.5 }}
                            onClick={onCloseSnackbar}
                        >
                            <CloseIcon />
                        </IconButton>   
                        
                    </React.Fragment>
                }></SnackbarContent>
            </Snackbar>
        </div>
    );
}; export default ActionSnackbar;

ActionSnackbar.propTypes = {
    openBool: propTypes.bool,
    message: propTypes.string,
    action: propTypes.func,
    severity: propTypes.string,
    onCloseSnackbar: propTypes.func,
    opacity: propTypes.bool,
};