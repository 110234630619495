import React from "react";

const ArchiveManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>Archiv</h2>
                Im Archiv können Sie Ihre Bilder herunterladen. Dazu können Sie rechts oben eine Auswahl treffen und die Bilder entsprechend filtern. 
                In den ersten beiden Feldern geben Sie das Start- und Enddatum ein, in den Feldern rechts daneben können Sie zusätzlich noch nach einer Uhrzeit 
                filtern mit Start- und Endzeit. Zusätzlich können Sie mit „Wochenende ausschließen“, ob alle Bilder der Woche ausgewählt werden oder nur die Bilder Montag bis Freitag.
                <br/><br/>
                Sollten Sie die Bilder nur eines ganz bestimmten Tages suchen, können Sie den Filter auf „nach Datum“ umstellen, so werden alle Bilder dieses einen Tages angezeigt.
                <br/><br/>
                Sobald sie eine Auswahl getroffen haben, können Sie auf den Button „Herunterladen“ klicken und so die Herstellung eines Download-Links beauftragen.
                 Die Cloud stellt nun diese Bildauswahl für Sie bereit. Sobald dieser Vorgang abgeschlossen ist, erhalten Sie eine E-Mail mit einem Downloadlink. 
                Je nach Größe des Archivs kann das bis zu einer halben Stunde dauern.
                <br/><br/>
                Downloads könnte nur beauftragt werden, wenn der davor initiierte Auftrag abgeschlossen wurde. Sie erkennen den Status dieser Funktion an der Farbe auf dem Button. Grün bedeutet, dass ein Downloads beauftragt werden kann, blau bedeutet, 
                dass zur Zeit noch ein Auftrag bearbeitet wird und mit dem nächsten Auftrag noch gewartet werden muss.
                <br/><br/>
                Haben Sie mehrere Kameras im Projekt, können Sie links oben unter „Cam auswählen“ die jeweilige Kamera für die Archiv-Ansicht festlegen.
            </div></div>
    );
};

export default ArchiveManual;