/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      firstname
      lastname
      language
      email
      notificationsEnabled
      role
      imagesKeys
      dateRange
      timeRange
      filteredImages
      workday
      allImages
      archiving
      projects {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      status
      createdBy
      roleModifiedBy
      pendingDeletion
      archiveStatus
      recentProjects
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      firstname
      lastname
      language
      email
      notificationsEnabled
      role
      imagesKeys
      dateRange
      timeRange
      filteredImages
      workday
      allImages
      archiving
      projects {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      status
      createdBy
      roleModifiedBy
      pendingDeletion
      archiveStatus
      recentProjects
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      firstname
      lastname
      language
      email
      notificationsEnabled
      role
      imagesKeys
      dateRange
      timeRange
      filteredImages
      workday
      allImages
      archiving
      projects {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      status
      createdBy
      roleModifiedBy
      pendingDeletion
      archiveStatus
      recentProjects
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      companyID
      name
      location
      latitude
      longitude
      shareableLink
      cameras {
        items {
          id
          cameraName
          cameraAlias
          username
          password
          status
          project {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          imagesByDate {
            nextToken
            __typename
          }
          liveStream
          liveStreamLink
          latestPictureLink
          protocol
          ftpLink
          rmsLink
          downloadImagesLink
          deleteAllImages
          changePassword
          hideCopyLink
          blurringEnabled
          blurringProEnabled
          maskEnabled
          timelapseEnabled
          testMode
          externalCamera
          lastDayTimelapseStatus
          lastWeekTimelapseStatus
          lastMonthTimelapseStatus
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        id
        name
        projects {
          items {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      endedAt
      cameraIDs
      deleteAllImages
      pendingDeletion
      weather_key
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      companyID
      name
      location
      latitude
      longitude
      shareableLink
      cameras {
        items {
          id
          cameraName
          cameraAlias
          username
          password
          status
          project {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          imagesByDate {
            nextToken
            __typename
          }
          liveStream
          liveStreamLink
          latestPictureLink
          protocol
          ftpLink
          rmsLink
          downloadImagesLink
          deleteAllImages
          changePassword
          hideCopyLink
          blurringEnabled
          blurringProEnabled
          maskEnabled
          timelapseEnabled
          testMode
          externalCamera
          lastDayTimelapseStatus
          lastWeekTimelapseStatus
          lastMonthTimelapseStatus
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        id
        name
        projects {
          items {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      endedAt
      cameraIDs
      deleteAllImages
      pendingDeletion
      weather_key
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      companyID
      name
      location
      latitude
      longitude
      shareableLink
      cameras {
        items {
          id
          cameraName
          cameraAlias
          username
          password
          status
          project {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          imagesByDate {
            nextToken
            __typename
          }
          liveStream
          liveStreamLink
          latestPictureLink
          protocol
          ftpLink
          rmsLink
          downloadImagesLink
          deleteAllImages
          changePassword
          hideCopyLink
          blurringEnabled
          blurringProEnabled
          maskEnabled
          timelapseEnabled
          testMode
          externalCamera
          lastDayTimelapseStatus
          lastWeekTimelapseStatus
          lastMonthTimelapseStatus
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        id
        name
        projects {
          items {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      endedAt
      cameraIDs
      deleteAllImages
      pendingDeletion
      weather_key
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserProject = /* GraphQL */ `
  mutation CreateUserProject(
    $input: CreateUserProjectInput!
    $condition: ModelUserProjectConditionInput
  ) {
    createUserProject(input: $input, condition: $condition) {
      id
      userID
      projectID
      projects {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        id
        username
        firstname
        lastname
        language
        email
        notificationsEnabled
        role
        imagesKeys
        dateRange
        timeRange
        filteredImages
        workday
        allImages
        archiving
        projects {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        status
        createdBy
        roleModifiedBy
        pendingDeletion
        archiveStatus
        recentProjects
        __typename
        createdAt
        updatedAt
        __typename
      }
      pinnedCams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserProject = /* GraphQL */ `
  mutation UpdateUserProject(
    $input: UpdateUserProjectInput!
    $condition: ModelUserProjectConditionInput
  ) {
    updateUserProject(input: $input, condition: $condition) {
      id
      userID
      projectID
      projects {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        id
        username
        firstname
        lastname
        language
        email
        notificationsEnabled
        role
        imagesKeys
        dateRange
        timeRange
        filteredImages
        workday
        allImages
        archiving
        projects {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        status
        createdBy
        roleModifiedBy
        pendingDeletion
        archiveStatus
        recentProjects
        __typename
        createdAt
        updatedAt
        __typename
      }
      pinnedCams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserProject = /* GraphQL */ `
  mutation DeleteUserProject(
    $input: DeleteUserProjectInput!
    $condition: ModelUserProjectConditionInput
  ) {
    deleteUserProject(input: $input, condition: $condition) {
      id
      userID
      projectID
      projects {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        id
        username
        firstname
        lastname
        language
        email
        notificationsEnabled
        role
        imagesKeys
        dateRange
        timeRange
        filteredImages
        workday
        allImages
        archiving
        projects {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        status
        createdBy
        roleModifiedBy
        pendingDeletion
        archiveStatus
        recentProjects
        __typename
        createdAt
        updatedAt
        __typename
      }
      pinnedCams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            nextToken
            __typename
          }
          company {
            id
            name
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            nextToken
            __typename
          }
          endedAt
          cameraIDs
          deleteAllImages
          pendingDeletion
          weather_key
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            nextToken
            __typename
          }
          company {
            id
            name
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            nextToken
            __typename
          }
          endedAt
          cameraIDs
          deleteAllImages
          pendingDeletion
          weather_key
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            nextToken
            __typename
          }
          company {
            id
            name
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            nextToken
            __typename
          }
          endedAt
          cameraIDs
          deleteAllImages
          pendingDeletion
          weather_key
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCamera = /* GraphQL */ `
  mutation CreateCamera(
    $input: CreateCameraInput!
    $condition: ModelCameraConditionInput
  ) {
    createCamera(input: $input, condition: $condition) {
      id
      cameraName
      cameraAlias
      username
      password
      status
      project {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      imagesByDate {
        items {
          id
          cameraID
          datetime
          date
          time
          intTime
          workday
          s3ObjectPathOriginal
          s3ObjectPathThumbnail
          s3ObjectPathBlurred
          s3ObjectPathBlurringPro
          blurringPro
          blurred
          masked
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      liveStream
      liveStreamLink
      latestPictureLink
      protocol
      ftpLink
      rmsLink
      downloadImagesLink
      deleteAllImages
      changePassword
      hideCopyLink
      blurringEnabled
      blurringProEnabled
      maskEnabled
      timelapseEnabled
      testMode
      externalCamera
      lastDayTimelapseStatus
      lastWeekTimelapseStatus
      lastMonthTimelapseStatus
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCamera = /* GraphQL */ `
  mutation UpdateCamera(
    $input: UpdateCameraInput!
    $condition: ModelCameraConditionInput
  ) {
    updateCamera(input: $input, condition: $condition) {
      id
      cameraName
      cameraAlias
      username
      password
      status
      project {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      imagesByDate {
        items {
          id
          cameraID
          datetime
          date
          time
          intTime
          workday
          s3ObjectPathOriginal
          s3ObjectPathThumbnail
          s3ObjectPathBlurred
          s3ObjectPathBlurringPro
          blurringPro
          blurred
          masked
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      liveStream
      liveStreamLink
      latestPictureLink
      protocol
      ftpLink
      rmsLink
      downloadImagesLink
      deleteAllImages
      changePassword
      hideCopyLink
      blurringEnabled
      blurringProEnabled
      maskEnabled
      timelapseEnabled
      testMode
      externalCamera
      lastDayTimelapseStatus
      lastWeekTimelapseStatus
      lastMonthTimelapseStatus
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCamera = /* GraphQL */ `
  mutation DeleteCamera(
    $input: DeleteCameraInput!
    $condition: ModelCameraConditionInput
  ) {
    deleteCamera(input: $input, condition: $condition) {
      id
      cameraName
      cameraAlias
      username
      password
      status
      project {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      imagesByDate {
        items {
          id
          cameraID
          datetime
          date
          time
          intTime
          workday
          s3ObjectPathOriginal
          s3ObjectPathThumbnail
          s3ObjectPathBlurred
          s3ObjectPathBlurringPro
          blurringPro
          blurred
          masked
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      liveStream
      liveStreamLink
      latestPictureLink
      protocol
      ftpLink
      rmsLink
      downloadImagesLink
      deleteAllImages
      changePassword
      hideCopyLink
      blurringEnabled
      blurringProEnabled
      maskEnabled
      timelapseEnabled
      testMode
      externalCamera
      lastDayTimelapseStatus
      lastWeekTimelapseStatus
      lastMonthTimelapseStatus
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      cameraID
      datetime
      date
      time
      intTime
      workday
      s3ObjectPathOriginal
      s3ObjectPathThumbnail
      s3ObjectPathBlurred
      s3ObjectPathBlurringPro
      blurringPro
      blurred
      masked
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      cameraID
      datetime
      date
      time
      intTime
      workday
      s3ObjectPathOriginal
      s3ObjectPathThumbnail
      s3ObjectPathBlurred
      s3ObjectPathBlurringPro
      blurringPro
      blurred
      masked
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      cameraID
      datetime
      date
      time
      intTime
      workday
      s3ObjectPathOriginal
      s3ObjectPathThumbnail
      s3ObjectPathBlurred
      s3ObjectPathBlurringPro
      blurringPro
      blurred
      masked
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHistoricalArchive = /* GraphQL */ `
  mutation CreateHistoricalArchive(
    $input: CreateHistoricalArchiveInput!
    $condition: ModelHistoricalArchiveConditionInput
  ) {
    createHistoricalArchive(input: $input, condition: $condition) {
      id
      projectName
      projectId
      companyName
      shareableLink
      archivingDate
      numberOfImages
      storageSpace
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHistoricalArchive = /* GraphQL */ `
  mutation UpdateHistoricalArchive(
    $input: UpdateHistoricalArchiveInput!
    $condition: ModelHistoricalArchiveConditionInput
  ) {
    updateHistoricalArchive(input: $input, condition: $condition) {
      id
      projectName
      projectId
      companyName
      shareableLink
      archivingDate
      numberOfImages
      storageSpace
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHistoricalArchive = /* GraphQL */ `
  mutation DeleteHistoricalArchive(
    $input: DeleteHistoricalArchiveInput!
    $condition: ModelHistoricalArchiveConditionInput
  ) {
    deleteHistoricalArchive(input: $input, condition: $condition) {
      id
      projectName
      projectId
      companyName
      shareableLink
      archivingDate
      numberOfImages
      storageSpace
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      log
      user
      expire
      logType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      log
      user
      expire
      logType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      log
      user
      expire
      logType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCredentials = /* GraphQL */ `
  mutation CreateCredentials(
    $input: CreateCredentialsInput!
    $condition: ModelCredentialsConditionInput
  ) {
    createCredentials(input: $input, condition: $condition) {
      id
      googleApiKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCredentials = /* GraphQL */ `
  mutation UpdateCredentials(
    $input: UpdateCredentialsInput!
    $condition: ModelCredentialsConditionInput
  ) {
    updateCredentials(input: $input, condition: $condition) {
      id
      googleApiKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCredentials = /* GraphQL */ `
  mutation DeleteCredentials(
    $input: DeleteCredentialsInput!
    $condition: ModelCredentialsConditionInput
  ) {
    deleteCredentials(input: $input, condition: $condition) {
      id
      googleApiKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCloudStatus = /* GraphQL */ `
  mutation CreateCloudStatus(
    $input: CreateCloudStatusInput!
    $condition: ModelCloudStatusConditionInput
  ) {
    createCloudStatus(input: $input, condition: $condition) {
      id
      displayStatus
      displayMessage
      bannerStatus
      bannerMessage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCloudStatus = /* GraphQL */ `
  mutation UpdateCloudStatus(
    $input: UpdateCloudStatusInput!
    $condition: ModelCloudStatusConditionInput
  ) {
    updateCloudStatus(input: $input, condition: $condition) {
      id
      displayStatus
      displayMessage
      bannerStatus
      bannerMessage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCloudStatus = /* GraphQL */ `
  mutation DeleteCloudStatus(
    $input: DeleteCloudStatusInput!
    $condition: ModelCloudStatusConditionInput
  ) {
    deleteCloudStatus(input: $input, condition: $condition) {
      id
      displayStatus
      displayMessage
      bannerStatus
      bannerMessage
      createdAt
      updatedAt
      __typename
    }
  }
`;
