import React, { useEffect, useState, useRef } from "react";
import "../../styles/pages/users/users.scss";
import "../../styles/pages/historicalArchive/historicalArchive.scss";
import { Link } from "react-router-dom";
import { FaUser, FaUserEdit, FaTimes } from "react-icons/fa";
import Grid from "@mui/material/Grid";
import translate from "../../i18n/translate";
import PropTypes from "prop-types";
import Loader from "../../components/Loader";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { ClickAwayListener } from '@mui/base';
import { ButtonPrimary } from "../../components/ButtonsPrimary";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    backgroundColor: "rgba(0, 0, 0, 0)",
    boxShadow: "none",
    color: theme.palette.text.secondary,
}));

const UserTable = ({
        isNavbarHidden, 
        currentItem, 
        comlumnHeaderWithSorting, 
        openModalFunc, 
        openDeleteModalFunc, 
        loader, 
        boxDisplay, 
        boxDisplayFunc, 
        boxIndex, 
        boxIndexFunc ,
        loggedInRole
    }) => {

    const [addClass, setAddClass] = useState(null);
    useEffect(() => {
        setAddClass((isNavbarHidden ? "users__table navbar-hidden-box" : "users__table box-users"));
    }, [isNavbarHidden]); 
    const mobileView = useMediaQuery("(max-width:550px)");
    const projectBox = useRef(null);

    const handleClickAway = () => {
        boxIndexFunc();
    }

    return (
    <>
    {mobileView? <div className={addClass}> 
    <div className="users__table-head">
        <div className="col-lg-1"></div>
        <div className="col-lg-2">{comlumnHeaderWithSorting("lastname","name")}</div>
        <div className="col-lg-2">{comlumnHeaderWithSorting("username","username")}</div>
        <div className="col-lg-1">{translate("user-role")}</div>
        <div className="col-lg-2">E-mail</div>
        <div className="col-lg-1">Created By</div>
        <div className="col-lg-1">Projects</div>
    </div>

    {loader? (
        <Loader />
    ):currentItem.length? (
        currentItem?.map((user,index) => {
            return (
                <div className="users__item row" key={user.id}>
                    <div className="col-lg-1 col-sm-12 user__item-avatar">
                        <div className="users__item-picture">
                            <FaUser color="#ffff" size={26} />
                        </div>
                        <div className="users__item-name-box">
                            <span className="users__item-name">{translate("name")}</span>
                            <span>{user.firstname+" "+user.lastname}</span>
                        </div>
                    </div>

                    <div className="col-lg-2 users__hide-column">{user.lastname+","+" "+user.firstname}</div>
                    <div className="col-lg-2 users__hide-column">{user.username}</div>
                    <div className="col-lg-1 users__hide-column">{user.role}</div>
                    <div className="col-lg-2 users__hide-column">{user.email}</div>
                    <div className="col-lg-1 users__hide-column">{user.createdBy}</div>
                    <div className="col-lg-1 users__hide-column users__projects">
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <button
                            className="users__item-btn mx-0"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              boxDisplayFunc(e, index);
                            }}
                        >
                           {user.projects.items.filter(item => item.projects?.endedAt===null).length}
                        </button>
                        </ClickAwayListener> 
                        <div
                            className="users__projects-list"
                            style={boxIndex===index? { display: boxDisplay }:null}
                            ref={projectBox}
                        >
                            {user.projects.items
                                .filter(item => item.projects?.endedAt === null)
                                .map((project,i) => {
                                    return (
                                        <ul key={i}>
                                            <Link to={`/projects/${project.projectID}`}>
                                                <li>
                                                    {project.projects.name}
                                                </li>
                                            </Link>
                                        </ul>
                                    );
                                })}
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-12 user__item-edit"
                    >
                        <ButtonPrimary
                            onClick={() =>
                                openModalFunc(user.id)
                            }
                            title={<>
                            <FaUserEdit color="#1181F2" size={18} />
                            &nbsp;&nbsp;{translate("edit")}
                            </>}
                            disabled={(loggedInRole === "superuser") && (user.role.toLowerCase() === "superuser")}
                        />
                        <ButtonPrimary
                            onClick={() =>
                                openDeleteModalFunc(user)
                            }
                            title={
                                <><FaTimes color="#1181F2" size={22} />
                            &nbsp;{translate("delete")}
                                </>
                            }
                            disabled={(loggedInRole === "superuser") && (user.role.toLowerCase() === "superuser")}
                        />
                    </div>
                </div>
            );
        })
    ):(
        <div style={{ width: "100%" }}>
            <h2 className="handle-empty-element">{translate("no-users")}</h2>
        </div>
    )}
</div>:
    <div className="users__table">
        <div className="users__table-head
    " style={{ float: "left",width: "100%" }}>
            <div style={{ overflow: "hidden",textOverflow: "ellipsis" }} className="col-lg-1 col-md-1 col-sm-2 col-2"></div>
            <div style={{ overflow: "hidden",textOverflow: "ellipsis" }} className="d-none d-lg-block col-lg-2 col-md-1 col-sm-2 col-2">{comlumnHeaderWithSorting("lastname","name")}</div>
            <div style={{ overflow: "hidden",textOverflow: "ellipsis" }} className="col-lg-1 col-md-2 col-sm-2 col-5">{comlumnHeaderWithSorting("username","username")}</div>
            <div style={{ overflow: "hidden",textOverflow: "ellipsis" }} className="d-none d-lg-block d-md-block d-sm-block col-lg-1 col-md-2 col-sm-2 col-1">{translate("user-role")}</div>
            <div style={{ overflow: "hidden",textOverflow: "ellipsis" }} className="d-none d-lg-block d-md-block col-lg-2 col-md-2">E-mail</div>
            <div style={{ overflow: "hidden",textOverflow: "ellipsis" }} className="col-lg-1 d-none d-lg-block col-lg-1 ">Created By</div>
            <div style={{ overflow: "hidden",textOverflow: "ellipsis" }} className="col-lg-1 col-md-6 col-sm-6 col-7">Projects</div>
        </div>
        <div className={addClass}>
            {loader? (
                <Loader />
            ):currentItem.length? (
                currentItem?.map((user,index) => {
                    return (
                        <div className="users__item row" key={user.id}>
                            <div style={{ overflow: "hidden",textOverflow: "ellipses" }} className="col-lg-1 col-md-1 col-sm-2 col-2 user__item-avatar">
                                <div className="users__item-picture">
                                    <FaUser color="#ffff" size={26} />
                                </div>
                                <div className="users__item-name-box">
                                    <span className="users__item-name">{translate("name")}</span>
                                    <span>{user.firstname+" "+user.lastname}</span>
                                </div>
                            </div>
                            <div style={{ overflow: "hidden",textOverflow: "ellipsis" }} className="d-none d-lg-block col-lg-2 col-md-1 col-sm-2 col-2 users__hide-column">{user.lastname+","+" "+user.firstname}</div>
                            <div style={{ overflow: "hidden",textOverflow: "ellipsis" }} className="col-lg-1 col-md-2 col-sm-2 col-3 users__hide-column">{user.username}</div>
                            <div style={{ overflow: "hidden",textOverflow: "ellipsis" }} className="d-none d-md-block d-lg-block d-sm-block col-lg-1 col-md-2 col-sm-2 col-1 users__hide-column">
                                {translate(user.role.replace( /([a-z])([A-Z])/g, "$1 $2").replace(/\s+/g, '-').toLowerCase())}
                            </div>
                            <div style={{ overflow: "hidden",textOverflow: "ellipsis" }} className="d-none d-lg-block d-md-block col-lg-2 col-md-2 users__hide-column">{user.email}</div>
                            <div style={{ overflow: "hidden",textOverflow: "ellipsis" }} className="col-lg-1 d-none d-lg-block col-lg-1  users__hide-column">{user.createdBy}</div>
                            <div style={{ overflow: "visible" }} className="col-lg-1 col-md-1 col-sm-1 col-3 users__hide-column users__projects">
                            <ClickAwayListener onClickAway={handleClickAway}>
                        <button
                            className="users__item-btn mx-0"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              boxDisplayFunc(e, index);
                            }}
                        >
                           {user.projects.items.filter(item => item.projects?.endedAt===null).length}
                        </button>
                        </ClickAwayListener> 
                        <div
                            className="users__projects-list"
                            style={boxIndex===index? { display: boxDisplay }:null}
                            ref={projectBox}
                        >
                            
                            {user.projects.items
                                .filter(item => item.projects?.endedAt===null)
                                .map((project,i) => {
                                    return (
                                        <ul key={i}>
                                            <Link to={`/projects/${project.projectID}`}>
                                                <li>
                                                    {project.projects.name}
                                                </li>
                                            </Link>
                                        </ul>
                                    );
                                })}
                        </div>
                       
                            </div>


                            <div className="col-lg-3 col-md-4  col-sm-5 col-4 user__item-edit">
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                             <Item  sx={{fontSize: 14}}>
                                <ButtonPrimary 
                                    onClick={() =>{openModalFunc(user.id)}}
                                    title={
                                    <>
                                    <FaUserEdit
                                            className="historicalArchive__item-btn-download-icon"
                                            color="#1181F2"
                                            size={22}
                                        />
                                    {translate("edit")}
                                    </>
                                }
                                disabled={(loggedInRole === "superuser") && (user.role.toLowerCase() === "superuser")}
                                />
                                    </Item>
                            <Item sx={{fontSize: 14}}>
                                <ButtonPrimary
                                    onClick={() =>
                                      openDeleteModalFunc(user.username, user.id, user.projects, user.role)
                                    }
                                    title={
                                        <>
                                        <FaTimes color="#1181F2" size={22} />
                                        &nbsp;{translate("delete")}
                                        </>
                                    }
                                    disabled={(loggedInRole === "superuser") && (user.role.toLowerCase() === "superuser")}
                                />
                            </Item>
                           </Grid>
                            </div>
                        </div>
                    );
                })
            ):(
                <div style={{ width: "100%" }}>
                    <h2 className="handle-empty-element">{translate("no-users")}</h2>
                </div>
            )}
        </div>
    </div>}
    </>)
}; export default UserTable;

UserTable.propTypes = {
    isNavbarHidden: PropTypes.bool,
    currentItem: PropTypes.any,
    comlumnHeaderWithSorting: PropTypes.func,
    openModalFunc: PropTypes.func,
    openDeleteModalFunc: PropTypes.func,
    loader: PropTypes.bool,
    boxDisplay: PropTypes.string,
    boxDisplayFunc: PropTypes.func,
    boxIndex: PropTypes.number,
    boxIndexFunc: PropTypes.func,
    loggedInRole: PropTypes.string
};