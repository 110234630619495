import { createGlobalStyle } from "styled-components";


export const GlobalStyles = createGlobalStyle `
  .overview__dropdown, .header__upper, .navbar, .users__table .users__item, .companies__table, .historicalArchive__search-input input,
  .select-dropdown .css-yk16xz-control, .select-dropdown .css-1pahdxg-control 
  {
    background: ${({ theme }) => theme.body};
  }

  body {
    background: ${({ theme }) => theme.body};
  }

  .header__extend-btn, .overview__searchInput,  .css-yk16xz-control,
  .nav-tabs .nav-item.show .nav-link,
  .add-project form .add-camera-column .css-yk16xz-control, .add-project form .add-camera-column .add-camera__edit, .add-project form .add-camera-column .css-1pahdxg-control,
  .add-project form .add-camera-column input, .form-control:focus, .add-user__projects-sec, .add-user__projects-sec-search-input input,
  .historicalArchive__filter .css-2b097c-container .css-yk16xz-control, .help .help__modal, .header__upper .header__upper-language .header__language-choices button, 
  .nav-tabs .nav-item, .css-4ljt47-MenuList, .dropdown-menu.show, .dropdown-menu.show .dropdown-item, .css-26l3qy-menu, .css-1pahdxg-control,
  .rs-picker-toggle-active, .rs-picker-toggle-textbox, .css-qumsi5-menu, .select-dropdown .css-g1d714-ValueContainer, .css-yk16xz-, .css-1knxwwr-menu
  {
    background: ${({ theme }) => theme.card}!important;
  }

  .nav-tabs .nav-link.active {
    background: ${({ theme }) => theme.tabBody}!important;
  }

  .users__table .users__item, .historicalArchive__table .historicalArchive__item, .companies__table .companies__item
  {
    background: ${({ theme }) => theme.rowItem};
  }

  .overview .row, .log-event__container,  .companies__table .projects-box .project-box__list, .Archive__tile
  {
    background: ${({ theme }) => theme.body2}!important;
  }

  .form-control, .createEditCamera__modal .row .inputField, .form-control:disabled 
  {
    background: ${({ theme }) => theme.fieldBg}!important;
  }

  h1, h2, h3, h4, h5, h6, p, label, .label, input, .header__upper-signout, .help__text, .historicalArchive__sorting, .overview__dropdown .drop-down label,
  .overview__cam-name, .overview__company-name, .truncate, .users__table .users__item, .users__table .users__item .users__item-btn,
  .historicalArchive__table .historicalArchive__item, .historicalArchive__table .historicalArchive__table-head, .companies__table .companies__item,
  .companies__table .projects-box__item-btn, .companies__filter label, .historicalArchive__search-input .historicalArchive__search-input-icon,
  .overview__searchInput, .overview__searchInput:focus, .nav .nav-link, .log-event__container, .historicalArchive__search-input input,
  .historicalArchive__filter label, .createEditCamera__radioBtn label, .createEditCamera__modal .row .inputField, .add-project form .add-project__input-groupname,
  .inputField, .add-project form .add-camera-column input, .add-project form .add-project__camera-groupname, .add-project form .add-project__camera-plus,
  .drop-down .btn-primary, .add-project form .drop-down .icon, .css-1uccc91-singleValue, .camera-popup__opt-btns .btn-primary,
  .camera-popup__opt-btns .btn-primary svg, .companies__table .companies__table-head, .users__table .users__table-head, .add-user__projects-sec-search-input-icon,
  .add-user__projects-sec-search-input input, .help .help__modal, .help .help__modal a, .header__upper .header__upper-language .header__language-choices button,
  .companies__table .projects-box .project-box__list ul li, .single-project__return, .single-project .single-project__cover .single-project__location,
  .single-project .single-project__cover .single-project__company,
  .projects__dropdown-btn .drop-down .icon, .overview__dropdown .drop-down .icon, .Archive__Head-Heading, .Archive__all-camera-btn, .css-1ngmqkl-singleValue,
  .Archive__Head-Address, .Archive__tile-delete-col, .Archive__navbar--day, .Archive__navbar--day-selected, .css-11wtpcr-option, .Archive .select-dropdown svg, .dropdown-menu.show .dropdown-item,
  .single-project__cameraname,  .css-yt9ioa-option, .css-7oapp4-option, .weather-data, .pagination__page
  {
    color: ${({ theme }) => theme.text}!important;
  }

  .add-user__project-item {
    color: ${({ theme }) => theme.text};
  }

  .overview__dropdown .drop-down label, .overview__dropdown .drop-down .dropdown-toggle, .projects__dropdown-btn .drop-down label,
  .projects__dropdown-btn .drop-down .dropdown-toggle, .projects .pagination-container .pagination__page, .overview .pagination-container .pagination__page,
  .historicalArchive__item-btn, .rs-picker-daterange-header, .rs-calendar-table-cell-day
  {
    color: ${({ theme }) => theme.text};
  }
  .header__languages {
    color: ${({ theme }) => theme.text};
  }

  .button-primary {
    background: ${({ theme }) => theme.card};
    box-shadow: ${({ theme }) => theme.boxShadow};
    color: ${({ theme }) => theme.buttonText};
  }
  
  .single-project__archive-btn, .single-project__live-btn {
    color: ${({ theme }) => theme.buttonText};
  }

  .projects__header-btn .css-2b097c-container .css-yk16xz-control {
    background: ${({ theme }) => theme.card};
  }

  .projects .thumbnails-row .projects__thumnail .project__thumbnails-info {
    background: ${({ theme }) => theme.card};
    h6, p {
      color: ${({ theme }) => theme.text};
    }
  }
  
  .projects .thumbnails-row .projects__thumnail .project__thumbnail-img, .modal-content  
  {
    background: ${({ theme }) => theme.card2};
  }

  .historicalArchive__item-btn, .users__table .users__item .users__item-btn, .historicalArchive__table .historicalArchive__item .historicalArchive__item-btn,
  .companies__table .projects-box__item-btn, .pagination .pagination__btn, .single-project .thumbnails-row .thumbnails__col .single-project__thumbnails .single-project__btn-group .single-project__archive-btn,
  .single-project .thumbnails-row .thumbnails__col .single-project__thumbnails .single-project__btn-group .single-project__live-btn, .single-project .single-project__edit .single-project__edit-right button,
  .single-project .single-project__cover .single-project__cover-right button 
  {
    background: ${({ theme }) => theme.button};
  }
  
  .card, .header__extend-btn,  .pagination-container .pagination__btn, .overview .pagination-container .pagination__btn, .users__table .users__item .users__item-btn,
  .pagination .pagination__btn, .historicalArchive__item-btn, .historicalArchive__table .historicalArchive__item .historicalArchive__item-btn, .companies__table .projects-box__item-btn,
  .single-project .single-project__cover .single-project__cover-right button
  {
    box-shadow: ${({ theme }) => theme.boxShadow};
  }

  .pagination-container .pagination__btn, .overview .pagination-container .pagination__btn {
    background: ${({ theme }) => theme.card};
  }

  .overview .card, .header__upper .header__upper-language .header__language-choices button:hover {
    background: ${({ theme }) => theme.card2}!important;
  }
  
  .camera-popup {
    background: ${({ theme }) => theme.modalBackground};
  }

  .inputField {
    box-shadow: ${({ theme }) => theme.fieldBoxShadow};
  }

  .camera-popup__opt-btns .btn-primary {
    background: ${({ theme }) => theme.buttonWithoutBg};
  }
  
  .rs-picker-default .rs-picker-toggle {
    background: ${({ theme }) => theme.card}!important;
  }

  .rs-stack-item, .rs-calendar-time-view .rs-calendar-time-dropdown {
    background: ${({ theme }) => theme.card};
  }

  .rs-calendar-time-dropdown-column-title {
    background: ${({ theme }) => theme.card2}!important;
  }

  .css-1r87cm-MuiDivider-root::before, .css-1r87cm-MuiDivider-root::after {
    background: ${({ theme }) => theme.textGrey}!important;
  }
`;