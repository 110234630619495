import React from "react";
import { ButtonPrimary } from "../components/ButtonsPrimary";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

function PageNotFound({ history }) {
    return (
        <React.Fragment>
            <div align="center" style={{ marginTop: "100px" }}>
                <h1 style={{ fontSize: "84px" }}>404</h1>
                <h2 style={{ fontSize: "44px" }}>Page not found!</h2>
                <br />
                <br />
                <br />
                <ButtonPrimary
                    onClick={ ()=> history.push("/projects")  
                    }
                    backgroundColor="#ffff"
                    color="#000000"
                    title= 'Go to All Projects'
                />
            </div>
        </React.Fragment>
    );
}


PageNotFound.propTypes = {
    history: PropTypes.any,
};

export default withRouter( PageNotFound);