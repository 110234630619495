import React, { useState } from "react";
import { login } from "../service/AuthService";
import { SignIn } from "aws-amplify-react";
import { API, graphqlOperation } from "aws-amplify";
import { listUsers } from "../graphql/queries";
import { updateUser } from "../graphql/mutations";
import Card from "./Card";
import { TextInput } from "./TextInput";
import { ButtonBlue } from "./ButtonBlue";
import "../styles/components/login.scss";
import { Image, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { I18nProvider, LOCALES } from "../i18n";
import translate from "../i18n/translate";

const Login = ({  user, setToRender }) => {
    const [ state, setState ] = useState({
        username: "",
        password: "",
        error: "",
        loader: false,
        forgotPassword: false,
        usernameSubmit: false,
    });

    const onChange = (e) =>
        setState({ ...state, [ e.target.name ]: e.target.value, error: false });

    const loginSubmit = (e) => {
        e.preventDefault();
        let { username, password } = state;
        username = username.toLowerCase().trim();
        setState({ ...state, loader: true });
        login(username, password)
            .then(async (res) => {
                API.graphql(graphqlOperation(listUsers, {
                    filter: {
                        username: {
                            eq: username
                        }
                    },
                    limit: 10000
                }))
                    .then(async({ data: { listUsers: { items } } }) => {
                        if (!items[ 0 ].status) {
                            await API.graphql(graphqlOperation(updateUser, { input: { id: items[ 0 ].id, status: true } }));
                        }
                        setState({ ...state, loader: false });
                        if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
                            setToRender(3);
                            user(res);
                        } else if (res.challengeName === "CUSTOM_CHALLENGE") {
                            setToRender(4);
                            user(res);
                        } else {
                            window.location.replace("/");
                        }
                    })
                    .catch(() => {
                        setState({ ...state, loader: false });
                        if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
                            setToRender(3);
                            user(res);
                        } else if (res.challengeName === "CUSTOM_CHALLENGE") {
                            setToRender(4);
                            user(res);
                        } else {
                            window.location.replace("/");
                        }
                    });
                
            })
            .catch((err) => {
                setState({ ...state, error: err.message, loader: false });
            });
    };


    const { username, password, loader, error } = state;
    let [ locale ] = useState(LOCALES.EN);
    const language = localStorage.getItem("language");
    if (language === "EN") {
        locale = LOCALES.EN;
    }
    if (language === "DE") {
        locale = LOCALES.DE;
    }
    if (language === "NL") {
        locale = LOCALES.NL;
    }
    return (
        <I18nProvider locale={locale}>
            <div className="login-container">
                <Col md={7} sm={12} xs={12} lg={5}>
                    <Card>
                        <Image
                            src={require("../assets/images/logos/BAUCAMERA.png")}
                            alt="baucamera-logo"
                            className="login-container__baucamera-logo"
                        />

                        <h2 className="login-container__title">{translate("login-in-service")}</h2>
                        <form onSubmit={loginSubmit} className="login-container__login-form">
                            <TextInput
                                label={translate("username")}
                                value={username || ""}
                                placeholder=""
                                type="text"
                                onChange={onChange}
                                name="username"
                                required
                            />
                            <TextInput
                                label={translate("password")}
                                placeholder=""
                                type="password"
                                formText={translate("forgot-password")}
                                name="password"
                                onChange={onChange}
                                value={password}
                                newComponent={() => setToRender(2)}
                                required
                            />
                            <ButtonBlue title="Login" type="submit" loader={loader} />
                        </form>

                        <span className="login-container__error">{error ? error : null}</span>
                    </Card>
                </Col>
            </div>
        </I18nProvider>
    );
};

Login.propTypes = {
    user: PropTypes.any,
    setToRender:PropTypes.func,
};

export default class LogIn extends SignIn {
    render() {
        return (
            <Login  user={this.props.user}  setToRender ={this.props.setToRender} />
        );
    }
}