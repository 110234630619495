import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import { MdContentCopy } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import Switch from "react-switch";
import PropTypes from "prop-types";
import { getCamera, getCameraFirstImageDateTime } from "../../helpers/manualQueries";
import "../../styles/pages/Cams/cams-popup.scss";
import { Auth, API, graphqlOperation } from "aws-amplify";
import translate from "../../i18n/translate";
import greyCamera from "../../assets/images/greycam.png";
import greyCameraDark from "../../assets/images/greycam-dark.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Storage } from "aws-amplify";
import { updateCamera } from "../../graphql/mutations";
import { onUpdateCamera } from "../../graphql/subscriptions";
import moment from "moment";
import { PopupNavBtns } from "../../components/PopupNavBtns";
import { SeekBtns } from "../../components/SeekBtn";
import MobileCloseBtn from "../../components/MobileCloseBtn";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Timeline from "../../components/Timeline";
import ActionSnackbar from "../../components/ActionSnackbar";
import { RiArrowDownLine } from "react-icons/ri";
import WeatherData from "../../components/WeatherData";

Storage.configure({
    customPrefix: {
        public: "",
    },
});

const SingleProjectImagePopup = ({ open, onHide, id, rmsLink }) => {
    const [ type, setType ] = useState(false);
    const cameraDataDepFunc = useRef({});
    const [ Role, setRole ] = useState("");
    const [ showPreviousImage, setShowPreviouseImage ] = useState(false);
    const [ cameraData, setCameraData ] = useState({});
    const [ camImages, setCamImages ] = useState([]);
    const [ imageUri, setImageUri ] = useState(greyCamera);
    const passwordRef = useRef({});
    const [ isUsingTimeline, setIsUsingTimeline ] = useState(false);
    const onUpdateCameraSubscription = useRef();
    const [ imageIndex, setImageIndex ] = useState(0);
    const [ tlExpanded, setTlExpanded ] = useState(false);
    const [ snackbarOpen, setSnackbarOpen ] = useState(false);
    const [ snackbarData, setSnackbarData ] = useState([]);
    const [ nextToken, setNextToken ] = useState(null);
    const [ dateBoundaries, setDateBoundaries ] = useState([]);
    const [ theme, setTheme ] = useState("light");

    useEffect(() => {
        setTheme(window.localStorage.getItem("theme"));
    }, [ window.localStorage.getItem("theme") ]);

    useEffect(() => {
        if (theme === "dark") {
            setImageUri(greyCameraDark);
        } else {
            setImageUri(greyCamera);
        }
    }, [ theme ]);

    useEffect(() => {
        getRole();
    }, [ open ]);

    const image = camImages[ imageIndex ];
    const s3ObjectPathThumbnail = camImages[ imageIndex ]?.s3ObjectPathThumbnail;
    const imageDateTime = camImages[ imageIndex ]?.datetime;
    const datetime = camImages.length
        ? camImages[ showPreviousImage ? 1 : 0 ]?.datetime
        : "";
    const cameraIDs = cameraData?.project?.cameraIDs;
    const cameraAssignedDate = cameraIDs
        ? JSON.parse(cameraIDs)[ cameraData?.id ][ 0 ]
        : null;

    const cameraDataDep = () => {
        if (cameraAssignedDate < imageDateTime ) {
            if (image?.s3ObjectPathThumbnail === null) {

                const previousImage = camImages[ 1 ];
                Storage.get(previousImage?.s3ObjectPathThumbnail).then(
                    (res) => {
                        setImageUri(res);
                        setShowPreviouseImage(true);
                    }
                );

            } else {

                Storage.get(image?.s3ObjectPathThumbnail).then((res) => {
                    setImageUri(res);
                    setShowPreviouseImage(false);
                });
            }
        }
    };

    cameraDataDepFunc.current = cameraDataDep;

    useEffect(() => {
        cameraDataDepFunc.current();
    }, [ s3ObjectPathThumbnail, imageDateTime ]);


    const getRole = async () => {
        const userDetails = await Auth.currentAuthenticatedUser();
        const role =
            userDetails?.signInUserSession?.accessToken?.payload[
                "cognito:groups"
            ][ 0 ];
        setRole(role.toString().toLowerCase());
    };

    const tlExpandHandler = () => {
        if (tlExpanded) {
            setTlExpanded(false);
        } else {
            setTlExpanded(true);
        }
    };

    const resetToken = () => {
        setCamImages([]);
        getCameraData();
        setImageIndex(0); 
    };

    const getBoundaries = async () => { // replaces getfisrtimagedatetime
        const now = new Date();
        try {
            await API.graphql(
                graphqlOperation(getCamera, {
                    id,
                    sortDirection: "DESC",
                    limit: 1
                })
            ).then((camera) => {
                const data = camera.data.getCamera;
                const obj = (data?.project?.cameraIDs ? JSON.parse(data?.project?.cameraIDs) : {});
                for (let i = 0; i <= 1; i++) {
                    API.graphql(graphqlOperation(getCameraFirstImageDateTime, {
                        id,
                        limit: 1,
                        sortDirection: (i === 0 ? "ASC" : "DESC"),
                        datetime: {
                            between: [ obj[ id ]?.[ 0 ], moment(now).toISOString(true) ],
                        },
                    })
                    ).then((response) => {
                        const date = response?.data?.getCamera?.imagesByDate?.items[ 0 ]?.datetime;
                        setDateBoundaries(current => [ ...current, date ]); // index 0 -> earliest image, index 1 -> latest image
                    });
                }
            });
        } catch (err) {
            throw err;
        }
    };
    
    const sortedDateBoundaries = dateBoundaries.sort((a, b) => a > b ? 1 : -1);

    useEffect(() => {
        getBoundaries();
    }, []); // Call once on open and should also add dependency to call when a new image is avaiable

    useEffect(() => {
        getCameraData();
    }, [ dateBoundaries ]);

    useEffect(() => {
        const intervalForCamera = setInterval(() => {
            if (imageIndex === 0) {
                getCameraData();  
            }
        }, 60000);
        return () => clearInterval(intervalForCamera);
    }, [ imageIndex, dateBoundaries ]);

    const getCameraData = async () => {
        if (dateBoundaries.length === 2) {
            try {
                setIsUsingTimeline(false);
                const camera = await API.graphql(
                    graphqlOperation(getCamera, {
                        id,
                        sortDirection: "DESC",
                        nextToken: null,
                        limit: 100,
                        datetime: {
                            between: [ sortedDateBoundaries[ 0 ], moment(new Date()).toISOString(true) ]
                        },
                    })
                );
                const data = camera.data.getCamera;
                setCameraData(data);
                setCamImages(data.imagesByDate?.items);
                setNextToken(data.imagesByDate?.nextToken); 
                passwordRef.current.value = data?.password;
            } catch (err) {
                throw err;
            } 
        }
    };

    const getTimelineImages = async (val1, val2) => {
        setIsUsingTimeline(true);
        const camera = await API.graphql(
            graphqlOperation(getCamera, {
                id,
                sortDirection: "DESC",
                limit: 500,
                datetime: {
                    between: [ val1, val2 ],
                },
            })
        );

        const data = camera.data.getCamera;
        setCameraData(data);
        setCamImages(data.imagesByDate?.items);
        setImageIndex(Math.round(data.imagesByDate?.items.length * 0.5));
        setNextToken(null);
    };

   
    const loadMoreImages = async () => {
        try {
            const camera = await API.graphql(
                graphqlOperation(getCamera, {
                    id,
                    sortDirection: "DESC",
                    nextToken,
                    limit: 100,
                    dateTime: sortedDateBoundaries
                })
            );
            const data = camera.data.getCamera;
            setCamImages(currentImages => [ ...currentImages, ...data.imagesByDate?.items ]);
            setNextToken(data.imagesByDate?.nextToken);
        } catch (err) { throw (err); }
    };


    const handleRegenerateCredential = async (e) => {
        e.preventDefault();
        try {
            const camera = { id: id, changePassword: true };
            await API.graphql(
                graphqlOperation(updateCamera, { input: camera })
            );
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        onUpdateCameraSubscription.current = API.graphql(
            graphqlOperation(onUpdateCamera)
        ).subscribe({
            next: (updateCameraData) => {
                const updatedCamera =
                    updateCameraData.value.data.onUpdateCamera;
                setCameraData((previousData) => {
                    if (previousData.id === updatedCamera.id) {
                        return updatedCamera;
                    } else return previousData;
                });
                setType(false);
            },
        });
        return () => onUpdateCameraSubscription.current.unsubscribe();
    }, []);

    const onClose = () => {
        setCameraData({});
        onHide();
    };


    const handleDownloadImage = () => {
        const path = (image?.s3ObjectPathBlurred ? image.s3ObjectPathBlurred : image?.s3ObjectPathOriginal);
        Storage.get(path, { download: true }).then(
            (result) => {
                let mimeType = result.ContentType;
                let fileName =
                    cameraData.cameraName +
                    "-" +
                    moment(datetime).format("YYYY-MM-DD") +
                    "_" +
                    (parseFloat(moment(datetime).format("HH.mm")) - 2.0) +
                    ".jpg";
                try {
                    let blob = new Blob([ result.Body ], { type: mimeType });

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        let objectUrl = URL.createObjectURL(blob);
                        let link = document.createElement("a");
                        link.href = objectUrl;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                } catch (exc) {
                    throw exc;
                }
            }
        );
    };

    const updateHideCopyLink = async () => {
        const hideCopyLink = cameraData.hideCopyLink ? false : true;
        try {
            const camera = { id: id, hideCopyLink };
            await API.graphql(
                graphqlOperation(updateCamera, { input: camera })
            );
        } catch (err) {
            throw err;
        }
    };
    const setTime = imageDateTime?.split("T")[ 1 ];

    const nextImageHandler = () => {
        if (!isUsingTimeline) {
            if (imageIndex + 1 < camImages.length) {
                setImageIndex(imageIndex + 1);
            }
            if (imageIndex + 1 === camImages.length - 1) {
                loadMoreImages();
            }
        } else {
            if (imageIndex + 1 < camImages.length) {
                setImageIndex(imageIndex + 1);
            }
        }
    };

    const previousImageHandler = () => {
        if (imageIndex != 0) {
            setImageIndex(imageIndex - 1);
        }
    };

    const [ isImageExpanded, setIsImageExpandedTo ] = useState(false);
    const [ currentClass, setCurrentClassTo ] = useState("camera-popup");
    const expandHandler = () => {
        if (isImageExpanded) {
            setIsImageExpandedTo(false);
        } else {
            setIsImageExpandedTo(true);
        }
    };
    useEffect(() => {
        if (isImageExpanded) {
            setCurrentClassTo("camera-popup-exp");
        } else {
            setCurrentClassTo("camera-popup");
        }
    }, [ isImageExpanded ]);

    const handleSnackbarOpen = (hasLink) => {
        if (hasLink) {
            const message = translate("successfully copied image link");
            setSnackbarData([ message, "info" ]);
        } else {
            const message = translate("no image availabe");
            setSnackbarData([ message, "warning" ]);
        }
        setSnackbarOpen(!snackbarOpen);
    };

    const onCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    const dateFormatConvert = () => {
        var getDate = imageDateTime.slice(0, 10).split("-"); //create an array
        var newFormat = getDate[ 2 ] + "." + getDate[ 1 ] + "." + getDate[ 0 ];
        return newFormat;
    };

    return (
        <Modal
            className={currentClass}
            show={open}
            onHide={() => onClose()}
            centered
        >
            <ActionSnackbar openBool={snackbarOpen} message={snackbarData[ 0 ]}
                severity={snackbarData[ 1 ]} onCloseSnackbar={onCloseSnackbar} />
            <div className='camera-popup__head'>
                <div className='d-flex'>
                    <h5>
                        {Role === "admin" || Role === "crew"
                            ? cameraData.cameraName
                            : cameraData.cameraAlias}
                    </h5>
                    <div className='camera-popup__date-time'>
                        <FaRegCalendarAlt size={14} color='#1181F2' />
                        {imageDateTime ? (
                            <p>{`${dateFormatConvert()} | ${setTime?.substring(0, 5)}`}</p>
                        ) : (
                            <p>--.--.---- | --:--</p>
                        )}
                    </div>
                </div>
            </div>      
            <MobileCloseBtn
                size={30}
                onClick={onClose}
                className="mobile-close-button"
                horizontalOffset={11}
            />
            <WeatherData 
                weatherKey={cameraData?.project?.weather_key}
                imageDateTime={imageDateTime}
            />
            <div style={{ height: 5 }}>
                <PopupNavBtns
                    onClickFullscreen={expandHandler}
                    archive={false}
                    onClickSeek={() => onClose()}
                ></PopupNavBtns>
            </div>
           
            <SeekBtns
                nextImg={nextImageHandler}
                prevImg={previousImageHandler}
                imageURIState={imageUri}
                imgP={theme === "dark" ? greyCameraDark : greyCamera}
                i={imageIndex}
                camDate={cameraAssignedDate}
                dateTime={imageDateTime}
                cameraData={cameraData}
                camImages={camImages}
                inverted={true}
                thumbnail={s3ObjectPathThumbnail}
                cameraIDs={cameraIDs}
                Role={Role}
            />

            {(Role !== "clientcamonly" ? (
               
                <div> <div className="container-button">
                    <button
                        onClick={tlExpandHandler}
                        className="accor-btn">
                        {(tlExpanded ?
                            <FaCaretUp color="#1181F2" size={20}></FaCaretUp> :
                            <FaCaretDown color="#1181F2" size={20} ></FaCaretDown>)}
                    </button>
                </div>
                <Accordion sx={{
                    backgroundColor: "transparent", borderRadius: "18px !IMPORTANT", padding: 0, margin: 0
                }} expanded={tlExpanded}>
                    <AccordionSummary
                        sx={{
                            backgroundColor: "transparent", padding: 0, margin: 0, border: "1px solid black", display: "none"
                        }}

                        aria-controls="panel1a-content"
                        id="panel1a-header"

                    >
                    </AccordionSummary>
                    <AccordionDetails sx={{ margin: 0, padding: 0, backgroundColor: "transparent", borderRadius: "18px" }}>
                        <Timeline
                            dateBoundaries={sortedDateBoundaries}
                            getTimelineImages={getTimelineImages}
                            Role={Role}
                            resetToken={resetToken}
                        />
                    </AccordionDetails>
                </Accordion>
                </div>
            ) : 
                null)}
            {(Role === "admin" || Role === "crew") && (
                <div className='camera-popup__btn-sec'>
                    <h6 className='truncate'>{cameraData.cameraName}</h6>
                    <div className='camera-popup__btn-sec--sub-sec'>
                        <span className='camera-popup__btn-sec--first-span'>
                            {rmsLink ? (
                                <p className='camera-popup__btn-sec--sub-sec-p'>
                                    FTP link: <p>{cameraData.ftpLink}</p>
                                    <br />
                                    RMS Link:{" "}
                                    <a
                                        href={cameraData.rmsLink}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                    >
                                        {cameraData.rmsLink}
                                    </a>
                                </p>
                            ) : (
                                <p>
                                    FTP link: <p>{cameraData.ftpLink}</p>
                                </p>
                            )}
                            <p></p>
                            <p>
                                {translate("username")}: {cameraData.username}
                            </p>
                        </span>
                        <span className='camera-popup__btn-sec--sec-span'>
                            <p>
                                {translate("password")}:{" "}
                                <input
                                    type={type ? "text" : "password"}
                                    ref={passwordRef}
                                />
                                {type ? (
                                    <BsEye
                                        onClick={() => {
                                            setType(false);
                                            getCameraData();
                                        }}
                                        size={20}
                                        color='#1181F2'
                                    />
                                ) : (
                                    <BsEyeSlash
                                        onClick={() => {
                                            setType(true);
                                            getCameraData();
                                        }}
                                        color='#1181F2'
                                        size={20}
                                    />
                                )}
                            </p>
                            <div className='d-flex'>
                                <p className='ml-0'>
                                    {translate("latest-picture-link")}:
                                </p>
                                <Switch
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onColor='#1181F2'
                                    checked={!cameraData.hideCopyLink}
                                    onChange={updateHideCopyLink}
                                />
                            </div>
                        </span>
                    </div>
                </div>
            )}

            <div className='camera-popup__opt-btns'>
                {(Role === "admin" ||
                    Role === "crew" ||
                    Role === "superuser") &&
                    !cameraData.hideCopyLink ? (
                        <CopyToClipboard text={cameraData.latestPictureLink}>
                            <ButtonPrimary
                                title={[
                                    <MdContentCopy
                                        key='copy'
                                        color='#1181f2'
                                        size={14}
                                    />,
                                    translate("copy-picture-link"),
                                ]}
                                type='submit'
                                onClick={() => { handleSnackbarOpen((cameraData.latestPictureLink ? true : false)); }}
                            />
                        </CopyToClipboard>
                    ) : null}
                <ButtonPrimary
                    title={
                        <>
                            <RiArrowDownLine
                                key='check'
                                color='#1181f2'
                                size={14}
                            />
                            {translate("download")}
                        </>
                    }
                    type='submit'
                    onClick={handleDownloadImage}
                />
                {(Role === "admin" || Role === "crew") && (
                    <ButtonPrimary
                        title={translate("regenerate-credentials")}
                        type='submit'
                        onClick={handleRegenerateCredential}
                    />
                )}
            </div>
        </Modal>
    );
};

SingleProjectImagePopup.propTypes = {
    open: PropTypes.bool,
    onHide: PropTypes.func,
    id: PropTypes.string,
    rmsLink: PropTypes.bool,
    projectId: PropTypes.string,
};

export default SingleProjectImagePopup;