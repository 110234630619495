import React, { Fragment } from "react";
import { IntlProvider } from "react-intl";
import { LOCALES } from "./locales";
import messages from "./messages";
import PropTypes from "prop-types";

const Provider = ({ children, locale = LOCALES.EN }) => ( <
    IntlProvider locale = { locale }
    textComponent = { Fragment }
    messages = { messages[ locale ] } > { children }

</IntlProvider>
);

Provider.propTypes = {
    children: PropTypes.node.isRequired,
    locale: PropTypes.string,
};

export default Provider;
