import React, { useState, useEffect, useRef } from "react";
import { TextInput } from "../../components/TextInput";
import { Row, Col, Modal } from "react-bootstrap";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import PropTypes from "prop-types";
import { API, graphqlOperation } from "aws-amplify";
import { createCamera, createEvent } from "../../graphql/mutations";
import { updateCamera } from "../../helpers/manualMutations";
import { getCamera } from "../../graphql/queries";
import "../../styles/pages/camera/createEditCamera.scss";
import { Auth, Storage } from "aws-amplify";
import translate from "../../i18n/translate";
import { BiX } from "react-icons/bi";
import { VscNewFile } from "react-icons/vsc";
import ReactTooltip from "react-tooltip";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const CreateEditCamera = ({ onHide, modal }) => {
    const { editCamera, show, id } = modal;
    const [ state, setState ] = useState({
        cameraName: "",
        liveStream: false,
        liveStreamLink: "",
        rmsLink: "",
        blurringEnabled: false,
        blurringProEnabled: false,
        maskEnabled: false,
        timelapseEnabled: false,
        lastDayTimelapseStatus: "not created",
        lastWeekTimelapseStatus: "not created",
        lastMonthTimelapseStatus: "not created",
        externalCamera: false,
        protocol: "ftps",
        testMode: false,
    });
    const [ editCameraId, setEditCameraId ] = useState("");
    const [ selectedFile, setSelectedFile ] = useState(null);
    const [ loadedFile, setLoadedFile ] = useState(false);
    const [ error, setError ] = useState("");
    const [ loader, setLoader ] = useState(false);
    const events = {
        log: "",
        user: Auth.user.username,
        expire: "",
        logType: "camera"
    };
    const tempfetchProjectFunc = useRef();

    let now = (Date.now() + 15552000000) / 1000;
    events.expire = Math.round(now);

    const onChange = (e) => {
        setError("");
        setState({ ...state, [ e.target.name ]: e.target.value });
    };

    const tempUseEffect = () => {
        if (editCamera) {
            API.graphql(
                graphqlOperation(getCamera, {
                    id,
                })
            )
                .then(({ data: { getCamera } }) => {
                    setState({
                        ...state,
                        protocol: getCamera?.protocol === "ftp" ? "ftp" : "ftps",
                        cameraName: getCamera?.cameraName,
                        testMode: getCamera?.testMode,
                        liveStream: getCamera?.liveStream
                            ? getCamera?.liveStream
                            : false,
                        liveStreamLink: getCamera?.liveStreamLink,
                        rmsLink: getCamera?.rmsLink,
                        blurringEnabled: getCamera?.blurringEnabled
                            ? getCamera?.blurringEnabled
                            : false,
                        blurringProEnabled: getCamera?.blurringProEnabled
                            ? getCamera?.blurringProEnabled
                            : false,
                        maskEnabled: getCamera?.maskEnabled
                            ? getCamera?.maskEnabled
                            : false,
                        timelapseEnabled: getCamera?.timelapseEnabled 
                            ? getCamera?.timelapseEnabled 
                            : false,
                        externalCamera: getCamera?.externalCamera
                            ? getCamera?.externalCamera
                            : false,
                    });
                    setEditCameraId(getCamera?.id);
                })
                .catch(({ errors }) => {
                    setError(errors[ 0 ]?.message);
                });
        }
    };
    tempfetchProjectFunc.current = tempUseEffect;

    useEffect(() => {
        tempfetchProjectFunc.current();
    }, [ editCamera ]);

    const onSubmit = (e) => {
        e.preventDefault();
        const {
            cameraName,
            liveStreamLink,
            liveStream,
            rmsLink,
            blurringEnabled,
            blurringProEnabled,
            maskEnabled,
            timelapseEnabled,
            lastDayTimelapseStatus,
            lastWeekTimelapseStatus,
            lastMonthTimelapseStatus,
            externalCamera,
            protocol,
            testMode,
        } = state;
        setLoader(true);
        if (!editCamera) {
            if (!/-|\s/g.test(cameraName.toString())) {
                API.graphql(
                    graphqlOperation(createCamera, {
                        input: {
                            id: cameraName,
                            cameraName,
                            liveStreamLink,
                            liveStream,
                            rmsLink,
                            changePassword: false,
                            hideCopyLink: false,
                            blurringEnabled,
                            blurringProEnabled,
                            maskEnabled,
                            timelapseEnabled,
                            lastDayTimelapseStatus,
                            lastWeekTimelapseStatus,
                            lastMonthTimelapseStatus,
                            externalCamera,
                            protocol,
                            testMode,
                        },
                    })
                )
                    .then(() => {
                        try {
                            events.log =
                                "A camera " +
                                state.cameraName +
                                " has been created";
                            const event = { ...events };
                            API.graphql(
                                graphqlOperation(createEvent, { input: event })
                            );

                            if (loadedFile && state.maskEnabled) {
                                addPhoto();
                            }
                        } catch (err) {
                            throw err;
                        }
                        onClose();
                    })
                    .catch(({ errors }) => {
                        setLoader(false);
                        setError(errors[ 0 ]?.message);
                    });
            } else {
                setLoader(false);
                setError(
                    "Camera name should not contain white spaces and hyphens"
                );
            }
        } else {
            API.graphql(
                graphqlOperation(updateCamera, {
                    input: {
                        liveStreamLink,
                        liveStream,
                        rmsLink,
                        protocol,
                        id: editCameraId,
                        blurringEnabled,
                        blurringProEnabled,
                        maskEnabled,
                        testMode,
                        timelapseEnabled,
                        lastDayTimelapseStatus: !timelapseEnabled ? "not created" : lastDayTimelapseStatus,
                        lastWeekTimelapseStatus:!timelapseEnabled ? "not created" : lastWeekTimelapseStatus,
                        lastMonthTimelapseStatus: !timelapseEnabled ? "not created" : lastMonthTimelapseStatus,
                        externalCamera,
                    },
                    sortDirection: "DESC",
                })
            )
                .then(() => {
                    if (loadedFile && state.maskEnabled) {
                        addPhoto();
                    }
                    onClose();
                    setLoader(false);
                })
                .catch(({ errors }) => {
                    setLoader(false);
                    setError(errors[ 0 ]?.message);
                });
        }
    };

    const addPhoto = async () => {
        if (!selectedFile) {
            return alert("Please choose a file to upload first.");
        } else if (selectedFile.type !== "image/png") {
            return alert("The image must be a file of type: PNG.");
        } else {
            const fileName = state.cameraName + ".png";
            try {
                await Storage.put(`masks/${fileName}`, selectedFile, {
                    contentType: "image/png",
                });
            } catch (error) {
                return alert("Error uploading file: ", error);
            }
        }
    };

    const onClose = () => {
        setState({
            cameraName: "",
            liveStream: false,
            liveStreamLink: "",
            rmsLink: "",
            blurringEnabled: false,
            blurringProEnabled: false,
            maskEnabled: false,
            timelapseEnabled: false,
            lastDayTimelapseStatus: "not created",
            lastWeekTimelapseStatus: "not created",
            lastMonthTimelapseStatus: "not created",
            externalCamera: false,
            protocol: "ftps",
            testMode: false,
        });
        setLoader(false);
        setError("");
        onHide();
    };

    return (
        <div className='createEditCamera'>
            <Modal
                className='createEditCamera__modal'
                show={show}
                onHide={() => onClose()}
                centered
            >
                <div className='createEditCamera__close-btn'>
                    <BiX
                        key={2}
                        color='#1181F2'
                        size={30}
                        onClick={onClose}
                        className='createEditCamera__cross-image'
                    />
                </div>
                <h2>
                    {editCamera
                        ? translate("edit-camera")
                        : translate("create-new-camera")}
                </h2>
                <span className='text-center text-danger'>{error}</span>
                <form onSubmit={onSubmit}>
                    <Row>
                        <p className="pl-4" style={{ fontSize: 14 }}>Protocol</p>
                    </Row>
                    <Row>
                        <Col>
                            <div className='createEditCamera__radioBtn'>
                                <Checkbox 
                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                                    value={state.protocol}
                                    onChange={() => 
                                        setState({
                                            ...state,
                                            protocol: "ftps",
                                        })
                                    }
                                    checked={state.protocol !== "ftp"}
                                />
                                <label
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            protocol: "ftps",
                                        });
                                    }}
                                    className='form-check-label'
                                    htmlFor=' FTPS'
                                >
                                    FTPS
                                </label>
                            </div>
                        </Col>
                        <Col>
                            <div className='createEditCamera__radioBtn'>
                                <Checkbox 
                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                                    value={state.protocol}
                                    onChange={() => 
                                        setState({
                                            ...state,
                                            protocol: "ftp",
                                        })
                                    }
                                    checked={state.protocol === "ftp"}
                                />
                                <label
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            protocol: "ftp",
                                        });
                                    }}
                                    className='form-check-label'
                                    htmlFor='FTP'
                                >
                                    FTP
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="testmode-section">
                            <FormControlLabel
                                checked={state.testMode}
                                onChange={() => setState({ ...state, testMode: !state.testMode })}
                                control={
                                    <Switch name="gilad" />
                                }
                                label={translate("test-mode")}
                            />
                        </div>
                    </Row>
                    
                    <Row>
                        <Col>
                            <TextInput
                                type='text'
                                value={state.cameraName}
                                label={translate("camera-name")}
                                name='cameraName'
                                required={true}
                                onChange={onChange}
                                disabled={editCamera ? true : false}
                            />
                        </Col>
                    </Row>
                 
                    <Row>
                        <Col>
                            <div className='createEditCamera__radioBtn'>
                                <Checkbox 
                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                                    value={state.externalCamera}
                                    onChange={(e) => 
                                        setState({
                                            ...state,
                                            externalCamera: e.target.checked,
                                        })
                                    }
                                    checked={state.externalCamera}
                                />
                                <label
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            externalCamera: !state.externalCamera,
                                        });
                                    }}
                                    className='form-check-label'
                                    htmlFor='externalCamera'
                                >
                                    {translate("external-camera")}
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='createEditCamera__radioBtn'>
                                <Checkbox 
                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                                    value={state.liveStream}
                                    onChange={(e) => 
                                        setState({
                                            ...state,
                                            liveStream: e.target.checked,
                                        })
                                    }
                                    checked={state.liveStream}
                                />
                                <label
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            liveStream: !state.liveStream,
                                        });
                                    }}
                                    className='form-check-label'
                                    htmlFor='live stream'
                                >
                                    Live stream
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInput
                                type='text'
                                value={state.liveStreamLink}
                                label='Live-stream link'
                                name='liveStreamLink'
                                required={true}
                                disabled={!state.liveStream}
                                onChange={onChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInput
                                type='text'
                                value={state.rmsLink}
                                label='RMS Link'
                                name='rmsLink'
                                onChange={onChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        
                        <Col>
                            <div className='createEditCamera__radioBtn'>
                                <Checkbox 
                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                                    value={state.timelapseEnabled}
                                    onChange={(e) => 
                                        setState({
                                            ...state,
                                            timelapseEnabled: e.target.checked,
                                        })
                                    }
                                    checked={state.timelapseEnabled}
                                />
                                <label
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            timelapseEnabled:
                                                !state.timelapseEnabled,
                                        });
                                    }}
                                    className='form-check-label'
                                    htmlFor='live stream'
                                >
                                    {translate("timelapse-enabled")}
                                </label>
                            </div>
                         
                        </Col>
                      
                    </Row>
                    <Divider textAlign="left"><span style={{ fontFamily: "Intersate-Bold", fontSize: "14px" }}><p>Blurring</p></span></Divider>
                    <Row>
                        <Col>
                            <div className='createEditCamera__radioBtn'>
                                <Checkbox 
                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                                    value={state.blurringEnabled}
                                    onChange={(e) => 
                                        setState({
                                            ...state,
                                            blurringEnabled:
                                                e.target.checked,
                                        })
                                    }
                                    checked={state.blurringEnabled}
                                    disabled={state.blurringProEnabled}
                                />
                                <label
                                    onClick={() => {
                                        if (!state.blurringProEnabled) {
                                            setState({
                                                ...state,
                                                blurringEnabled:
                                                !state.blurringEnabled,
                                            }); 
                                        }
                                    }}
                                    className='form-check-label'
                                    htmlFor='live stream'
                                >
                                    {translate("blurring-enabled")}
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='createEditCamera__radioBtn'>
                                <Checkbox 
                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                                    value={state.blurringProEnabled}
                                    onChange={(e) => 
                                        setState({
                                            ...state,
                                            blurringProEnabled:
                                                e.target.checked,
                                            blurringEnabled: false,
                                        })
                                    }
                                    checked={state.blurringProEnabled}
                                />
                                <label
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            blurringProEnabled:
                                                !state.blurringProEnabled,
                                            blurringEnabled: false,
                                        });
                                    }}
                                    className='form-check-label'
                                    htmlFor='live stream'
                                >
                                    {translate("blurring-pro-enabled")}
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Divider textAlign="left"><span style={{ fontFamily: "Intersate-Bold", fontSize: "14px" }}><p>Masking</p></span></Divider>
                    <Row>
                        <Col>
                            <div className='createEditCamera__radioBtn'>
                                <Checkbox 
                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                                    value={state.maskEnabled}
                                    onChange={(e) => 
                                        setState({
                                            ...state,
                                            maskEnabled:
                                                e.target.checked,
                                        })
                                    }
                                    checked={state.maskEnabled}
                                />
                                <label
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            maskEnabled: !state.maskEnabled,
                                        });
                                    }}
                                    className='form-check-label'
                                    htmlFor='live stream'
                                >
                                    Masking enabled
                                </label>
                            </div>
                        </Col>
                    </Row>

                    {state.maskEnabled && (
                        <p data-tip='The mask should be the same dimension as the camera image.'>
                            <div className='px-5'>
                                <label
                                    className='createEditCamera__file'
                                    htmlFor='myFile'
                                >
                                    <p>Select PNG File</p>
                                    <VscNewFile size={24} />
                                    <input
                                        type='file'
                                        id='myFile'
                                        name='filename'
                                        onChange={(e) => {
                                            setSelectedFile(e.target.files[ 0 ]);
                                            setLoadedFile(true);
                                        }}
                                    />
                                </label>
                                {selectedFile && (
                                    <p className='createEditCamera__file-name'>
                                        <b>File:</b> {selectedFile.name}
                                    </p>
                                )}
                            </div>
                            <ReactTooltip
                                place='bottom'
                                type='dark'
                                effect='float'
                                border='true'
                            />
                        </p>
                    )}

                    <div className='createEditCamera__btn-div'>
                        <ButtonPrimary
                            title={
                                editCamera
                                    ? translate("save")
                                    : translate("add")
                            }
                            type='submit'
                            backgroundColor='#fff'
                            color='#000'
                            loader={loader}
                            loaderColour='#1181f2'
                        />
                    </div>
                </form>
            </Modal>
        </div>
    );
};

CreateEditCamera.propTypes = {
    onHide: PropTypes.func,
    modal: PropTypes.object,
};
export default CreateEditCamera;
