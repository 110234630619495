import React, { useEffect, useState, useRef } from "react";
import "../../styles/pages/companies/companies.scss";
import "../../styles/pages/historicalArchive/historicalArchive.scss";
import { deleteCompany, createEvent } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { Auth } from "aws-amplify";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AiOutlineFolder } from "react-icons/ai";
import Box from "@mui/material/Box";
import { FaEdit, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import translate from "../../i18n/translate";
import Loader from "../../components/Loader";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReactTooltip from "react-tooltip";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import { useIntl } from "react-intl";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    backgroundColor: "rgba(0, 0, 0, 0)",
    boxShadow: "none",
    color: theme.palette.text.secondary,
}));

const CompaniesTable = ({ isNavbarHidden, companies, openModalFunc, openModalDeleteFunc, addCompany, loader }) => {
    const mobileView = useMediaQuery("(max-width:570px");
    const [ boxIndex, setBoxIndex ] = useState(null);
    const [ boxDisplay, setBoxDisplay ] = useState("none");
    const projectBox = useRef(null);
    const [ boxMobileDisplay, setBoxMobileDisplay ] = useState("none");
    const [ addClass, setAddClass ] = useState(null);
    const [ selectedCompanies, setSelectedCompanies ] = useState([]);
    const intl = useIntl();
    const events = {
        log: "",
        user: Auth.user.username,
        expire: "",
        logType: "company"
    };

    let now = (Date.now() + 15552000000) / 1000;
    events.expire = Math.round(now);

    useEffect(() => {
        setAddClass((isNavbarHidden ? "navbar-hidden-box" : "box-users"));
    }, [ isNavbarHidden ]);


    useEffect(() => {
        document.body.addEventListener("click", handleClickOutside);
    }, []);

    function handleClickOutside(event) {
        if (projectBox.current && !projectBox.current.contains(event.target)) {
            setBoxIndex(null);
            setBoxDisplay("none");
        }
    };

    const handleSelectCompany = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedCompanies(prevState => [ ...prevState, value ]); 
        } else {
            setSelectedCompanies(prevState => prevState.filter(company => company !== value));
        }
        
    };

    const handleDeleteCompanies = () => {
        try {
            selectedCompanies.forEach((id) =>  API.graphql(graphqlOperation(deleteCompany, {
                input: {
                    id: id
                }
            }))
            );
            try {
                selectedCompanies.forEach((id) => {
                    events.log = "A company " + id + " has been deleted";
                    const event = { ...events };
                    API.graphql(graphqlOperation(createEvent, { input: event }));
                });
            } catch (err) {
                throw (err);
            }
            setSelectedCompanies([]);
        } catch (err) {
            throw (err);
        }
    };

    return (
        <div className="companies__table">
            <div className="companies__table-head">
                <div
                    className="col-lg-3 col-md-3 col-sm-3 col-3"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <div 
                        style={{  minWidth: "30px", height: "30px" }}
                        data-tip={selectedCompanies.length > 0 ?  intl.formatMessage({ id: "delete-selected-company" })  : ""}
                    >
                        {selectedCompanies.length > 0 && 
                        <DeleteForeverIcon 
                            sx={{ 
                                fontSize: "28px", 
                                cursor: "pointer",
                                marginRight: "5px",
                                color: "#1181f2"
                            }}
                            onClick={handleDeleteCompanies}
                        />
                        } 
                        
                    </div>{translate("company")}
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-3">{translate("number-of-cameras")}</div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-3">{translate("number-of-projects")}</div>
            </div>
            {(mobileView && selectedCompanies.length > 0)  &&  
            <>
                <DeleteForeverIcon 
                    sx={{ 
                        fontSize: "28px", 
                        cursor: "pointer",
                        marginRight: "5px",
                        color: "#1181f2"
                    }}
                    onClick={handleDeleteCompanies}
                />
                Delete selected companies
            </>}

            <div className={addClass}>
                <Box sx={{ width: "100%" }}>
                    {loader ?
                        <>
                            <Loader />
                        </> :
                        companies.length ? (companies.map((company, index) => {
                            var newArr = [];
                            if (company.projects.items.length) {
                                company.projects.items.map((project) => {
                                    return newArr.push(...project.cameras.items);
                                });
                            }

                            return (
                                <div className="companies__item row" key={company.id}>
                                    <div 
                                        className="col-lg-3 col-md-3 col-sm-3 col-3 companies__namebox"
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                    >
                                        <Checkbox  
                                            sx={{ "& .MuiSvgIcon-root": { fontSize: 24, color: "#1181f2" } }} 
                                            onChange={handleSelectCompany}
                                            value={company.id}
                                            className="MUI-checkbox"
                                            disabled={company.projects.items.length !== 0 ? true : false}
                                        />
                                        {company.name}
                                    </div>
                                    {mobileView ? <div className="col-md-2 col-sm-3 col-4">
                                        <div className="col-lg-3 col-sm-2 col-md-2  projects-box">
                                            <button
                                                className="projects-box__item-btn mx-0"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => { 
                                                    e.stopPropagation();
                                                    setBoxIndex(index);
                                                    setBoxDisplay("block");
                                                }}
                                            >
                                                {company.projects.items.length}
                                            </button>
                                            <div
                                                className="project-box__list"
                                                style={boxIndex === index ? { display: boxDisplay } : null}
                                                ref={projectBox}
                                            >
                                                {company.projects.items.map((item, i) => {
                                                    return (
                                                        <ul key={i}>
                                                            <Link to={`/projects/${item.id}`}>
                                                                <li>
                                                                    {item.name}
                                                                </li>
                                                            </Link>
                                                        </ul>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div> : <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                        {mobileView ? "Cams: " : null}{newArr.length}
                                    </div>}
                                    {mobileView ? <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                        {mobileView ? "Cams: " : null}{newArr.length}
                                    </div> :
                                        <div className="col-md-2 col-sm-3 col-2">
                                            <div className="col-lg-3 col-sm-2 col-md-2  projects-box">
                                                <button
                                                    className="projects-box__item-btn mx-0"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={(e) => { 
                                                        e.stopPropagation();
                                                        setBoxIndex(index);
                                                        setBoxDisplay("block");
                                                    }}
                                                >
                                                    {company.projects.items.length}
                                                </button>
                                                <div
                                                    className="project-box__list"
                                                    style={boxIndex === index ? { display: boxDisplay } : null}
                                                    ref={projectBox}
                                                >
                                                    {company.projects.items.map((item, i) => {
                                                        return (
                                                            <ul key={i}>
                                                                <Link to={`/projects/${item.id}`}>
                                                                    <li>
                                                                        {item.name}
                                                                    </li>
                                                                </Link>
                                                            </ul>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>}
                                    <div className="d-none user__item-edit">
                                        <button
                                            className="companies__item-btn companies__item-btn--mobile mx-0"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setBoxIndex(index);
                                                setBoxMobileDisplay("block");
                                            }}
                                        >
                                            <AiOutlineFolder color="#1181F2" size={20} />
                                                &nbsp;
                                            {company.projects.items.length}
                                        </button>
                                        <div
                                            className="companies__project-list--mobile col-md-5"
                                            style={boxIndex === index ? { display: boxMobileDisplay } : null}
                                            ref={projectBox}
                                        >
                                            {company.projects.items.map((project, i) => {
                                                return (
                                                    <ul key={i}>
                                                        <Link to={`/projects/${project.id}`}>
                                                            <li>
                                                                {project.name}
                                                            </li>
                                                        </Link>
                                                    </ul>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="d-none d-lg-block d-md-block d-sm-block 
                                        col-6 col-lg-4 col-md-4  col-sm-3">
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <Item sx={{ fontSize: 14 }}>
                                                {(addCompany ? 
                                                    <ButtonPrimary
                                                        onClick={() => openModalFunc(company.id)}
                                                        title={
                                                            <>
                                                                <FaEdit color="#1181F2" size={18} />{translate("edit")}
                                                            </>}
                                                    /> :   
                                                    <ButtonPrimary 
                                                        onClick={() => openModalFunc(company.id)} 
                                                        title={<>
                                                            <FaEdit
                                                                className="historicalArchive__item-btn-download-icon"
                                                                color="#1181F2"
                                                                size={22}
                                                            />{translate("edit")}
                                                        </>}
                                                    /> 
                                                )}
                                            </Item>
                                            <Item sx={{ fontSize: 14  }}> 
                                                {(addCompany ?
                                                    <ButtonPrimary 
                                                        onClick={() => openModalDeleteFunc(company.name, company.id)}
                                                        startIcon={
                                                            <>
                                                                <FaTimes color="#1181F2" size={22} />{translate("delete")}
                                                            </>
                                                        }
                                                    />
                                                    : 
                                                    <ButtonPrimary 
                                                        disabled={company.projects.items.length !== 0 ? true : false}
                                                        onClick={() => openModalDeleteFunc(company.name, company.id)} 
                                                        title={
                                                            <>
                                                                <FaTimes color="#1181F2" size={22} /> 
                                                                {translate("delete")}
                                                            </>
                                                        }
                                                    />)}
                                            </Item></Grid>
                                    </div>
                                </div>

                            );
                        })) : <div
                            style={{ width: "100%" }}
                        >
                            <h2 className="handle-empty-element">
                                {translate("no-companies")}
                            </h2>
                        </div>

                    }</Box>
            </div>
            <ReactTooltip
                place='top'
                type='dark'
                effect='float'
            />
        </div>);
}; 

export default CompaniesTable;

CompaniesTable.propTypes = {
    companies: PropTypes.any,
    isNavbarHidden: PropTypes.bool,
    openModalFunc: PropTypes.func,
    openModalDeleteFunc: PropTypes.func,
    addCompany: PropTypes.bool,
    loader: PropTypes.bool,
};