import React from "react";
import { Modal } from "react-bootstrap";
import { ButtonPrimary } from "./ButtonsPrimary";
import { BsCheck } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import PropTypes from "prop-types";
import "../styles/components/deleteModal.scss";
import translate from "../i18n/translate";

const DeleteModal = ({ open, onHide, deleteFunc, loader, error, name, canDelete, endProject }) => {
    return (
        <Modal className="delete-user" show={open.show} onHide={onHide} centered>
            {canDelete ?
                <div className = 'delete-user-heading'>
                    {name ? <h2 className = 'delete-user-heading1'>{name}</h2> :
                        <h2>{endProject ? endProject : translate("delete-ask")}</h2>}
                    <span className='text-center text-danger'>{error}</span>
                    <p>{open.name}</p>
                    <div className='delete-user__btn-div'>
                        <ButtonPrimary
                            disabled={loader}
                            title={[
                                <GrFormClose
                                    key='cancel'
                                    color='#D05E5E'
                                    size={16} />,
                                translate("no")
                            ]
                            }
                            type="submit"
                            backgroundColor="#fff"
                            color="#000"
                            onClick={onHide}
                        />
                        <ButtonPrimary
                            disabled={loader}
                            loader={loader}
                            loaderColour='#1181f2'
                            title={<><BsCheck key='check' color='#00B607' size={18} /> {translate("yes")}</>}
                            type="submit"
                            backgroundColor="#fff"
                            color="#000"
                            onClick={() => deleteFunc()}
                        />
                    </div>
                </div>
                :
                <div className='delete-user-heading'>
                    <h3><strong>{open.name}</strong></h3>
                    <p>{translate("cannot-delete-company")}</p>
                    <span className='text-center text-danger'>{error}</span>
                    <div className='delete-user__btn-div'>
                        <ButtonPrimary
                            disabled={loader}
                            title="Ok"
                            type="submit"
                            backgroundColor="#fff"
                            color="#000"
                            onClick={onHide}
                        />
                    </div>
                </div>
            }

        </Modal>
    );
};

DeleteModal.propTypes = {
    open: PropTypes.object,
    onHide: PropTypes.func,
    deleteFunc: PropTypes.func,
    loader: PropTypes.bool,
    error: PropTypes.string,
    name: PropTypes.object,
    canDelete: PropTypes.bool,
    endProject:PropTypes.any,
};

export default DeleteModal;
