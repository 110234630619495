import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { styled } from "@mui/material/styles";
import { Slider } from "@material-ui/core";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import opacity from "react-element-popper/animations/opacity";
import DatePicker from "react-multi-date-picker";
import "../styles/components/Timeline.scss";
import "../styles/pages/Cams/cams-popup.scss";
import { BsFillSkipEndFill, BsFillSkipStartFill } from "react-icons/bs";
import { useEffect } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useIntl } from "react-intl";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "rgba($color: #ffffff, $alpha: 0.3)",
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const muiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "#1181F2",
            },
            track: {
                color: "#1181F2",
            },
            rail: {
                color: "#1181F2",
            },
            valueLabel: {
                color: "#1181F2",
                fontWeight: "bolder",
                fontSize: 12,
                borderRadius: "0 !important",
            },
        },
    },
});

const Timeline = ({ dateBoundaries, getTimelineImages, resetToken, Role }) => {
    const [ date1, setDate1 ] = useState(null);
    const [ date2, setDate2 ] = useState(null);
    const [ lastImageDate, setLastImageDate ] = useState(null);
    const [ valueMonthYear, setValueMonthYear ] = useState(new Date());
    const [ valueSlider, setValueSlider ] = useState(new Date().getDate());
    const [ valueSlider2, setValueSlider2 ] = useState(29);
    const [ minDays, setMinDays ] = useState(1);
    const [ maxDays, setMaxDays ] = useState(32);
    const [ dateList, setDateList ] = useState([]);
    const moment = require("moment");
    const [ dateLabel, setDateLabel ] = useState(null);
    const [ scaleType, setScaleType ] = useState("default");
    const datePickerRef = useRef();
    const intl = useIntl();

    const fmt = (dateObj) => {
        return moment(dateObj).toISOString(true);
    };

    useEffect(() => {
        if (dateBoundaries?.length === 2) {
            setDate1(new Date(dateBoundaries[ 0 ]));
            setDate2(new Date(dateBoundaries[ 1 ]));
            setLastImageDate(new Date(dateBoundaries[ 1 ]));
        }
    }, [ dateBoundaries ]);

    useEffect(() => {
        if (date2) {
            setValueSlider(date2.getDate());
        }
    }, [ date2 ]);

    useEffect(() => {
        if (valueMonthYear && valueSlider) {
            dateHandler();
        }
    }, [ valueMonthYear, valueSlider ]);

    const dateHandler = () => {};

    useEffect(() => {
        if (dateBoundaries) {
            setValueMonthYear(new Date(dateBoundaries[ 1 ]));
        }
    }, [ dateBoundaries ]);

    const changeMonthYear = (newValue) => {
        setValueMonthYear(new Date(newValue));
        setScaleType("monthly");
    };

    const style =
    Role === "admin" || Role === "crew"
        ? { marginTop: 1, marginLeft: 0 }
        : { marginTop: 1, marginLeft: 4 };

    setTimeout(() => {
        if (
            +valueMonthYear.getMonth() === +date1?.getMonth() &&
      +valueMonthYear.getFullYear() === +date1?.getFullYear()
        ) {
            setMinDays(date1.getDate());
        } else {
            setMinDays(1);
        }
        if (
            +valueMonthYear.getMonth() === +date2?.getMonth() &&
      +valueMonthYear.getFullYear() === +date2?.getFullYear()
        ) {
            setMaxDays(date2.getDate());
        } else {
            const d = new Date(
                valueMonthYear.getFullYear(),
                valueMonthYear.getMonth(),
                0,
            );
            setMaxDays(d.getDate());
        }
    }, 500);

    useEffect(() => {
        if (
            +valueMonthYear.getMonth() === +date1?.getMonth() &&
      +valueMonthYear.getFullYear() === +date1?.getFullYear()
        ) {
            setMinDays(date1.getDate());
        } else {
            setMinDays(1);
        }
        if (
            +valueMonthYear.getMonth() === +date2?.getMonth() &&
      +valueMonthYear.getFullYear() === +date2?.getFullYear()
        ) {
            setMaxDays(date2.getDate());
        } else {
            const d = new Date(
                valueMonthYear.getFullYear(),
                valueMonthYear.getMonth(),
                0,
            );
            setMaxDays(d.getDate());
        }
    }, [ valueMonthYear ]);

    const changeValueSlider = (event, value) => {
        const monthYear = new Date(valueMonthYear);
        const a = monthYear.getFullYear();
        const b = monthYear.getMonth();
        const c = value;
        const realSelectedDate = new Date(a, b, c);

        getTimelineImages(
            fmt(realSelectedDate.setHours(0, 0, 0)),
            fmt(realSelectedDate.setHours(23, 59, 59)),
        );
        setValueSlider(value);
    };

    const skipToFirstDate = () => {
        getTimelineImages(
            dateBoundaries[ 0 ],
            fmt(new Date(dateBoundaries[ 0 ]).setHours(23, 59, 59)),
        );
        setValueSlider(date1.getDate());
        setValueMonthYear(new Date(dateBoundaries[ 0 ]));
        setScaleType("monthly");
    };

    const skipToLastDate = () => {
        resetToken();
        setValueSlider(date2.getDate());
        setValueMonthYear(new Date(dateBoundaries[ 1 ]));
        setScaleType("default");
        setValueSlider2(29);
    };

    useEffect(() => {
        if (lastImageDate) {
            const newArray = [];
            const endDate = lastImageDate;
            const firstDate = new Date(moment(endDate).subtract(29, "days").format("LL"));
            for (
                let date = endDate;
                date >= firstDate;
                date.setDate(date.getDate() - 1)
            ) {
                newArray.push(new Date(date));
                if (moment(date1).format("LL") === moment(new Date(date)).format("LL")) { break; }
            } 
            setDateList(dateList.concat(newArray.reverse()));  
            setDateLabel(moment(newArray[ newArray.length - 1 ]).format("DD.MM"));
        }
    }, [ lastImageDate ]);

    const changeValueSlider2 = (event, value) => {
        const label = dateList[ value ];
        setDateLabel(moment(label).format("DD.MM"));
        setValueSlider2(value);

        getTimelineImages(
            fmt(label.setHours(0, 0, 0)),
            fmt(label.setHours(23, 59, 59))
        );
    };

    return (
        <ThemeProvider theme={muiTheme}>
            <Box
                sx={{
                    flexGrow: 1,
                    borderRadius: "18px",
                    paddingRight: 1,
                    paddingLeft: 1,
                    paddingBottom: 1,
                    marginTop: 1,
                }}
                className="main-box"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    columns={18}
                >
                    <Grid item xs={8} sx={style}>
                        <div className="first-part">
                            <span style={{ cursor: "pointer" }}>
                                <span>&nbsp;</span>
                                <DatePicker
                                    value={valueMonthYear}
                                    onChange={changeMonthYear}
                                    minDate={date1}
                                    maxDate={date2}
                                    onlyMonthPicker
                                    ref={datePickerRef}
                                    render={
                                        <FaRegCalendarAlt
                                            size={18}
                                            color={"black"}
                                            onClick={() => datePickerRef.current.openCalendar()}
                                            color="#1181f2"
                                        />
                                    }
                                    animations={[ opacity() ]}
                                />

                                <span
                                    style={{ fontWeight: "bolder", marginLeft: 5 }}
                                    onClick={() => datePickerRef.current.openCalendar()}
                                >
                                    <span>&nbsp;</span>
                                    {moment(valueMonthYear).format("YYYY-MM")}
                                </span>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={8}>
                        <div className="second-part">
                            <button
                                onClick={() => skipToFirstDate()}
                                className="tl-btn-start"
                                style={false ? { opacity: 0.5, cursor: "not-allowed" } : null}
                                data-tip={intl.formatMessage({ id: "to-project-start" })}
                            >
                                <BsFillSkipStartFill size={25} />
                            </button>
                            <button
                                onClick={() => skipToLastDate()}
                                className="tl-btn-end"
                                style={
                                    false || false
                                        ? { opacity: 0.5, cursor: "not-allowed" }
                                        : null
                                }
                                data-tip={intl.formatMessage({ id: "to-latest-image" })}
                            >
                                <BsFillSkipEndFill size={25} />
                            </button>
                        </div>
                        <ReactTooltip
                            place='top'
                            type='dark'
                            effect='float'
                        />
                    </Grid>
                    {scaleType !== "default" ? <Grid item xs={19}>
                        <Item
                            sx={{
                                paddingRight: 3,
                                paddingLeft: 3,
                                paddingTop: 4.5,
                                height: "60px",
                                borderRadius: "18px",
                            }}
                            className="Slider"
                        >
                            <Slider
                                marks
                                size="small"
                                valueLabelDisplay="on"
                                value={valueSlider}
                                max={maxDays ? maxDays : 32}
                                min={minDays ? minDays : 1}
                                track={false}
                                onChange={changeValueSlider}
                            />
                        </Item>
                    </Grid>
                        : 
                        <Grid item xs={19}>
                            <Item
                                sx={{
                                    paddingRight: 3,
                                    paddingLeft: 3,
                                    paddingTop: 4.5,
                                    height: "60px",
                                    borderRadius: "18px",
                                }}
                                className="Slider"
                            >
                                <Slider
                                    value={valueSlider2}
                                    marks
                                    size="small"
                                    aria-label="medium"
                                    valueLabelDisplay="on"
                                    valueLabelFormat={dateLabel}
                                    max={dateList.length - 1}
                                    min={0}
                                    track={false}
                                    onChange={changeValueSlider2}
                                />
                            </Item>
                        </Grid>
                    }
                    
                  
                </Grid>
            </Box>
        </ThemeProvider>
    );
};
export default Timeline;

Timeline.propTypes = {
    cameraDataFMDateTime: PropTypes.string,
    cameraDataLMDateTime: PropTypes.string,
    imageCriteria: PropTypes.func,
    user: PropTypes.string,
    downloadFunc: PropTypes.func,
    Role: PropTypes.string,
    resetToken: PropTypes.func,
    dateBoundaries: PropTypes.array,
    getTimelineImages: PropTypes.func,
    resetToken: PropTypes.func,
};
