import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { API, graphqlOperation } from "aws-amplify";
import { Auth } from "aws-amplify";
import { listCompaniesByName } from "../../helpers/manualQueries";
import { onUpdateCompany } from "../../helpers/manualSubscriptions";
import { onCreateCompany, onDeleteCompany } from "../../graphql/subscriptions";
import { IoMdRefresh } from "react-icons/io";
import "../../styles/pages/historicalArchive/historicalArchive.scss";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import _ from "lodash";
import { IoMdSearch } from "react-icons/io";
import { deleteCompany, createEvent } from "../../graphql/mutations";
import AddEditCompany from "./AddEditCompany";
import DeleteUser from "../../components/DeleteModal";
import "../../styles/pages/companies/companies.scss";
import translate from "../../i18n/translate";
import CompaniesTable from "../Companies/CompaniesTable.jsx";

const Companies = ({ isNavbarHidden }) => {
    const addCompany = useMediaQuery("(max-width: 735px");
    const [ companies, setCompanies ] = useState([]);
    const [ deleteCompanyState, openDeleteCompany ] = useState({
        name: "",
        id: ""
    });
    const [ modal, openModal ] = useState({
        editCompany: false,
        show: false,
        id: ""
    });
    const [ filterValue, setFilterValue ] = useState("");
    const [ deleteLoader, setDeleteLoader ] = useState(false);
    const [ loader, setLoader ] = useState(false);
    const [ deleteError, setDeleteError ] = useState("");
    const [ canDelete, setCanDelete ] = useState(true);
    const onCreate = useRef();
    const onDelete = useRef();
    const onUpdate = useRef();
    const [ nextToken, setNextToken ] = useState(undefined);
    const [ nextNextToken, setNextNextToken ] = useState("");
    const [ previousTokens, setPreviousTokens ] = useState([]);
    const events = {
        log: "",
        user: Auth.user.username,
        expire: "",
        logType: "company"
    };

    let now = (Date.now() + 15552000000) / 1000;
    events.expire = Math.round(now);

    useEffect(() => {
        fetchCompanies();
    }, [ nextToken ]);

    const fetchCompanies = async () => {
        setLoader(true);
        try {
            const companiesData = await API.graphql(graphqlOperation(listCompaniesByName, { limit: 36, nextToken }));
            const data = companiesData.data.listCompaniesByName.items;
            setCompanies(data);
            setNextNextToken(companiesData?.data?.listCompaniesByName.nextToken);
            setLoader(false);
        } catch (err) {
            setLoader(false);
        }
    };

    useEffect(() => {
        onCreate.current = API.graphql(graphqlOperation(onCreateCompany)).subscribe({
            next: (createCompanyData) => {
                const createdCamera = createCompanyData?.value?.data?.onCreateCompany;
                setCompanies(previousData => [ ...previousData, createdCamera ]);
            }
        });
        onDelete.current = API.graphql(graphqlOperation(onDeleteCompany)).subscribe({
            next: (deletedCompanyData) => {
                const updatedCompany = deletedCompanyData?.value?.data?.onDeleteCompany;
                setCompanies((previousData) =>
                    previousData.filter((company) => {
                        if (company.id !== updatedCompany.id) return company;
                        return false;
                    })
                );
            }
        });
        onUpdate.current = API.graphql(graphqlOperation(onUpdateCompany)).subscribe({
            next: (updateCompanyData) => {
                const updatedCompany = updateCompanyData.value.data.onUpdateCompany;
                setCompanies(previousData => previousData.map((company) => {
                    if (company.id === updatedCompany.id) {
                        return company = updatedCompany;
                    } else return company;
                }));
            }
        });
        return () => {
            onCreate.current.unsubscribe();
            onDelete.current.unsubscribe();
            onUpdate.current.unsubscribe();
        };
    }, []);

    const filterCompaniesList = async (value) => {
        try {
            const companiesData = await API.graphql(graphqlOperation(listCompaniesByName,
                { name: { beginsWith: value } }));
            const data = companiesData.data.listCompaniesByName.items;
            setCompanies(data);
        } catch (err) {
            throw err;
        }

    };

    const handleFilterChange = (e) => {
        if (e == true) {
            setFilterValue("");
            fetchCompanies();
        } else {
            setFilterValue(e.target.value);
        }
        if (e != true) {
            if (e.target.value.length > 0) {
                const debouncedSave = _.debounce((value) => filterCompaniesList(value), 500);
                debouncedSave(e.target.value);
            } else {
                setTimeout(() => {
                    fetchCompanies();
                }, 1000);
            }
        }
    };

    const deleteCompanyHandle = () => {
        setDeleteLoader(true);
        const company = companies.filter((item) => item.id === deleteCompanyState.id);
        try {
            if (company[ 0 ].projects.items.length) {
                setCanDelete(false);
                setDeleteLoader(false);
            } else {
                API.graphql(graphqlOperation(deleteCompany, {
                    input: {
                        id: deleteCompanyState.id
                    }
                }));

                try {
                    events.log = "A company " + deleteCompanyState.name + " has been deleted";
                    const event = { ...events };
                    API.graphql(graphqlOperation(createEvent, { input: event }));
                } catch (err) {
                    throw err;
                }
                setDeleteLoader(false);
                onHide();
            }
        } catch (err) {
            setDeleteError(err?.errors[ 0 ]?.message);
            setDeleteLoader(false);
        }
    };

    const onHide = () => {
        openDeleteCompany({
            name: "",
            show: false,
            id: ""
        });
        setDeleteLoader(false);
        setDeleteError("");
        setCanDelete(true);
    };

    const handleNextPage = () => {
        setPreviousTokens([ ...previousTokens, nextToken ]);
        setNextToken(nextNextToken);
    };

    const handlePreviousPage = () => {
        setNextToken(previousTokens.pop());
    };
    const myArray = [];
    for (let i = 0; i > 36; i++) {
        myArray.push(i);
    }
    const openModalFunc = (id) => {
        openModal({
            id: id,
            show: true,
            editCompany: true,
        });
    };
    const openModalDeleteFunc = (name, id) => {
        openDeleteCompany({
            name: name,
            show: true,
            id: id,
        });
    };
    return (
        <div className="companies">
            {addCompany ? <div className="companies__cover">
                <ButtonPrimary onClick={() => openModal({
                    editCompany: false,
                    show: true,
                    editUserData: {}
                })} backgroundColor="#ffff" color="#000000" title={translate("add-company")} />
            </div> : null}
            <div className="companies__filter" style={{ justifyContent: "flex-end", borderBottom: "none" }}>
                <div className="companies__cover"
                    style={{ justifyContent: "flex-start", position: "absolute", left: 0, paddingLeft: 100 }}>
                    {addCompany ? null : <ButtonPrimary onClick={() => openModal({
                        editCompany: false,
                        show: true,
                        editUserData: {}
                    })} backgroundColor="#ffff" color="#000000" title={translate("add-company")} />}
                </div>
                <>
                    <label>{translate("filter-by-name")}: </label>
                    <div className="historicalArchive__search-input">
                        <IoMdSearch className="historicalArchive__search-input-icon" />
                        <input onChange={handleFilterChange} value={filterValue} />
                    </div>
                    <span
                        className="companies__filter-reload btn"
                        onClick={() => { handleFilterChange(true); }}
                    >
                        <IoMdRefresh color="#1181F2" size={28} />
                    </span>
                </>
            </div>
            <CompaniesTable 
                isNavbarHidden={isNavbarHidden}
                companies={companies}
                openModalFunc={openModalFunc}
                openModalDeleteFunc={openModalDeleteFunc}
                addCompany={addCompany}
                loader={loader}
            />
            <div className='pagination-container mb-5'>
                <button
                    onClick={handlePreviousPage}
                    className='pagination-arrow'
                    disabled={previousTokens?.length === 0}
                >
                    <FaChevronLeft
                        color={previousTokens?.length === 0 ? "lightgrey" : "#1181F2"}
                        size={14}
                        className='pagination__btn mr-2'
                    />{" "}
                </button>
                <div className="pagination__page">Page {previousTokens.length + 1}</div>
                <button
                    onClick={handleNextPage}
                    className='pagination-arrow'
                    disabled={!nextNextToken}
                >
                    <FaChevronRight
                        color={!nextNextToken ? "lightgrey " : "#1181F2"}
                        size={14}
                        className='pagination__btn ml-2'
                    />
                </button>
            </div>

            <AddEditCompany
                modal={modal}
                onHide={() => openModal({
                    editCompany: false,
                    show: false,
                    id: ""
                })}
            />

            <DeleteUser open={deleteCompanyState}
                deleteFunc={deleteCompanyHandle}
                loader={deleteLoader}
                error={deleteError}
                onHide={onHide}
                canDelete={canDelete}
            />

        </div>
    );
};

export default Companies;
Companies.propTypes = {
    isNavbarHidden: PropTypes.bool,
};