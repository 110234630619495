import React from "react";

const FAQManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>FAQ</h2>

                <h3>
                Wenn ich ein Bild in der Projektansicht oder im Player groß darstelle, sieht es manchmal etwas unscharf aus. Woran liegt das?
                </h3>
                Alle Bilder werden in der Cloud in einer verkleinerten Vorschaugröße angezeigt, um ein schnelles und gutes Daten-Handling zu gewährleisten. 
                Die Original-Bilder werden nicht verändert und in Originalgröße gespeichert. Sie erhalten alle Bilder in Originalgröße, sobald Sie Bilder runterladen.
                <br/>

                <h3>Ich möchte das aktuelle Bild der Kamera auf unsere Homepage stellen. Wie geht das?</h3>
                In der Ansicht „Kamera” unterhalb des Players bitte den Button „Bildlink kopieren” klicken. 
                Der Bildlink wird kopiert und Sie können ihn in ihrer Homepage oder im Intranet einbinden. Er verweist auf das jeweils neueste Bild ohne Zugangsdaten abzufragen. Dieser Link steht erst zur Verfügung, sobald die Kamera erste Bilder übertragen hat. 
                Diese Funktion steht nur Hauptnutzern zur Verfügung.
                <br/>

                <h3>Es werden keine aktuellen Bilder mehr in die Cloud übertragen. Was ist zu tun?</h3>
                Die wahrscheinlichste Ursache dafür ist eine unterbrochene Stromversorgung. 
                Bitte lassen Sie vor Ort überprüfen, ob die Stromversorgung der BAU.CAMERA sichergestellt ist. Wir können das aus der Ferne nicht überprüfen. Bei bestehender Versorgung bitte zusätzlich einmal den Stecker ziehen und nach ca. 15 Sekunden wieder einstecken.
                Wenn die BAU.CAMERA trotz sichergestellter Stromversorgung und Neustart nicht funktioniert, nehmen Sie bitte Kontakt zum Support auf.
                <br/>

                <h3>Ich brauche die Kamera nicht mehr. Was ist zu tun?</h3>
                Wenn Sie die BAU.CAMERA nicht mehr benötigen, schicken Sie sie bitte einfach an uns zurück. Eine schriftliche Kündigung zum Mietende ist nicht mehr erforderlich. Die vereinbarte Mindestlaufzeit ist davon nicht berührt.

                Unsere Technik muss spätestens bis zum aktuellen Mietende wieder bei uns zurück sein, um keine automatische Verlängerung der Miete um einen Monat 
                auszulösen. Dazu ist auf den rechtzeitigen Rückversand unter Berücksichtigung der Paket-Laufzeit zu achten. Das Datum des Endes der vereinbarten Mietzeit finden Sie in der letzten Rechnung.

                Bitte verpacken Sie die BAU.CAMERA-Technik sorgfältig und schicken sie an uns zurück. Für Schäden wegen unzureichender Verpackung ist der Kunde 
                verantwortlich. Versichern Sie das Paket zu Ihrer eigenen Sicherheit gegen Verlust auf 2.500¤. Bitte schicken Sie Sie uns nach Möglichkeit die Tracking-Nummer des Pakets an support@baucamera.com.
                <br/>

                <h3>Adresse für den Rückversand:</h3>
                NEXTFRAME Film + Media GmbH
                Östbergweg 14
                30559 Hannover
                <br/>

                <h3>Ich möchte meine Kamera länger mieten. Was ist zu tun?</h3>
                Sie können die Kamera automatisch monatsweise verlängern, dazu müssen Sie gar nichts tun. Sie erhalten dann monatlich eine Rechnung über eine weitere Monatsmiete. Wenn Sie die Abrechnung wie bisher alle sechs Monate bevorzugen, 
                nehmen Sie bitte Kontakt mit dem Support auf.
                <br/>
            </div></div>
    );
};

export default FAQManual;