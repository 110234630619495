import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import DeleteUser from "../../components/DeleteModal";
import Pagination from "../../components/Pagination";
import { IoMdRefresh } from "react-icons/io";
import "../../styles/pages/historicalArchive/historicalArchive.scss";
import {
    listHistoricalArchives,
} from "../../graphql/queries";
import { deleteHistoricalArchive, createEvent } from "../../graphql/mutations";
import { onDeleteHistoricalArchive, onCreateHistoricalArchive } from "../../graphql/subscriptions";
import Select from "react-select";
import { Auth } from "aws-amplify";
import translate from "../../i18n/translate";
import PropTypes from "prop-types";
import ActionSnackbar from "../../components/ActionSnackbar";
import HistoricalArchiveTable from "../HistoricalArchive/HistoricalArchiveTable.jsx";
const HistoricalArchive = ({ isNavbarHidden }) => {
    const [ historicalArchives, setHistoricalArchives ] = useState([]);
    const [ deleteHistoricalArchivesState, openDeleteHistoricalArchives, ] = useState({
        name: "",
        show: false,
        id: "",
    });
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ itemPerPage ] = useState(36);
    const [ project, setProject ] = useState([]);
    const [ filterValue, setFilterValue ] = useState(null);
    const [ deleteLoader, setDeleteLoader ] = useState(false);
    const [ deleteError, setDeleteError ] = useState("");
    const [ showProject, setShowProject ] = useState([]);
    const [ currentItem, setCurrentItem ] = useState([]);
    const [ loader, setLoader ] = useState(false);
    const [ sortAsc, setSortAsc ] = useState(true);
    const [ sortBy, setSortBy ] = useState("projectName");
    const [ snackbarOpen, setSnackbarOpen ] = useState(false);
    const [ snackbarData, setSnackbarData ] = useState([]);
    const fetchHistoricalArchiveFunc = useRef();
    const fetchListProjectsFunc = useRef();
    const onDelete = useRef();
    const onCreate = useRef();
    const tempUseEffectFunc = useRef();
    const [ valx, setValx ] = useState(null);
   
    const onHide = () => {
        openDeleteHistoricalArchives({
            name: "",
            show: false,
            id: "",
        });
        setDeleteLoader(false);
        setDeleteError("");
    };
    const [ events, setEvents ] = useState({
        log: "",
        user: Auth.user.username,
        expire: "",
        logType: "historicalArchive",
    });

    let now = (Date.now() + 15552000000) / 1000;
    events.expire =  Math.round(now);

    const onCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };
    
    const handleSnackbarOpen = (hasLink) => {
        if (hasLink) {
            const message = translate("successfully-copied-image-link");
            setSnackbarData([ message, "info" ]);
        } else {
            const message = translate("no-image-availabe");
            setSnackbarData([ message, "warning" ]);
        }
        setSnackbarOpen(!snackbarOpen);
    };

    const tempUseEffect = () => {
        var ShowProject = [ ...showProject ];
        historicalArchives.map(() => {
            return ShowProject.push({ state: false });
        });
        setShowProject(ShowProject);
    };
    
    useEffect(() => {
        tempUseEffectFunc.current();
    }, [ historicalArchives ]);
    tempUseEffectFunc.current = tempUseEffect;

    useEffect(() => {
        onDelete.current = API.graphql(graphqlOperation(onDeleteHistoricalArchive)).subscribe({
            next: (deletedHistoricalArchivesData) => {
                const updatedHistoricalArchives = deletedHistoricalArchivesData?.value?.data?.onDeleteHistoricalArchive;
                setHistoricalArchives((previousData) =>
                    previousData.filter((histArch) => {
                        if (histArch.id !== updatedHistoricalArchives.id) return histArch;
                        return false;
                    })
                );
            },
        });

        onCreate.current = API.graphql(graphqlOperation(onCreateHistoricalArchive)).subscribe({
            next: ({ value: { data: { onCreateHistoricalArchive } } }) => {
                setHistoricalArchives(previousData => [ ...previousData, onCreateHistoricalArchive ]);
            }
        });

        return () => {
            onDelete.current.unsubscribe();
            onCreate.current.unsubscribe();
        };
    }, []);

    const fetchListProjects = () => {
        API.graphql(graphqlOperation(listHistoricalArchives, { limit: 1000 }))
            .then(
                ({
                    data: {
                        listHistoricalArchives: { items },
                    },
                }) => {
                    setProject(items);
                }
            );
    };
    fetchListProjectsFunc.current = fetchListProjects;

    const fetchHistoricalArchive = async () => {
        setLoader(true);
        if (filterValue !== null) {
            API.graphql(
                graphqlOperation(listHistoricalArchives, {
                    filter: {
                        projectName: {
                            eq: filterValue
                        }
                    },
                })
            )
                .then(
                    ({
                        data: {
                            listHistoricalArchives: {
                                items
                            },
                        },
                    }) => {
                        setHistoricalArchives(items);
                        setLoader(false);
                    }
                )
                .catch(() => {
                    setLoader(false);
                });
        } else {
            API.graphql(graphqlOperation(listHistoricalArchives, { limit: 10000 }))
                .then(
                    ({
                        data: {
                            listHistoricalArchives: { items },
                        },
                    }) => {
                        setHistoricalArchives(items);
                        setLoader(false);
                    }
                )
                .catch(() => {
                    setLoader(false);
                });
        }
    };
    fetchHistoricalArchiveFunc.current = fetchHistoricalArchive;

    useEffect(() => {
        fetchHistoricalArchiveFunc.current();
        fetchListProjectsFunc.current();
    }, [ filterValue ]);


    useEffect(() => {
        fetchListProjects();
    }, []);
    const deleteHistoricalArchivesHandle = async () => {
        setDeleteLoader(true);
        try {
            API.graphql(
                graphqlOperation(deleteHistoricalArchive, {
                    input: {
                        id: deleteHistoricalArchivesState.id,
                    },
                })
            );

            try {
                events.log = "An archive " + deleteHistoricalArchivesState.name + " has been deleted";
                const event = { ...events };
                setEvents({ ...events, event });
                API.graphql(graphqlOperation(createEvent, { input: event }));
            } catch (err) {
                throw err;
            }
            setDeleteLoader(false);
            onHide();
        } catch (err) {
            setDeleteError(err?.errors[ 0 ]?.message);
            setDeleteLoader(false);
        }
    };

    const filterOptions = {
        "project": project.map((project) => ({
            value: project?.projectName,
            label: project?.projectName?.toUpperCase(),
        })
        ),
    };

    const handleFilterChange = (filterValue) => {
        setFilterValue(filterValue.value);
        setValx(filterValue);
    };
   
    const paginate = (selectedPage) => {
        let selected = selectedPage.selected;
        setCurrentPage(selected);
        document.documentElement.scrollTop = 0;
    };

    const sortingArrorws = () => {
        return (
            <>
                {sortAsc ? <BsArrowDown /> : <BsArrowUp />}
            </>
        );
    };

    const comlumnHeaderWithSorting = (value, column) => {
        return (
            <div className="historicalArchive__sorting" onClick={() => {
                setSortAsc(!sortAsc);
                setSortBy(value);
            }}>
                {sortBy === value && sortingArrorws()}
                {translate(column)}
            </div>
        ); 
    };

    const handleSorting = () => {
        const sortedItems = historicalArchives.sort(
            (a, b) => {
                if (sortAsc) return (a[ sortBy ] > b[ sortBy ]) ? 1 : ((b[ sortBy ] > a[ sortBy ]) ? -1 : 0);
                else return (b[ sortBy ] > a[ sortBy ]) ? 1 : ((a[ sortBy ] > b[ sortBy ]) ? -1 : 0);
            } 
        );
        const indexOfLastItem = (currentPage + 1) * itemPerPage;
        const indexOfFirstItem = indexOfLastItem - itemPerPage;
        const currentItems = sortedItems.slice(
            indexOfFirstItem,
            indexOfLastItem
        );
        setCurrentItem(currentItems);
    };

    useEffect(() => {
        handleSorting();
    }, [
        historicalArchives,
        sortAsc,
        currentPage,
        sortBy
    ]);
    const showProjectFunc = (newShowProject) => {
        setShowProject(newShowProject);
    };
    const openDeleteHistoricalArchivesFunc = (projectName, id) => {
        openDeleteHistoricalArchives({
            name: projectName,
            show: true,
            id: id,
        });
    };
    return (
        <div className="historicalArchive">
            <div className="historicalArchive__filter">
                <span style={{ marginRight: "10px" }}><label>{translate("filter-by-project")}: </label></span>
                <span
                    className="historicalArchive__filter-reload-1 btn"
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    <IoMdRefresh color="#1181F2" size={25} />
                </span>
                <Select
                    options={filterOptions[ "project" ]}
                    onChange={handleFilterChange}
                    value={valx}
                />
                <span
                    className="historicalArchive__filter-reload-2 btn"
                    onClick={() => {
                        setFilterValue(null);setValx(null);
                    }}
                >
                    <IoMdRefresh color="#1181F2" size={28} />
                </span>
            </div>
            <ActionSnackbar openBool={snackbarOpen} message={snackbarData[ 0 ]} opacity={true}
                severity={snackbarData[ 1 ]} onCloseSnackbar={onCloseSnackbar} />
            <HistoricalArchiveTable 
                isNavBarHidden={isNavbarHidden}
                currentItem={currentItem}
                loader={loader}
                comlumnHeaderWithSorting={comlumnHeaderWithSorting}
                showProject={showProject}
                showProjectFunc={showProjectFunc}
                openDeleteHistoricalArchivesFunc={openDeleteHistoricalArchivesFunc}
                handleSnackbarOpen={handleSnackbarOpen}
            />

            <DeleteUser
                open={deleteHistoricalArchivesState}
                deleteFunc={deleteHistoricalArchivesHandle}
                loader={deleteLoader}
                error={deleteError}
                onHide={onHide}
                canDelete={true}
            />

            <Pagination
                currentPage={currentPage}
                itemPerPage={itemPerPage}
                totalItems={historicalArchives.length}
                paginate={paginate}
                path="/users"
            />
        </div>
    );
};

export default HistoricalArchive;
HistoricalArchive.propTypes = {
    isNavbarHidden: PropTypes.bool,
};