import React from "react";

const ArchiveManual = () => {
    return (
        <div className="log-event__container">
            <div className="log-event__container-inner">
                <h2>Archive</h2>
            You can download your images from the archive. To do this, you can make a selection at the top right 
            and filter the images accordingly. In the first two fields you enter the start and end date, 
            in the fields to the right you can also filter by a time with start and end time. You can also use "Exclude weekend" to specify whether all images of the week are selected or only the images from Monday to Friday.  

                <br/><br/>
If you are only looking for pictures from a specific day, you can change the filter to "by date" to display all pictures from that day.  
                <br/><br/>
As soon as you have made a selection, you can click on the "Download" button to request the creation of 
a download link. The cloud will now make this selection of images available to you. As soon as this process is complete, you will receive an email with a download link. Depending on the size of the archive, this can take up to half an hour.  
                <br/><br/>
Downloads can only be ordered if the previously initiated order has been completed. You can recognize the status of this function by the color on the button. Green means that a download can be ordered, blue means that a job is currently still being processed and the next job will have to wait.  
                <br/><br/>
If you have several cameras in the project, you can specify the respective camera for the archive view under "Select cam" at the top left.  
            </div></div>
    );
};

export default ArchiveManual;