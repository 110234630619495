import React, { useState, useRef, useEffect } from "react";
import { Storage } from "aws-amplify";
import "../../styles/pages/Cams/cams.scss";
import { FaRegCalendarAlt } from "react-icons/fa";
import { TiMediaRecord } from "react-icons/ti";
import { FaEdit, FaTimes } from "react-icons/fa";
import Card from "../../components/Card";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import { Col } from "react-bootstrap";
import translate from "../../i18n/translate";
import moment from "moment";
import PropTypes from "prop-types";
import "../../styles/pages/Cams/cams-image.scss";
import greyCamera from "../../assets/images/greycam.png";
import greyCameraDark from "../../assets/images/greycam-dark.png";
import CamStatusIcons from "../../components/CamStatusIcons";


Storage.configure({
    customPrefix: {
        public: "",
    },
});

const CamsCard = ({
    cameraData,
    setCameraPopUp,
    setCameraId,
    setPopupDatetime,
    setProjectID,
    onClickFunc,
    setLivePopUp,
    setLiveState,
    openModal,
    openDeleteModal,
    openTimelapseFunc,
}) => {
    const [ showPreviousImage, setShowPreviouseImage ] = useState(false);
    const [ theme, setTheme ] = useState("light");
    const datetime =
        cameraData.imagesByDate.items[ showPreviousImage ? 1 : 0 ]?.datetime;
    const setTime = datetime?.split("T")[ 1 ];
    const cameraIDs = cameraData?.project?.cameraIDs;
    const cameraAssignedDate = cameraIDs
        ? JSON.parse(cameraIDs)[ cameraData?.id ][ 0 ]
        : null;
    const CamsImage = (imageClassName, defaultClassName) => {
        const [ imageURI, setImageURI ] = useState(greyCamera);
        const cameraDataUseEffFunc = useRef();
        const image = cameraData?.imagesByDate?.items[ 0 ];
        const s3ObjectPathThumbnail =
            cameraData?.imagesByDate?.items[ 0 ]?.s3ObjectPathThumbnail;
        const imageDateTime = cameraData?.imagesByDate?.items[ 0 ]?.datetime;
        const cameraDataUseEff = () => {
            if (cameraAssignedDate < imageDateTime ) {
                if (image?.s3ObjectPathThumbnail === null) {
                    const previousImage = cameraData?.imagesByDate?.items[ 1 ];
                    Storage.get(previousImage?.s3ObjectPathThumbnail).then((res) => {
                        setImageURI(res);
                        setShowPreviouseImage(true);
                    }); 
                } else {
                    Storage.get(image?.s3ObjectPathThumbnail).then((res) => {
                        setImageURI(res);
                        setShowPreviouseImage(false);
                    });
                }
            }
        };
        cameraDataUseEffFunc.current = cameraDataUseEff;

        useEffect(() => {
            cameraDataUseEffFunc.current();
        }, [ s3ObjectPathThumbnail, imageDateTime, ]);

        useEffect(() => {
            setTheme(window.localStorage.getItem("theme"));
        }, [ window.localStorage.getItem("theme") ]);
       
        
        return (
            <>
                {(imageURI && cameraAssignedDate < imageDateTime) ? (
                    <img
                        src={imageURI}
                        alt='arvhive camera'
                        className={imageClassName}
                        onError={(e) => {
                            if (theme === "dark") {
                                e.target.setAttribute("src", greyCameraDark);
                            } else {
                                e.target.setAttribute("src", greyCamera);  
                            }
                        }
                        }
                    />
                ) : (
                    <div className={defaultClassName}>
                        <img
                            height='100px'
                            src={theme === "dark" ? 
                                require("../../assets/images/greycam-dark.png") : 
                                require("../../assets/images/greycam.png")}
                            className='default_img'
                            alt='Default Camera'
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <Col sm={12} md={6} lg={3}>
            <div style={{ padding: "0px 10px" }}>
                <div className='overview__cam-info'>
                    <div className='truncate'>
                        <TiMediaRecord
                            color={
                                cameraData?.status === "active"
                                    ? " #00B607"
                                    : cameraData?.status === "inactive"
                                        ? "#C5C5C5"
                                        : cameraData?.status === "failure" &&
                                      "#D05E5E"
                            }
                            size={20}
                        />
                        <h6>
                            {cameraData?.cameraName}  
                        </h6>
                    </div>
                    <div className='overview__cam-info--date-time'>
                        <FaRegCalendarAlt size={16} />
                        {cameraAssignedDate < datetime ? (
                            <p>{`${moment(datetime).format(
                                "DD.MM.YYYY"
                            )} | ${setTime?.substring(0, 5)}`}</p>
                        ) : (
                            <p>--.--.---- | --:--</p>
                        )}
                    </div>
                </div>
                <Card>
                    <div
                        onClick={() => {
                            setCameraPopUp(true);
                            setCameraId(cameraData.id);
                            setPopupDatetime(datetime);
                            setProjectID(cameraData.project?.id);
                            onClickFunc(cameraData);
                        }}
                        className='overview__camera-img'
                    >
                        {CamsImage("overview-image", "default_img")}
                    </div>
                    <div style={{ position: "relative" }}>
                        <div style={{ paddingLeft: 1, fontSize: 15 }} className="overview__cam-identity"> 
                            { cameraData.project ? <>
                                <a
                                    className='overview__camera-projectname'
                                    href={ `/projects/${ cameraData?.project?.id }` }
                                >
                                    { cameraData?.project?.name }
                                </a>
                                
                            </> : <span style={{ color: "grey" }}>{translate("no-project")}</span> }
                            <CamStatusIcons 
                                cameraData={cameraData} 
                                setLivePopupFunc={() => {
                                    setLivePopUp(true);
                                    setLiveState({
                                        cameraName: cameraData.cameraName,
                                        liveStreamLink: cameraData.liveStreamLink,
                                    });
                                }}
                                setTimelapsePopupFunc={() => {
                                    openTimelapseFunc(true); setLiveState(cameraData);
                                }}
                            /> 
                        </div>
                        <div>
                            <p className='overview__cam-name'>
                                {cameraData?.cameraName}
                            </p>
                            <p className='overview__company-name'>
                                {cameraData?.project?.company?.name}
                            </p>
                        </div>
                     
                    </div>
                    <div className='btn-div d-flex mb-2'  style={{ justifyContent: "space-around" }} >
                        <ButtonPrimary
                            title={
                                <>
                                    <FaEdit
                                        className='btn-icon'
                                        key={1}
                                        color='#1181F2'
                                        size={18}
                                    />
                                    {
                                        translate("edit")}
                                </>
                            }
                            backgroundColor='#fff'
                            color='#000'
                            onClick={() =>
                                openModal({
                                    show: true,
                                    editCamera: true,
                                    id: cameraData?.id,
                                })
                            }
                        />
                        <ButtonPrimary
                            title={
                                <>
                                    <FaTimes
                                        className='btn-icon'
                                        key={2}
                                        color='#1181F2'
                                        size={22}
                                    />
                                    {translate("delete")}
                                </>
                            }
                            onClick={() =>
                                openDeleteModal({
                                    name: cameraData?.cameraName,
                                    show: true,
                                    id: cameraData?.id,
                                })
                            }
                            backgroundColor='#fff'
                            color='#000'
                        />
                    </div>
                </Card>
            </div>
        </Col>
    );
};

CamsCard.propTypes = {
    cameraData: PropTypes.object,
    setCameraPopUp: PropTypes.func,
    setCameraId: PropTypes.func,
    setPopupDatetime: PropTypes.func,
    setProjectID: PropTypes.func,
    onClickFunc: PropTypes.func,
    setLivePopUp: PropTypes.func,
    openTimelapseFunc: PropTypes.func,
    setLiveState: PropTypes.func,
    openModal: PropTypes.func,
    openDeleteModal: PropTypes.func,
};
export default CamsCard;
