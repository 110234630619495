import React from "react";
import PropTypes from "prop-types";
import {GrFormClose} from "react-icons/gr";
import "../styles/pages/Cams/cams-popup.scss";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
const MobileCloseBtn = ({size, className, onClick, horizontalOffset}) => {
    return (
        <div className={className}>
          
            <Grid container rowSpacing={1} spacing={0}>
                <Grid item xs={horizontalOffset}>
                </Grid>
                <Grid item xs={12 - horizontalOffset}>
                    <GrFormClose onClick={() => {onClick();}} size={size}></GrFormClose>
                </Grid>
              
            </Grid>
           
            
        </div>
    );
}; export default MobileCloseBtn;

MobileCloseBtn.propTypes = {
    size: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
    horizontalOffset: PropTypes.number,
};
