import React, { useState, useEffect } from "react";
import LogIn from "./Login";
import ResetPassword from "./ResetPassword";
import ConfirmForgotPassword from "./ConfirmForgotPassword";
import ForgotPassword from "./ForgotPassword";
import VerifyLogin from "./VerifyLogin";

function LoginResetPassword() {
    const [user, setUser] = useState("");
    const [toRender, setToRender] = useState(1);
    const [username, setUsername] = useState("");
    const [code, setCode] = useState("");
    useEffect(() => {
        const url = window.location;
        let params = new URL(url).searchParams;
        let Code = params.get("token");
        let Username = params.get("username");
        setCode(Code);
        setUsername(Username);
        if (Code && Username) {
            setToRender(5);
        }
    }, []);

    if (toRender === 1) {
        return (
            <LogIn
                user={(user) => setUser(user)}
                setToRender={(value) => setToRender(value)}
            />
        );
    }
    if (toRender === 2) {
        return <ForgotPassword setToRender={() => setToRender(1)} />;
    }
    if (toRender === 3) {
        return <ResetPassword user={user} setToRender={() => setToRender(1)} />;
    }
    if (toRender === 4) {
        return <VerifyLogin user={user} setToRender={() => setToRender(1)} />;
    }
    if (toRender === 5) {
        return (
            <ConfirmForgotPassword
                username={username}
                code={code}
                setToRender={() => setToRender(1)}
            />
        );
    } else {
        return (
            <LogIn
                user={(user) => setUser(user)}
                setToRender={(value) => setToRender(value)}
            />
        );
    }
}

export default LoginResetPassword;
