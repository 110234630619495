/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      username
      firstname
      lastname
      language
      email
      notificationsEnabled
      role
      imagesKeys
      dateRange
      timeRange
      filteredImages
      workday
      allImages
      archiving
      projects {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      status
      createdBy
      roleModifiedBy
      pendingDeletion
      archiveStatus
      recentProjects
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      username
      firstname
      lastname
      language
      email
      notificationsEnabled
      role
      imagesKeys
      dateRange
      timeRange
      filteredImages
      workday
      allImages
      archiving
      projects {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      status
      createdBy
      roleModifiedBy
      pendingDeletion
      archiveStatus
      recentProjects
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      username
      firstname
      lastname
      language
      email
      notificationsEnabled
      role
      imagesKeys
      dateRange
      timeRange
      filteredImages
      workday
      allImages
      archiving
      projects {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      status
      createdBy
      roleModifiedBy
      pendingDeletion
      archiveStatus
      recentProjects
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject {
    onCreateProject {
      id
      companyID
      name
      location
      latitude
      longitude
      shareableLink
      cameras {
        items {
          id
          cameraName
          cameraAlias
          username
          password
          status
          project {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          imagesByDate {
            nextToken
            __typename
          }
          liveStream
          liveStreamLink
          latestPictureLink
          protocol
          ftpLink
          rmsLink
          downloadImagesLink
          deleteAllImages
          changePassword
          hideCopyLink
          blurringEnabled
          blurringProEnabled
          maskEnabled
          timelapseEnabled
          testMode
          externalCamera
          lastDayTimelapseStatus
          lastWeekTimelapseStatus
          lastMonthTimelapseStatus
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        id
        name
        projects {
          items {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      endedAt
      cameraIDs
      deleteAllImages
      pendingDeletion
      weather_key
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject {
    onUpdateProject {
      id
      companyID
      name
      location
      latitude
      longitude
      shareableLink
      cameras {
        items {
          id
          cameraName
          cameraAlias
          username
          password
          status
          project {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          imagesByDate {
            nextToken
            __typename
          }
          liveStream
          liveStreamLink
          latestPictureLink
          protocol
          ftpLink
          rmsLink
          downloadImagesLink
          deleteAllImages
          changePassword
          hideCopyLink
          blurringEnabled
          blurringProEnabled
          maskEnabled
          timelapseEnabled
          testMode
          externalCamera
          lastDayTimelapseStatus
          lastWeekTimelapseStatus
          lastMonthTimelapseStatus
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        id
        name
        projects {
          items {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      endedAt
      cameraIDs
      deleteAllImages
      pendingDeletion
      weather_key
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject {
    onDeleteProject {
      id
      companyID
      name
      location
      latitude
      longitude
      shareableLink
      cameras {
        items {
          id
          cameraName
          cameraAlias
          username
          password
          status
          project {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          imagesByDate {
            nextToken
            __typename
          }
          liveStream
          liveStreamLink
          latestPictureLink
          protocol
          ftpLink
          rmsLink
          downloadImagesLink
          deleteAllImages
          changePassword
          hideCopyLink
          blurringEnabled
          blurringProEnabled
          maskEnabled
          timelapseEnabled
          testMode
          externalCamera
          lastDayTimelapseStatus
          lastWeekTimelapseStatus
          lastMonthTimelapseStatus
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        id
        name
        projects {
          items {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        items {
          id
          userID
          projectID
          projects {
            id
            companyID
            name
            location
            latitude
            longitude
            shareableLink
            endedAt
            cameraIDs
            deleteAllImages
            pendingDeletion
            weather_key
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            id
            username
            firstname
            lastname
            language
            email
            notificationsEnabled
            role
            imagesKeys
            dateRange
            timeRange
            filteredImages
            workday
            allImages
            archiving
            status
            createdBy
            roleModifiedBy
            pendingDeletion
            archiveStatus
            recentProjects
            __typename
            createdAt
            updatedAt
            __typename
          }
          pinnedCams
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      endedAt
      cameraIDs
      deleteAllImages
      pendingDeletion
      weather_key
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserProject = /* GraphQL */ `
  subscription OnCreateUserProject {
    onCreateUserProject {
      id
      userID
      projectID
      projects {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        id
        username
        firstname
        lastname
        language
        email
        notificationsEnabled
        role
        imagesKeys
        dateRange
        timeRange
        filteredImages
        workday
        allImages
        archiving
        projects {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        status
        createdBy
        roleModifiedBy
        pendingDeletion
        archiveStatus
        recentProjects
        __typename
        createdAt
        updatedAt
        __typename
      }
      pinnedCams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserProject = /* GraphQL */ `
  subscription OnUpdateUserProject {
    onUpdateUserProject {
      id
      userID
      projectID
      projects {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        id
        username
        firstname
        lastname
        language
        email
        notificationsEnabled
        role
        imagesKeys
        dateRange
        timeRange
        filteredImages
        workday
        allImages
        archiving
        projects {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        status
        createdBy
        roleModifiedBy
        pendingDeletion
        archiveStatus
        recentProjects
        __typename
        createdAt
        updatedAt
        __typename
      }
      pinnedCams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserProject = /* GraphQL */ `
  subscription OnDeleteUserProject {
    onDeleteUserProject {
      id
      userID
      projectID
      projects {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      users {
        id
        username
        firstname
        lastname
        language
        email
        notificationsEnabled
        role
        imagesKeys
        dateRange
        timeRange
        filteredImages
        workday
        allImages
        archiving
        projects {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        status
        createdBy
        roleModifiedBy
        pendingDeletion
        archiveStatus
        recentProjects
        __typename
        createdAt
        updatedAt
        __typename
      }
      pinnedCams
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      name
      projects {
        items {
          id
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            nextToken
            __typename
          }
          company {
            id
            name
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            nextToken
            __typename
          }
          endedAt
          cameraIDs
          deleteAllImages
          pendingDeletion
          weather_key
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      name
      projects {
        items {
          id
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            nextToken
            __typename
          }
          company {
            id
            name
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            nextToken
            __typename
          }
          endedAt
          cameraIDs
          deleteAllImages
          pendingDeletion
          weather_key
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      name
      projects {
        items {
          id
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            nextToken
            __typename
          }
          company {
            id
            name
            __typename
            createdAt
            updatedAt
            __typename
          }
          users {
            nextToken
            __typename
          }
          endedAt
          cameraIDs
          deleteAllImages
          pendingDeletion
          weather_key
          __typename
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCamera = /* GraphQL */ `
  subscription OnCreateCamera {
    onCreateCamera {
      id
      cameraName
      cameraAlias
      username
      password
      status
      project {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      imagesByDate {
        items {
          id
          cameraID
          datetime
          date
          time
          intTime
          workday
          s3ObjectPathOriginal
          s3ObjectPathThumbnail
          s3ObjectPathBlurred
          s3ObjectPathBlurringPro
          blurringPro
          blurred
          masked
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      liveStream
      liveStreamLink
      latestPictureLink
      protocol
      ftpLink
      rmsLink
      downloadImagesLink
      deleteAllImages
      changePassword
      hideCopyLink
      blurringEnabled
      blurringProEnabled
      maskEnabled
      timelapseEnabled
      testMode
      externalCamera
      lastDayTimelapseStatus
      lastWeekTimelapseStatus
      lastMonthTimelapseStatus
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCamera = /* GraphQL */ `
  subscription OnUpdateCamera {
    onUpdateCamera {
      id
      cameraName
      cameraAlias
      username
      password
      status
      project {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      imagesByDate {
        items {
          id
          cameraID
          datetime
          date
          time
          intTime
          workday
          s3ObjectPathOriginal
          s3ObjectPathThumbnail
          s3ObjectPathBlurred
          s3ObjectPathBlurringPro
          blurringPro
          blurred
          masked
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      liveStream
      liveStreamLink
      latestPictureLink
      protocol
      ftpLink
      rmsLink
      downloadImagesLink
      deleteAllImages
      changePassword
      hideCopyLink
      blurringEnabled
      blurringProEnabled
      maskEnabled
      timelapseEnabled
      testMode
      externalCamera
      lastDayTimelapseStatus
      lastWeekTimelapseStatus
      lastMonthTimelapseStatus
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCamera = /* GraphQL */ `
  subscription OnDeleteCamera {
    onDeleteCamera {
      id
      cameraName
      cameraAlias
      username
      password
      status
      project {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            protocol
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            blurringProEnabled
            maskEnabled
            timelapseEnabled
            testMode
            externalCamera
            lastDayTimelapseStatus
            lastWeekTimelapseStatus
            lastMonthTimelapseStatus
            __typename
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        company {
          id
          name
          projects {
            nextToken
            __typename
          }
          __typename
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            id
            userID
            projectID
            pinnedCams
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        endedAt
        cameraIDs
        deleteAllImages
        pendingDeletion
        weather_key
        __typename
        createdAt
        updatedAt
        __typename
      }
      imagesByDate {
        items {
          id
          cameraID
          datetime
          date
          time
          intTime
          workday
          s3ObjectPathOriginal
          s3ObjectPathThumbnail
          s3ObjectPathBlurred
          s3ObjectPathBlurringPro
          blurringPro
          blurred
          masked
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      liveStream
      liveStreamLink
      latestPictureLink
      protocol
      ftpLink
      rmsLink
      downloadImagesLink
      deleteAllImages
      changePassword
      hideCopyLink
      blurringEnabled
      blurringProEnabled
      maskEnabled
      timelapseEnabled
      testMode
      externalCamera
      lastDayTimelapseStatus
      lastWeekTimelapseStatus
      lastMonthTimelapseStatus
      __typename
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage {
    onCreateImage {
      id
      cameraID
      datetime
      date
      time
      intTime
      workday
      s3ObjectPathOriginal
      s3ObjectPathThumbnail
      s3ObjectPathBlurred
      s3ObjectPathBlurringPro
      blurringPro
      blurred
      masked
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage {
    onUpdateImage {
      id
      cameraID
      datetime
      date
      time
      intTime
      workday
      s3ObjectPathOriginal
      s3ObjectPathThumbnail
      s3ObjectPathBlurred
      s3ObjectPathBlurringPro
      blurringPro
      blurred
      masked
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage {
    onDeleteImage {
      id
      cameraID
      datetime
      date
      time
      intTime
      workday
      s3ObjectPathOriginal
      s3ObjectPathThumbnail
      s3ObjectPathBlurred
      s3ObjectPathBlurringPro
      blurringPro
      blurred
      masked
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateHistoricalArchive = /* GraphQL */ `
  subscription OnCreateHistoricalArchive {
    onCreateHistoricalArchive {
      id
      projectName
      projectId
      companyName
      shareableLink
      archivingDate
      numberOfImages
      storageSpace
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateHistoricalArchive = /* GraphQL */ `
  subscription OnUpdateHistoricalArchive {
    onUpdateHistoricalArchive {
      id
      projectName
      projectId
      companyName
      shareableLink
      archivingDate
      numberOfImages
      storageSpace
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteHistoricalArchive = /* GraphQL */ `
  subscription OnDeleteHistoricalArchive {
    onDeleteHistoricalArchive {
      id
      projectName
      projectId
      companyName
      shareableLink
      archivingDate
      numberOfImages
      storageSpace
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      log
      user
      expire
      logType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      log
      user
      expire
      logType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      log
      user
      expire
      logType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCredentials = /* GraphQL */ `
  subscription OnCreateCredentials {
    onCreateCredentials {
      id
      googleApiKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCredentials = /* GraphQL */ `
  subscription OnUpdateCredentials {
    onUpdateCredentials {
      id
      googleApiKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCredentials = /* GraphQL */ `
  subscription OnDeleteCredentials {
    onDeleteCredentials {
      id
      googleApiKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCloudStatus = /* GraphQL */ `
  subscription OnCreateCloudStatus {
    onCreateCloudStatus {
      id
      displayStatus
      displayMessage
      bannerStatus
      bannerMessage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCloudStatus = /* GraphQL */ `
  subscription OnUpdateCloudStatus {
    onUpdateCloudStatus {
      id
      displayStatus
      displayMessage
      bannerStatus
      bannerMessage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCloudStatus = /* GraphQL */ `
  subscription OnDeleteCloudStatus {
    onDeleteCloudStatus {
      id
      displayStatus
      displayMessage
      bannerStatus
      bannerMessage
      createdAt
      updatedAt
      __typename
    }
  }
`;
