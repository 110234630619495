import * as React from "react";
import "../styles/pages/Cams/cams-popup.scss";
import { FaUserAltSlash, FaClock, FaVideo } from "react-icons/fa";

import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

const CamStatusIcons = ({ cameraData, setLivePopupFunc, setTimelapsePopupFunc }) => {
    return (
        <div className="cam-status">
            {cameraData?.timelapseEnabled ?  <p data-tip={"timelapse enabled"}>
                <span>
                    <FaClock
                        size={22}
                        color={(
                                        cameraData?.timelapseEnabled ? "#1181f2"
                                            : "transparent")}
                        onClick={() => setTimelapsePopupFunc()}
                    />
                </span>

            </p> : null}
            {cameraData?.blurringEnabled ?  
                <p 
                    data-tip={"blurring enabled"}
                    style={{ marginTop: "0px" }}
                >
                    <span> 
                        <FaUserAltSlash
                            size={24}
                            color={(
                                cameraData?.blurringEnabled ? "#1181f2"
                                    : "transparent")}
                        />
                    </span>
                </p> : null }
            {cameraData?.blurringProEnabled ?  
                <p 
                    data-tip={"blurring pro enabled"}
                    style={{ marginTop: "0px" }}
                >
                    <span> 
                        <FaUserAltSlash
                            size={24}
                            color={(
                                cameraData?.blurringProEnabled ? "#1181f2"
                                    : "transparent")}
                        />
                    </span>
                </p> : null}
            {cameraData.liveStream && cameraData.liveStreamLink ? 
                <p data-tip={"live-stream enabled"} style={{ marginTop: "0px" }}>
                    <span>
                        <FaVideo
                            size={24}
                            color={(cameraData?.liveStreamLink &&
                                        cameraData?.liveStream ? "#1181f2"
                                : "transparent")}
                            onClick={(cameraData?.liveStreamLink &&
                                        cameraData?.liveStream ? setLivePopupFunc
                                : null)}
                        />
                    </span>
                </p> : null}
            <ReactTooltip
                place='top'
                type='dark'
                effect='float'
            />
        </div>
       
 
    );

}; export default CamStatusIcons;

CamStatusIcons.propTypes = {
    cameraData: PropTypes.object,
    setLivePopupFunc: PropTypes.func,
    setTimelapsePopupFunc: PropTypes.func
};