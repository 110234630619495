export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      projects {
        items {
          id
          companyID
          name
          location
          latitude
          longitude
          shareableLink
          cameras {
            items {
              id
              cameraName
            }
            nextToken
          }
          company {
            id
            name
            createdAt
            updatedAt
          }
          users {
            nextToken
          }
          endedAt
          cameraIDs
          deleteAllImages
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateCloudStatus = /* GraphQL */ `
  mutation UpdateCloudStatus(
    $input: UpdateCloudStatusInput!
    $condition: ModelCloudStatusConditionInput
  ) {
    updateCloudStatus(input: $input, condition: $condition) {
      id
      displayStatus
      displayMessage
      bannerStatus
      bannerMessage
      createdAt
      updatedAt
    }
  }
`;
export const updateCamera = /* GraphQL */ `
  mutation UpdateCamera(
    $input: UpdateCameraInput!
    $condition: ModelCameraConditionInput
    $sortDirection: ModelSortDirection
  ) {
    updateCamera(input: $input, condition: $condition) {
      id
      cameraName
      cameraAlias
      username
      password
      status
      project {
        id
        companyID
        name
        location
        latitude
        longitude
        shareableLink
        cameras {
          items {
            id
            cameraName
            cameraAlias
            username
            password
            status
            liveStream
            liveStreamLink
            latestPictureLink
            ftpLink
            rmsLink
            downloadImagesLink
            deleteAllImages
            changePassword
            hideCopyLink
            blurringEnabled
            maskEnabled
                    createdAt
            updatedAt
          }
          nextToken
        }
        company {
          id
          name
          projects {
            nextToken
          }
          createdAt
          updatedAt
        }
        users {
          items {
            id
            userID
            projectID
            createdAt
            updatedAt
          }
          nextToken
        }
        endedAt
        cameraIDs
        deleteAllImages
        __typename
        createdAt
        updatedAt
      }
      imagesByDate(sortDirection: $sortDirection) {
        items {
          id
          cameraID
          datetime
          date
          time
          intTime
          workday
          s3ObjectPathOriginal
          s3ObjectPathThumbnail
          s3ObjectPathBlurred
          blurred
          masked
          createdAt
          updatedAt
        }
        nextToken
      }
      liveStream
      liveStreamLink
      latestPictureLink
      ftpLink
      rmsLink
      downloadImagesLink
      deleteAllImages
      changePassword
      hideCopyLink
      blurringEnabled
      blurringProEnabled
      maskEnabled
      timelapseEnabled
      lastDayTimelapseStatus
      lastWeekTimelapseStatus
      lastMonthTimelapseStatus
      createdAt
      updatedAt
    }
  }
`;